const SINGULARS_AMOUNTS = Object.freeze([1, -1]);

export function pluralize(count: number, singular: string, plural?: string): string {
  if (SINGULARS_AMOUNTS.includes(count)) {
    return singular;
  }

  if (!plural) {
    plural = singular + 's';
  }

  return plural;
}
