export const environment = {
  production: true,
  staging: false,
  dev: false,
  siteUrl: 'https://mynitel.nitelusa.com',
  apiUrl: 'https://mynitel.nitelusa.com/api/v2',
  mocks: false,
  entra_tenant_id: "3b07c292-8989-432b-84cf-ac7772d4cb9b",
  entra_spa_client_id: "bdfc105d-c21a-4081-9733-899dbbf37df0",
  entra_nitelapi_scopes: "https://mynitel.onmicrosoft.com/mynitelprod/readwrite",
};
