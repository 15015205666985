import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { map, takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";

// Services
import { AuthService } from "src/app/services/auth.service";
import { HeaderService } from "src/app/services/header.service";

// Model
import {
  Navigation,
  entraNav,
  nav,
  nav2,
} from "src/app/models/navigation.model";
import { Contact } from "src/app/models/contact.model";
import { User } from "src/app/models/user.model";

import * as fromStore from "src/app/store/";
import { EditProfileDialog } from "../../../core/edit-profile/edit-profile-user-dialog.component";

@Component({
  selector: "app-header",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  standalone: false,
})
export class HeaderComponent implements OnInit {
  @Input() browserSize: string;
  user$: Observable<User>;
  nav: Navigation[] = nav;
  sortedMonitoringList: any[] = [];
  nav2: Navigation[] = nav2;
  entraNav = entraNav;

  readonly dialog = inject(MatDialog);
  private readonly destroy$ = new Subject<void>();

  constructor(
    public header: HeaderService,
    private store: Store<fromStore.State>,
    public router: Router
  ) {}

  ngOnInit() {
    this.user$ = this.store.select(fromStore.getCurrentUser);

    this.user$
      .pipe(
        map((user) => {
          const monitoringRecords =
            user?.Monitoring_System_Links__r?.records || [];
          const monitoringLinks =
            user?.Orion_UserID__c && user?.Orion_Password__c
              ? [
                  {
                    Name: "Managed Routers",
                    URL__c: `http://vigil.nitelusa.com/Orion/Login.aspx?AccountID=${user.Orion_UserID__c}&Password=${user.Orion_Password__c}`,
                  },
                ]
              : [];

          return { monitoringRecords, monitoringLinks };
        }),
        tap(({ monitoringLinks, monitoringRecords }) => {
          // Combine and sort links by 'Name'
          this.sortedMonitoringList = [
            ...monitoringLinks,
            ...monitoringRecords,
          ].sort((a, b) => a.Name.localeCompare(b.Name));
        }),
        takeUntil(this.destroy$) // Ensures proper cleanup
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  goToLink(url: string) {
    window.location.href = url;
  }

  editProfile() {
    this.dialog.open(EditProfileDialog, {
      autoFocus: true,
      width: "480px",
      data: {},
    });
  }

  public classForMenuItem(menuItem: Navigation): string[] {
    const classes = ["label"];

    if (!menuItem.children?.length) return classes;
    const childActive = menuItem.children.some((r) => this.isRouteActive(r));
    if (!childActive) return classes;

    classes.push("active-link");
    return classes;
  }

  private isRouteActive(route: { link?: string }): boolean {
    if (!route.link?.trim()) return false;
    return this.router.isActive(route.link, {
      matrixParams: "ignored",
      queryParams: "ignored",
      fragment: "ignored",
      paths: "exact",
    });
  }

  isParentOrChildActive(item: Navigation): boolean {
    const currentUrl = this.router.url;

    // Check if the main item itself is active
    if (currentUrl === item.link) return true;

    // Check if any direct child is active
    if (item.children?.some((child) => currentUrl.startsWith(child.link))) {
      return true;
    }

    // Check if any second-level child is active
    return (
      item.children?.some((child) =>
        child.children?.some((subChild) => currentUrl.startsWith(subChild.link))
      ) ?? false
    );
  }
}
