import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, inject, Signal, ViewEncapsulation } from "@angular/core";
import { KpiChart } from "./download-charts.types";
import { ChartsService } from "../charts-service";
import { ChartType } from "src/app/operations-dashboard/types/chart-type";

@Component({
  selector: "app-download-chart-modal",
  templateUrl: "./download-chart-modal.component.html",
  styleUrls: ["./download-chart-modal.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class DownloadChartModalComponent {
  private readonly charts$: ChartsService = inject(MAT_DIALOG_DATA);

  public readonly ChartType = ChartType;
  public readonly today: Date = new Date();
  public readonly displayCharts: Signal<KpiChart>[][] = [
    [
      this.charts$.mttrAllNOCCasesLife.signal,
      this.charts$.yearNitelNps.signal,
      this.charts$.csatSurveyResults.signal,
    ],
    [
      this.charts$.activeServices.signal,
      this.charts$.inflightOrders.signal,
      this.charts$.newClosedCases.signal,
    ],
    [
      this.charts$.closedCases.signal,
      this.charts$.oldOpenCareCases.signal,
      this.charts$.openCareCases.signal,
    ],
    [
      this.charts$.oldOpenNOCCases.signal,
      this.charts$.openNOCCases.signal,
      this.charts$.oldOpenSupportCases.signal,
    ],
    [
      this.charts$.openSuportCases.signal,
      this.charts$.oldOpenFinanceCases.signal,
      this.charts$.openFinanceCases.signal,
    ],
    [
      this.charts$.emergencyCases.signal,
      this.charts$.openMaintenanceCases.signal,
      // this.charts$.openFinanceCases.signal,
    ],
    [
      this.charts$.caseHistoryByDate.signal,
    ],
    [
      this.charts$.ytdNocCasesClosedCode.signal,
    ],
    [
      this.charts$.mttrCircuitDown.signal,
    ],
  ];
}
