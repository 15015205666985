import {
  Component,
  AfterViewInit,
  OnChanges,
  Input,
  ViewEncapsulation,
  ApplicationRef,
  EnvironmentInjector,
  createComponent,
  inject,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import {
  LeafletPopupContentComponent,
  LeafletMarker,
} from "./components/leaflet-popup-content/leaflet-popup-content.component";
import {
  LeafletLegendComponent,
  LegendCategory,
} from "./components/leaflet-legend/leaflet-legend.component";
import { BehaviorSubject, combineLatest, map, Observable, of } from "rxjs";
import { categoryColor } from "./functions";
import * as Leaflet from "leaflet";

/**
 * World Bounds limits the map to the world based on the latitude and longitude.
 */
const WORLD_BOUNDS = new Leaflet.LatLngBounds(
  new Leaflet.LatLng(-85.05112878, -180),
  new Leaflet.LatLng(85.05112878, 180)
);

@Component({
  selector: "app-leaflet-map",
  templateUrl: "./leaflet-map.component.html",
  styleUrls: ["./leaflet-map.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LeafletMapComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @ViewChild("map") mapElement: ElementRef<HTMLDivElement>;
  @Input() categories: { label: string; count: number }[] = [];
  @Input() markers: LeafletMarker[];

  private readonly envInjector$: EnvironmentInjector =
    inject(EnvironmentInjector);
  private readonly appRef$: ApplicationRef = inject(ApplicationRef);

  public showingPopup$: Observable<boolean> = of(false);
  private legend: Leaflet.Control = new Leaflet.Control({
    position: "bottomleft",
  });
  private resizeObserver!: ResizeObserver;
  private markerLayer: Leaflet.LayerGroup;
  private map: Leaflet.Map;

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnChanges(): void {
    if (this.map && this.markerLayer) {
      this.addMarkers();
      this.addLegend();
    }
  }

  ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
  }

  private initMap(): void {
    const titleLayer = Leaflet.tileLayer(
      "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&hl=en",
      {
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
        noWrap: true,
        maxZoom: 20,
      }
    );

    this.map = Leaflet.map("map").setView([37.0902, -95.7129], 4); // Centered on the USA with zoom level 4
    ``;
    this.markerLayer = Leaflet.layerGroup();
    this.map.setMaxBounds(WORLD_BOUNDS);
    this.markerLayer.addTo(this.map);
    titleLayer.addTo(this.map);

    this.resizeObserver?.disconnect();
    this.resizeObserver = new ResizeObserver(() => {
      this.map.invalidateSize();
    });

    this.resizeObserver.observe(this.mapElement.nativeElement);
  }

  private addMarkers(): void {
    if (!this.markerLayer) {
      return;
    }

    this.markerLayer.clearLayers();
    const openObservables: Observable<boolean>[] = [];
    const markers: Leaflet.CircleMarker[] = [];
    for (const item of this.markers) {
      const marker = this.createLeafletMarker(item);
      openObservables.push(marker.popupOpen);
      markers.push(marker.marker);
    }

    this.markerLayer = Leaflet.layerGroup(markers).addTo(this.map);

    if (markers.length > 0) {
      const group = Leaflet.featureGroup(markers);
      this.map.fitBounds(group.getBounds(), { padding: [10, 10] });
    }

    this.showingPopup$ = combineLatest(openObservables).pipe(
      map((values) => values.some((value) => value))
    );
  }

  private addLegend(): void {
    if (this.legend) {
      this.map.removeControl(this.legend);
    }

    const componentRef = createComponent(LeafletLegendComponent, {
      environmentInjector: this.envInjector$,
    });

    componentRef.instance.categories = this.categories as LegendCategory[];
    this.appRef$.attachView(componentRef.hostView);

    this.legend = new Leaflet.Control({ position: "bottomleft" });
    this.legend.onAdd = () => {
      return componentRef.location.nativeElement;
    };

    this.legend.addTo(this.map);
  }

  private createLeafletMarker(item: LeafletMarker) {
    const marker = Leaflet.circleMarker([+item.latitude, +item.longitude], {
      fillColor: categoryColor(item.status),
      color: "black",
      stroke: true,
      fillOpacity: 0.75,
      opacity: 0.5,
      fill: true,
      radius: 10,
      weight: 1,
    });

    const popupOpen = new BehaviorSubject(false);
    marker.on("popupclose", () => popupOpen.next(false));
    marker.on("popupopen", () => popupOpen.next(true));

    const tooltipContent = this.createPopupComponent(item, true);
    marker.bindTooltip(tooltipContent);

    const popupContent = this.createPopupComponent(item);
    marker.bindPopup(popupContent);

    return { marker, popupOpen };
  }

  private createPopupComponent(
    item: LeafletMarker,
    asTooltip = false
  ): HTMLElement {
    const componentRef = createComponent(LeafletPopupContentComponent, {
      environmentInjector: this.envInjector$,
    });

    componentRef.instance._item = item;
    componentRef.instance.asTooltip = asTooltip;
    this.appRef$.attachView(componentRef.hostView);
    return componentRef.location.nativeElement;
  }
}
