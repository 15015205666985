import { createSelector } from "@ngrx/store";
import * as fromReducer from "../reducers/contacts.reducer";
import { User } from "src/app/models/user.model";
import { getCurrentUser } from "./users.selector";

export const getContactsEntities = createSelector(
  fromReducer.getContactsFeatureState,
  fromReducer.getContactsEntities
);

export const getContacts = createSelector(getContactsEntities, (entities) => {
  return Object.keys(entities).map((id) => entities[id]);
});

export const getMyNitelUsersWithEntraId = createSelector(
  getContacts,
  getCurrentUser,
  (contacts: User[], currentUser: User): User[] =>
    contacts.filter(
      (contact) =>
        Boolean(contact?.Portal_Role__c) &&
        Boolean(contact?.EntraUserId__c) &&
        contact.Id !== currentUser.Id // Exclude the logged-in user
    )
);

export const getContactsLoaded = createSelector(
  fromReducer.getContactsFeatureState,
  fromReducer.getContactsLoaded
);
export const getContactsLoading = createSelector(
  fromReducer.getContactsFeatureState,
  fromReducer.getContactsLoading
);
export const getContactsError = createSelector(
  fromReducer.getContactsFeatureState,
  fromReducer.getContactsError
);
