<section class="inventory-list">
  <div class="headerWrapper" #headerContainer>
    <div class="widget-header">
      <div class="icon-text">
        <div class="custom-icon services_icon">service icon</div>
        <h1>Inventory of Services ({{ activeServices.length || 0 }})</h1>
      </div>
      <div class="csv-filter-container" *ngIf="services.length > 0">
        <div class="csv-export">
          <span>Download</span>
          <csv-download [csvData]="formattedCSVData" [fileName]="'ServicesInventory.csv'"></csv-download>
        </div>
        <a class="filter-icon-container" (click)="toggleShowDiv('filter-wrapper')">
          <span>Filter</span>
          <div class="custom-icon filter">filter icon</div>
          <div class="total-filter-selected" *ngIf="totalSelectedFilters > 0">
            {{ totalSelectedFilters }}
          </div>
        </a>
      </div>
    </div>

    <div class="widget-header" *ngIf="services.length > 0">
      <div class="search-wrapper">
        <mat-form-field>
          <div class="custom-icon search">search icon</div>
          <input matInput [formControl]="searchTermControl" placeholder="Search (Ex: SD-WAN)" autocomplete="off" />
        </mat-form-field>
      </div>
    </div>

    <div class="filter-wrapper" [@slideInOut]="animationState">
      <div class="filter-dropdowns">
        <mat-form-field>
          <mat-select
            placeholder="Locations"
            [formControl]="locationsFilterFormControl"
            [(value)]="selectedLocationFilters"
            multiple
            class="dropdown-locations"
          >
            <mat-option *ngFor="let location of locationsFilterOptions" [value]="location">{{ location }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-select
            placeholder="Service"
            [formControl]="serviceFilterFormControl"
            [(value)]="selectedServiceFilters"
            multiple
            class="dropdown-services"
          >
            <mat-option *ngFor="let type of serviceFilterOptions" [value]="type">{{ type }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <mat-select
            placeholder="Status"
            [formControl]="statusFilterFormControl"
            [(value)]="selectedStatusFilters"
            multiple
            class="dropdown-status"
          >
            <mat-option *ngFor="let status of statusFilterOptions" [value]="status">{{
              status === 'New' ? 'Pending' : status
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="selected-options">
        <ul>
          <li class="selected-location" *ngFor="let location of locationsFilterFormControl.value">
            <a (click)="removeFilterSelection('Z_Location__r.Name', location)">
              {{ location }}
              <div class="custom-icon close_sm_white">close icon</div>
            </a>
          </li>
          <li class="selected-service" *ngFor="let service of serviceFilterFormControl.value">
            <a (click)="removeFilterSelection('RecordType.Name', service)">
              {{ service }}
              <div class="custom-icon close_sm_white">close icon</div>
            </a>
          </li>
          <li class="selected-status" *ngFor="let status of statusFilterFormControl.value">
            <a (click)="removeFilterSelection('Status__c', status)">
              {{ status === 'New' ? 'Pending' : status }}
              <div class="custom-icon close_sm_white">close icon</div>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="group-wrapper" *ngIf="services.length > 0">
      <div class="group-label">
        <div class="custom-icon sort_arrows">sort icon</div>
        <span>Group by:</span>
      </div>

      <div class="group-options" *ngIf="groupedServices">
        <button
          (click)="onClickGroupBy('Location')"
          [disabled]="hasObjectLength(groupedServices, 1) && groupByFormControl.value === 'Z_Location__r.Name'"
          [ngClass]="{ 'active-link': groupByFormControl.value === 'Z_Location__r.Name' }"
        >
          <span>
            Location
            <div
              class="custom-icon"
              [ngClass]="{ sort_asc: isAscFormControl.value, sort_desc: !isAscFormControl.value }"
            >
              sort icon
            </div>
          </span>
        </button>
        <button
          (click)="onClickGroupBy('Service')"
          [disabled]="hasObjectLength(groupedServices, 1) && groupByFormControl.value === 'RecordType.Name'"
          [ngClass]="{ 'active-link': groupByFormControl.value === 'RecordType.Name' }"
        >
          <span>
            Service
            <div
              class="custom-icon"
              [ngClass]="{ sort_asc: isAscFormControl.value, sort_desc: !isAscFormControl.value }"
            >
              sort icon
            </div>
          </span>
        </button>
        <button
          (click)="onClickGroupBy('Status')"
          [disabled]="hasObjectLength(groupedServices, 1) && groupByFormControl.value === 'Status__c'"
          [ngClass]="{ 'active-link': groupByFormControl.value === 'Status__c' }"
        >
          <span>
            Status
            <div
              class="custom-icon"
              [ngClass]="{ sort_asc: isAscFormControl.value, sort_desc: !isAscFormControl.value }"
            >
              sort icon
            </div>
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="loading-indicator" *ngIf="isLoading">
    <mat-spinner [diameter]="50"></mat-spinner>
    <mat-spinner [diameter]="50" *ngIf="isServicesInProgress && !isLoading"></mat-spinner>
  </div>    

  <ng-container *ngIf="groupedServices && !isServicesInProgress">
    <div class="inventory-empty" *ngIf="hasObjectLength(groupedServices, 0) && !isLoading && services.length > 0">
      There are no services based on your search.
    </div>
    <div class="inventory-empty" *ngIf="hasObjectLength(groupedServices, 0) && !isLoading && services.length <= 0">
      There are currently no services yet.
    </div>
  </ng-container>

  <div class="inventory-viewport">
    <mat-accordion *ngIf="groupedServices">
      <mat-expansion-panel
        *ngFor="
          let inventory of groupedServices | filter: searchTerm | paginate: { itemsPerPage: 10, currentPage: page };
          first as isFirst
        "
        [expanded]="isFirst"
      >
        <mat-expansion-panel-header (click)="onHeaderClick(inventory.services[0])">
          <mat-panel-title>
            <div
              class="custom-icon"
              [ngClass]="{
                location_dark: groupByFormControl.value === 'Z_Location__r.Name',
                circuit_dark: groupByFormControl.value === 'RecordType.Name',
                services_icon: groupByFormControl.value === 'Status__c'
              }"
            >
              group icon
            </div>

            <div class="group-title">
              <span *ngIf="groupByFormControl.value === 'Z_Location__r.Name'">
                {{ inventory.services[0].CustomerLocationAlias }}
              </span>

              {{ inventory.groupTitle | uppercase }}
            </div>
          </mat-panel-title>

          <mat-panel-description>
            <span class="total-mrc"
              >Total Number Services: {{ inventory.services.length }}&nbsp;&nbsp;&nbsp;&nbsp; Total:
              {{ inventory.totalMrc | currency }}</span
            >
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="row-scroll">
          <div
            *ngFor="let service of inventory.services | filter: searchTerm; index as i"
            [class.selected]="service === selectedService"
            tabindex="0"
            class="inventory-row"
            (keyup.enter)="onSelectService(service)"
          >
            <div class="inventory-inner">
              <div class="inventory-description">
                <div class="description">
                  <div class="inventory-top-detail">
                    <ng-container *ngIf="groupByFormControl.value === 'Z_Location__r.Name'">
                      <div class="custom-icon circuit_dark">circuit icon</div>
                      <div class="service-wrapper">
                        SERVICE: {{ service.Name | uppercase }} - {{ service.RecordType.Name | uppercase }}
                        <ng-container *ngIf="service.Bandwidth_Downstream__c">
                          - {{ service.Bandwidth_Downstream__c }}
                        </ng-container>
                        <ng-container *ngIf="service.Asymmetrical_Bandwidth__c">
                          / {{ service.Bandwidth_Upstream__c }}
                        </ng-container>
                        <ng-container *ngIf="service.Vendor__r">
                          - {{ service.Vendor__r.Display_Alias__c ? service.Vendor__r.Display_Alias__c : service.Vendor__r.Name }}
                        </ng-container>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="groupByFormControl.value !== 'Z_Location__r.Name'">
                      <div class="custom-icon location_dark">location icon</div>
                      <div class="service-wrapper">{{ service.Z_Location__r.Name }}</div>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="groupByFormControl.value !== 'Z_Location__r.Name'">
                    <div class="inventory-bottom-detail">
                      <div class="inventory-service">
                        <div class="custom-icon circuit_dark">circuit icon</div>

                        <div class="service-wrapper">
                          SERVICE: {{ service.Name | uppercase }}
                          <ng-container *ngIf="groupByFormControl.value !== 'RecordType.Name'">
                            - {{ service.RecordType.Name | uppercase }}
                          </ng-container>
                          <ng-container *ngIf="service.Bandwidth_Downstream__c">
                            - {{ service.Bandwidth_Downstream__c }}
                          </ng-container>
                          <ng-container *ngIf="service.Asymmetrical_Bandwidth__c">
                            / {{ service.Bandwidth_Upstream__c }}
                          </ng-container>
                          <ng-container *ngIf="service.Vendor__r">
                            - {{ service.Vendor__r.Display_Alias__c ? service.Vendor__r.Display_Alias__c : service.Vendor__r.Name }}
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="status-mrc">
                <div class="status">
                  STATUS:
                  <span
                    class="label"
                    [ngClass]="{
                      open: service.Status__c === 'In Service',
                      pending: service.Status__c === 'New' || service.Status__c === 'Pending',
                      closed: service.Status__c === 'Canceled' || service.Status__c === 'Disconnected'
                    }"
                  >
                    {{ service.Status__c === 'New' ? 'Pending' : service.Status__c }}
                  </span>

                  <div class="view-more">
                    <div class="custom-icon arrow_progress_inactive"></div>
                    <div class="custom-icon arrow_progress"></div>
                  </div>
                </div>

                <div class="monthly-charges">
                  Monthly Charges:
                  <span>{{ service.MRR__c ? (service.MRR__c | currency) : (0 | currency) }}</span>
                </div>
              </div>
              <a routerLink="{{ service.Id }}" tabindex="-1" class="inventory-link-outer"
                ><div class="inventory-link mobile">mobile</div></a
              >
              <a (click)="onSelectService(service)" tabindex="-1"><div class="inventory-link desktop">desktop</div></a>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <pagination-controls (pageChange)="page = $event" [autoHide]="true"></pagination-controls>
  </div>

  <app-footer></app-footer>
</section>
