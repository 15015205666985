import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-access-unauthorized",
  template: `
    <div class="unauthorized-container">
      <div class="widget">
        <h1>Access Denied</h1>
        <p>You do not have permission to view this page.</p>
        <button mat-raised-button color="primary" (click)="goHome()">
          Go to Home
        </button>
      </div>
    </div>
  `,
  styles: [
    `
      .unauthorized-container {
        margin-top: 60px;
        height: calc(100vh - 96px);
        padding-top: 60px;
        -webkit-overflow-scrolling: touch;
        background-image: url("/assets/images/bg_loop.png");
        background-repeat: repeat-x;
        background-position: bottom;
        background-size: 300vw 90%;
        transform: translate3d(0, 0, 0);
        animation: scrollBg 90s linear infinite;
      }
      .unauthorized-container .widget {
        border-radius: 15px;
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
        transition: 0.2s ease all;
        display: table;
        padding: 20px 15px;
        margin: 0 auto;
        width: calc(100vw / 3);
        text-align: center;
      }
      .unauthorized-container .widget:hover {
        box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.05);
        transform: scale(1.015);
      }

      .unauthorized-container .widget h1 {
        font-size: 24px;
        margin-bottom: 10px;
      }
      .unauthorized-container .widget button {
        margin: 20px auto 10px;
        font-size: 16px;
      }
    `,
  ],
})
export class AccessUnauthorizedComponent {
  constructor(private router: Router) {}

  goHome() {
    this.router.navigate(["/"]);
  }
}
