import { ChangeDetectionStrategy, Component, HostListener, Input, ViewEncapsulation, inject } from '@angular/core';
import { SharedModule } from '../../../shared.module';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-widget-number-display',
  templateUrl: './widget-number-display.component.html',
  styleUrls: ['./widget-number-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    SharedModule,
  ],
})
export class WidgetNumberDisplayComponent {
  @Input() loading: boolean = true;
  @Input() navigateOnClick: boolean = false;
  @Input() hyperlink: string | null = null;
  @Input() ctaText: string = '';
  @Input() number: number = 0;
  @Input() title: string = '';
  @Input() status: string = '';
  @Input() label: string = '';

  private readonly router$ = inject(Router);

  @HostListener('click') onClick() {
    if (!this.hyperlink) return;
    if (!this.navigateOnClick || this.loading) return;
    this.router$.navigate([this.hyperlink]);
  }

}
