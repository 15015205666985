import { Injectable } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import * as Orders from '../orders-table/models';
import { OrdersParserService } from '../services/orders-parser.service';

@Injectable({
  providedIn: 'root'
})
export class SummaryParserService {
  private summaryData: Orders.Order[] = null;

  constructor(private service: OrdersParserService) {}

  public Initialize(): this {
    return this;
  }

  public getSummary(): Observable<any> {
    if (this.summaryData) {
      return new Observable((observer) => observer.next(this.summaryData));
    }
    const types = {
      all: 0
    };
    const active_types = {};
    const status = {
      in_progress: 0,

      preparing: 0,
      pending_foc: 0,
      pending_foc_waiting: 0,
      pending_foc_construction: 0,
      pending_foc_carrier: 0,
      pending_foc_customer: 0,
      foc_assigned: 0,
      vendor_completion: 0,
      equipment_shipped: 0,
      pending_activation: 0,
      scheduled_activation: 0,
      complete: 0,

      canceled: 0,
      disconnected: 0,
      disconnected_ip: 0,
      move_request: 0,
      activation_complete: 0,
      move_request_ip: 0
    };
    const pending_reasons = {
      waiting_vendor: 0,
      special_construction: 0,
      carrier_build: 0,
      cust_req: 0
    };

    const statusParse = (order) => {
      switch (order.milestone) {
        case Orders.Status.PREPARING:
          status.preparing++;
          status.in_progress++;
          break;
        case Orders.Status.FOC_PENDING:
          status.pending_foc++;
          status.in_progress++;
          order.foc_pending_reasons.map((o) => {
            if (o == Orders.Status.FOC_PENDING_WAITING_VENDOR) pending_reasons.waiting_vendor++;
            if (o == Orders.Status.FOC_PENDING_SPECIAL_CONSTRUCTION) pending_reasons.special_construction++;
            if (o == Orders.Status.FOC_PENDING_CARRIER_BUILD) pending_reasons.carrier_build++;
            if (o == Orders.Status.FOC_PENDING_CUST_REQ) pending_reasons.cust_req++;
          });
          break;
        case Orders.Status.FOC_ASSIGNED:
          status.foc_assigned++;
          status.in_progress++;
          break;
        case Orders.Status.VENDOR_COMPLETION:
          status.vendor_completion++;
          status.in_progress++;
          break;
        case Orders.Status.EQUIPMENT_SHIPPED:
          status.equipment_shipped++;
          status.in_progress++;
          break;
        case Orders.Status.ACTIVATION_PENDING:
          status.pending_activation++;
          status.in_progress++;
          break;
        case Orders.Status.ACTIVATION_SCHEDULED:
          status.scheduled_activation++;
          status.in_progress++;
          break;
        case Orders.Status.COMPLETE:
          status.complete++;
          break;

        case Orders.Status.CANCELED:
          status.canceled++;
          break;
        case Orders.Status.DISCONNECTED:
          status.disconnected++;
          break;
        case Orders.Status.DISCONNECTED_IN_PROGRESS:
          status.disconnected_ip++;
          break;
        case Orders.Status.MOVE_REQUESTED:
          status.move_request++;
          break;
        case Orders.Status.MOVE_REQUESTED_IN_PROGRESS:
          status.move_request_ip++;
          break;
      }
    };
    const activeTypeParse = (order) => {
      switch (order.milestone) {
        case Orders.Status.PREPARING:
        case Orders.Status.FOC_PENDING:
        case Orders.Status.FOC_ASSIGNED:
        case Orders.Status.VENDOR_COMPLETION:
        case Orders.Status.EQUIPMENT_SHIPPED:
        case Orders.Status.ACTIVATION_PENDING:
        case Orders.Status.ACTIVATION_SCHEDULED:
        case Orders.Status.IN_PROGRESS:
        case Orders.Status.ACTIVATION_COMPLETE:
          if (!active_types[order.service_name]) {
            active_types[order.service_name] = 0;
          }
          active_types[order.service_name]++;
          break;
        default:
          break;
      }
    };

    return this.service.getOrders(true).pipe(
      tap((orders) => {
        if (orders == null) {
          types.all = -1;
          return {};
        }
        types.all = orders.length;
        orders.map((o) => {
          if (!o.type) {
            return;
          }
          statusParse(o);
          activeTypeParse(o);
        });
        return orders;
      }),
      map(() => {
        if (types.all == -1) return null;
        if (types.all == 0)
          return {
            success: true,
            types,
            status,
            active_types,
            pending_reasons
          };
        return {
          success: true,
          types,
          status,
          active_types,
          pending_reasons
        };
      })
    );
  }
}
