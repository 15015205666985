import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { MsalGuard, MsalService } from "@azure/msal-angular";
import { LocalAuthGuard } from "./local-auth.guard";
import { AuthenticationResult } from "@azure/msal-browser";
import { catchError, Observable, of, switchMap } from "rxjs";
import { entraConfig } from "../../msal.config";

@Injectable({
  providedIn: "root",
})
export class DynamicAuthGuard implements CanActivate {
  constructor(
    private msalGuard: MsalGuard,
    private localAuthGuard: LocalAuthGuard,
    private msalService: MsalService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> {

    // Ensure there is an account
    const accounts = this.msalService.instance.getAllAccounts();
    if (!accounts.length) {
      return this.localAuthGuard.canActivate(route, state);
    }

    const account = accounts[0];

    return this.msalService
      .acquireTokenSilent({
        account,
        scopes: entraConfig.appScopes.nitelApi.scopes,
      })
      .pipe(
        switchMap((result: AuthenticationResult | null) => {
          if (result?.accessToken) {
            return this.msalGuard.canActivate(route, state);
          }

          if (localStorage.getItem("salesforce_auth_token")) {
            return this.localAuthGuard.canActivate(route, state);
          }
          return of(false);
        }),
        catchError(() => {
          if (localStorage.getItem("salesforce_auth_token")) {
            return this.localAuthGuard.canActivate(route, state);
          }
          return of(false);
        })
      );
  }
}
