<div class="loading-indicator" *ngIf="isLoadingServices">
  <mat-spinner [diameter]="30"></mat-spinner>
</div>
<div class="service-info-container" [formGroup]="formRef" *ngIf="!isLoadingServices">
  <ng-container *ngFor="let sli of formRef.controls.services['controls']; let i = index">
    <div [formGroup]="sli" class="line-item">
      <!--  If more than one service exists, show dropdowns with multiple services -->
      <ng-container *ngIf="allServices.length > 1">
        <div class="question primary">Select service by</div>
        <mat-radio-group class="default-contact-options" formControlName="identifyIssueBy" (change)="onServiceOrLocationRadioChange(i)">
          <mat-radio-button class="service-location-radio" value="location" checked>
            <div class="icon-text">
              <div class="custom-icon location"></div>
              <div class="custom-icon location_dark"></div>
              Location
            </div>
          </mat-radio-button>
          <mat-radio-button class="service-location-radio" value="service">
            <div class="icon-text">
              <div class="custom-icon circuit"></div>
              <div class="custom-icon circuit_dark"></div>
              Service
            </div>
          </mat-radio-button>
        </mat-radio-group>

        <!--- BY LOCATION
        - if there is one location, there is only one service: display both static
        - if there is one location and multiple services: display location static and services as a dropdown
        - if there are many locations: display location dropdown and hide service information
        ---- choosing a location with one service: display service static
        ---- choosing a location with many services: display service dropdown -->
        <ng-container *ngIf="formRef.value.services[i].identifyIssueBy === 'location'">
          <app-autocomplete-location
            [allLocations]="allLocations"
            (locationSelected)="locationSelected($event)"
            [locationIterator]="i"
          ></app-autocomplete-location>

          <ng-container
            *ngIf="formRef.value.services[i].locationId && formRef.value.services[i].servicesByLocation.length > 1"
          >
            <div class="question">Which service?</div>
            <mat-form-field>
              <mat-label>Please select a service</mat-label>
              <mat-select formControlName="serviceId" (valueChange)="onServiceSelected($event, i)">
                <ng-container *ngFor="let s of allServices">
                  <mat-option
                    *ngIf="s.locationId == formRef.value.services[i].locationId"
                    [value]="s.id"
                    [disabled]="s.disabled"
                  >
                    {{ s.nit }} – {{ s.product }} <span *ngIf="s.bandwidth">{{ s.bandwidth }} </span
                    ><span *ngIf="s.alias"> - {{ s?.alias }}</span>
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </ng-container>

          <ng-container
            *ngIf="formRef.value.services[i].locationId && formRef.value.services[i].servicesByLocation.length === 1"
          >
            <div class="question">Service:</div>
            {{ formRef.value.services[i].servicesByLocation[0].nit }} -
            {{ formRef.value.services[i].servicesByLocation[0].product }}
          </ng-container>
        </ng-container>

        <!--  BY SERVICE -->
        <ng-container *ngIf="formRef.value.services[i].identifyIssueBy === 'service'">
          <app-autocomplete
            [allServices]="allServices"
            [selectedServiceId]="formRef.value.services[i].serviceId"
            [ticketType]="ticketType"
            [formRef]="formRef"
            [identifyIssueBy]="formRef.value.services[i].identifyIssueBy"
            [serviceIterator]="i"
            (serviceSelected)="serviceSelected($event)"
          ></app-autocomplete>
        </ng-container>

        <h3>Desired disconnect date</h3>
        <h4 class="disclaimer">Disconnect requests require a 30 day minimum notification.</h4>
        <mat-form-field class="date-picker">
          <mat-label>Please select a date</mat-label>
          <input
            matInput
            formControlName="desiredDate"
            (dateChange)="canSubmit()"
            [matDatepickerFilter]="filterDates"
            [matDatepicker]="picker"
            placeholder="Please select"
            (keyup)="canSubmit()"
            (click)="picker.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon svgIcon="nitelCalendar" matDatepickerToggleIcon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker
            #picker
            [calendarHeaderComponent]="customCalHeader"
            [startAt]="startCalendarAt"
          ></mat-datepicker>
        </mat-form-field>

        <button
          class="button-remove-service"
          (click)="removeServicesFormControl(i)"
          *ngIf="formRef.value.services.length > 1"
        >
          remove
        </button>
      </ng-container>

      <!--  If only one service exists, show service details -->
      <ng-container *ngIf="allServices.length === 1">
        <p class="one-service">{{ allServices[0].nit }} - {{ allServices[0].product }}</p>
        <p>{{ formRef.value.services[0].locationAddress }}</p>

        <h3>Desired disconnect date</h3>
        <h4 class="disclaimer">Disconnect requests require a 30 day minimum notification.</h4>
        <mat-form-field class="date-picker">
          <mat-label>Please select a date</mat-label>
          <input
            matInput
            formControlName="desiredDate"
            (dateChange)="canSubmit()"
            [matDatepickerFilter]="filterDates"
            [matDatepicker]="picker"
            placeholder="Please select"
            (keyup)="canSubmit()"
            (click)="picker.open()"
          />
          <mat-datepicker-toggle matSuffix [for]="picker">
            <mat-icon svgIcon="nitelCalendar" matDatepickerToggleIcon></mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker
            #picker
            [calendarHeaderComponent]="customCalHeader"
            [startAt]="startCalendarAt"
          ></mat-datepicker>
        </mat-form-field>
      </ng-container>

      <!--  If no service exists -->
      <div class="question" *ngIf="allServices.length === 0">There are no active services available to disconnect.</div>
    </div>
  </ng-container>

  <ng-container *ngIf="allServices.length > 1">
    <div
      class="alt-contact-options disconnect-another-service"
      *ngIf="formRef.value.services.length != allServices.length"
    >
      <button mat-fab class="cta" (click)="addServicesFormControl()">
        <div class="custom-icon add_button"></div>
      </button>
      <div>
        <button class="cta-label" (click)="addServicesFormControl()">Disconnect Another Service</button>
      </div>
    </div>
  </ng-container>

  <p class="one-service" *ngIf="selectedServicesTotal == allServices.length">
    There are no more services available to disconnect.
  </p>
</div>
