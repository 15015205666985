import { SharedModule } from '../../../shared.module';

import { ChangeDetectionStrategy, Component, HostListener, inject, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  standalone: true,
  selector: 'app-widget-billing-display',
  templateUrl: './widget-billing-display.component.html',
  styleUrls: ['../common/styles/widget-element.scss', './widget-billing-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    SharedModule,
  ],
})
export class WidgetBillingDisplayComponent {

  @Input() hyperlink: string | null = null;
  @Input() navigateOnClick: boolean = false;
  @Input() loadingInvoices: boolean = true;
  @Input() loadingPayments: boolean = true;
  @Input() invoiceDate: Date | null = null;

  @Input({ alias: 'payment.transactionDate' }) paymentTransactionDate: string | Date | null = null;
  @Input({ alias: 'payment.amount' }) paymentAmount: number | null = null;

  private readonly decimalPipe$ = inject(DecimalPipe);
  private readonly router$ = inject(Router);

  public get paymentDollars(): string {
    if (!this.paymentAmount) return '0';
    const amount = this.decimalPipe$.transform(this.paymentAmount, '1.2-2') || '0.00';
    return `${amount.split('.')[0]}.`;
  }

  public get paymentCents(): string {
    if (!this.paymentAmount) return '0';
    const amount = this.decimalPipe$.transform(this.paymentAmount, '1.2-2') || '0.00';
    return amount.split('.')[1] || '00';
  }

  @HostListener('click') onClick() {
    if (!this.hyperlink) return;
    if (!this.navigateOnClick || (this.loadingInvoices && this.loadingPayments)) return;
    this.router$.navigate([this.hyperlink]);
  }

}
