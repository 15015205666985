import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { PortalRoleService } from '../../services/portal-role.service';
import * as portalRolesActions from '../actions/portal-roles.action';

@Injectable()
export class PortalRoleEffects {
  constructor(private actions$: Actions, private portalRolesService: PortalRoleService) {}

  loadPortalRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(portalRolesActions.LOAD_PORTAL_ROLES),
      switchMap(() => {
        return this.portalRolesService.getPortalRoles().pipe(
          map((data) => new portalRolesActions.LoadPortalRolesSuccess(data)),
          catchError((error) => of(new portalRolesActions.LoadPortalRolesFail(error)))
        );
      })
    );
  });
}
