<div class="horizontal-bars-item stats" [ngClass]="{ 'horizontal-bars-item--loading': loading }">
  <div class="horizontal-bars-item__header">
    <h1 class="horizontal-bars-item__title">
      {{ data$()?.title }}
    </h1>
    <div>
      <button
        mat-icon-button
        class="horizontal-bars-item__expand"
        (click)="downloadXlsx()"
      >
        <mat-icon>file_open</mat-icon>
      </button>
      <button
        mat-icon-button
        class="horizontal-bars-item__expand"
        (click)="openModal()"
      >
        <mat-icon>fullscreen</mat-icon>
      </button>
    </div>
  </div>
  <div class="horizontal-bars-item__body">
    <div class="horizontal-bars-item__chart" [style.height]="chartService$.recommendedHeight$() + 'px'">
      <canvas
        baseChart
        [type]="chartService$.barChartType"
        [data]="chartService$.barChartData$()"
        [options]="chartService$.barChartOptions"
        [plugins]="chartService$.barChartPlugins"
        (chartClick)="chartService$.chartClicked($event)"
      ></canvas>
    </div>

    <mat-spinner class="horizontal-bars-item__spinner"
      *ngIf="loading"
      [diameter]="75"
    ></mat-spinner>
  </div>

  <!-- <div class="horizontal-bars-item__footer">
    <a [routerLink]="data$()?.hyperlink">
      {{ data$()?.hyperlinkText || "View " + data$()?.title }}
    </a>
  </div> -->
</div>
