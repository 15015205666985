import { getActiveOlis, LoadOrders, LoadOrdersFail, LoadOrdersSuccess } from "../../../store";
import { ChartType } from "../../types/chart-type";
import { ChartService } from "./chart.service";
import { SingleValueKPI } from "../../types";
import { Injectable } from "@angular/core";
import { map } from "rxjs";

@Injectable()
export class InflightOrdersChartService extends ChartService<SingleValueKPI> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadOrders,
    success: LoadOrdersSuccess,
    failure: LoadOrdersFail,
  }).pipe(
    map(({ loading, data }) => {
      const dataValue = getActiveOlis(data || []).length;

      return {
        ngClass: [],
        loading,
        data: {
          type: ChartType.SINGLE,
          id: "Inflight Orders",
          title: "Inflight Orders",
          value: dataValue,
          hyperlink: "/orders", // need flags
        } satisfies SingleValueKPI,
      };
    })
  );

}
