import { VerticalBarsModalComponent } from "./vertical-bars-modal/vertical-bars-modal.component";
import { Component, EventEmitter, Input, inject, Output, ViewChild, effect, ElementRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ChartService } from './services/chart.service';
import { BaseChartDirective } from "ng2-charts";
import { VerticalBarsKpi } from "../../types";
import * as domtoimage from "dom-to-image-more";
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-vertical-bars',
  templateUrl: './vertical-bars.component.html',
  styleUrls: ['./vertical-bars.component.scss'],
  providers: [ChartService],
})
export class VerticalBarsComponent {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  @Input({ required: true }) set data(v: VerticalBarsKpi) { this.data$.set(v) };
  @Input() loading: boolean = false;
  @Input() total: number = 0;
  @Output() navigate = new EventEmitter<string>();
  @ViewChild("modalContent") modalContent: ElementRef | undefined;

  //#region     BEGIN ChartService
  public readonly chartService$ = inject(ChartService)
  public get data$() { return this.chartService$.data$ }
  public readonly updateChartEffect$ = effect(() => {
    this.chartService$.barChartData$();
    this.chart?.update();
  });
  //#endregion  END ChartService

  private readonly matDialog = inject(MatDialog);
  public openModal(): void {
    this.matDialog.open<VerticalBarsModalComponent, VerticalBarsKpi>(
      VerticalBarsModalComponent,
      {
        width: "calc(100vw - 1rem)",
        data: this.data$(),
        hasBackdrop: true,
      }
    );
  }

  public downloadImage(): void {
    const contentElement = this.modalContent?.nativeElement;

    if (contentElement) {
      const options = {
        width: contentElement.offsetWidth,
        height: contentElement.offsetHeight,
        scale: 1.5,
        style: {
          transform: "scale(1)",
          transformOrigin: "top center",
        },
      };

      domtoimage
        .toPng(contentElement, options)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "component-image.png";
          link.click();
        })
        .catch((error) => {
          console.error("Error", error);
        });
    }
  }

  public downloadXlsx(): void {
    const chartData = this.chartService$.barChartData$();

    const data = [];
    const labels = chartData.labels;

    const headers = ['Label', ...chartData.datasets.map(dataset => dataset.label)];
    data.push(headers);

    labels.forEach((label, index) => {
      const row = [label];
      chartData.datasets.forEach(dataset => {
        row.push(dataset.data[index]);
      });
      data.push(row);
    });

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Chart Data');

    XLSX.writeFile(wb, 'chart.xlsx');
  }

}
