import { ChartConfiguration, ChartData } from "chart.js";
import DataLabelsPlugin from "chartjs-plugin-datalabels";

//#region     BEGIN OPTIONS
const defaultChartOptions = (): ChartConfiguration["options"] => ({
  scales: {
    x: {
      grid: {
        display: true,
      },
      ticks: {
        color: '#90A4AE',
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: true,
      },
      ticks: {
        stepSize: 1,
        padding: 20,
        color: '#90A4AE',
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      anchor: "end",
      align: "end",
      font: {
        size: 12,
        weight: "bold",
        family: '"open-sans", sans-serif',
      },
      color: "#000",
      formatter: (value) => {
        return value;
      },
    },
  },
});
//#endregion  END OPTIONS

//#region     BEGIN DATA
const defaultChartData = (): ChartData<'line'> => ({
  labels: [],
  datasets: [
    {
      data: [], // Values ​​for the graph
      label: '',
      borderColor: '#42A5F5',
      backgroundColor: 'rgba(66, 165, 245, 0.2)',
      fill: true, // For shading below the line
      tension: 0.4, // Smoothing the line
    },
  ],
});
//#endregion  END DATA

//#region     BEGIN PLUGINS
const defaultChartPlugins = (): any[] => [DataLabelsPlugin];
//#endregion  END PLUGINS

export const chartDefaults = Object.freeze({
  plugins: defaultChartPlugins,
  options: defaultChartOptions,
  data: defaultChartData,
});
