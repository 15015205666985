import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { catchError, map, Observable, Subject, takeUntil, tap } from "rxjs";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTooltipModule } from "@angular/material/tooltip";

import * as fromStore from "../store/";
import { User } from "../models/user.model";
import { CreateEditUserDialog } from "./dialogs/create-edit-user-dialog.component";
import { ConfirmRemoveUserDialog } from "./dialogs/confirm-remove-user-dialog.component";
import { MSGraphService } from "../services/ms-graph.service";

export interface UserDialogData extends Partial<User> {
  TotalSuperAdmins?: number;
}

@Component({
  selector: "app-manage-users",
  templateUrl: "./manage-users.component.html",
  styleUrls: ["./manage-users.component.scss"],
  standalone: true,
  imports: [
    MatTableModule,
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatTooltipModule,
    MatSortModule,
    MatSnackBarModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageUsersComponent implements OnInit, OnDestroy {
  @ViewChild(MatSort) sort!: MatSort;

  users$: Observable<User[]> = this.store.select(fromStore.getMyNitelUsersWithEntraId);
  loggedInUser: User;
  dataSource = new MatTableDataSource<User>();
  displayedColumns: string[] = [
    "FirstName",
    "LastName",
    "Email",
    "Role",
    "Actions",
  ];
  portalRoles$ = this.store.select(fromStore.getPortalRoles);

  isSubmitting: boolean = false;
  totalSuperAdmins: number = 0;

  readonly dialog = inject(MatDialog);
  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<fromStore.State>,
    private graphService: MSGraphService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromStore.LoadContacts());
    this.store.dispatch(new fromStore.LoadPortalRoles());
    this.users$.pipe(takeUntil(this.destroy$)).subscribe((users) => {
      this.dataSource = new MatTableDataSource(users);
      this.handleSorting();
      this.updateUserRoleCounts(users);
    });

    this.store
      .select(fromStore.getCurrentUser)
      .pipe(
        map((user) => {
          this.loggedInUser = user;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  handleSorting() {
    this.dataSource.sort = this.sort;
    // Sorting logic for nested properties
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "Role":
          return item.Portal_Role__r?.Name || "";
        default:
          return item[property];
      }
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCreateEditUser(user?: User): void {
    const dialogRef = this.openCreateEditUserDialog(user);

    this.handleDialogResult(dialogRef, () => {
      this.reloadContacts();
    });
  }

  onRemoveUser(user: User): void {
    const dialogRef = this.openRemoveUserDialog(user);

    this.handleDialogResult(dialogRef, () => {
      this.deactivateUser(user);
    });
  }

  onResendInvite(user: User): void {
    this.graphService
      .resendEntraInvitation(user.Email)
      .pipe(
        takeUntil(this.destroy$),
        tap(() =>
          this.showSnackBar("Invite Resent Successfully", "success-snackbar")
        ),
        catchError((error) => {
          console.error("Error resending invitation:", error);
          this.showSnackBar(
            "Failed to Resend Invite. Please try again.",
            "error-snackbar"
          );
          return [];
        })
      )
      .subscribe();
  }

  private openCreateEditUserDialog(user?: User) {
    return this.dialog.open(CreateEditUserDialog, {
      disableClose: true,
      data: {
        ...user,
        AccountId: this.loggedInUser.AccountId,
        Account: { Name: this.loggedInUser.Account.Name },
        TotalSuperAdmins: this.totalSuperAdmins,
      } as UserDialogData,
    });
  }

  private openRemoveUserDialog(user: User) {
    return this.dialog.open(ConfirmRemoveUserDialog, {
      width: "300px",
      data: user,
    });
  }

  private handleDialogResult(dialogRef: any, onSuccess: () => void): void {
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        onSuccess();
      }
    });
  }

  private deactivateUser(user: User): void {
    this.graphService
      .deactivateEntraUser(user.EntraUserId__c, user.Id)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.showSnackBar("User successfully removed!", "success-snackbar");
          this.reloadContacts();
        }),
        catchError((error) => {
          console.error("Error removing user:", error);
          this.showSnackBar(
            "Error removing user. Please try again.",
            "error-snackbar"
          );
          return [];
        })
      )
      .subscribe();
  }

  private reloadContacts(): void {
    this.store.dispatch(new fromStore.ClearContacts());
    this.store.dispatch(new fromStore.LoadContacts());
    this.updateUserRoleCounts(this.dataSource.data);
  }

  private showSnackBar(message: string, panelClass: string): void {
    this.snackBar.open(message, "Close", {
      duration: 7000,
      panelClass: [panelClass],
    });
  }

  private updateUserRoleCounts(users: User[]): void {
    this.totalSuperAdmins = users.filter(
      (user) => user.Portal_Role__r?.Name === "Super Admin"
    ).length;
  }
}
