import { Component, effect, inject, OnInit, ViewChild } from '@angular/core';
import { ChartType } from 'src/app/operations-dashboard/types/chart-type';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LineValuesKpi } from 'src/app/operations-dashboard/types';
import { ChartService } from '../services/chart.service';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-line-values-modal',
  templateUrl: './line-values-modal.component.html',
  styleUrls: ['./line-values-modal.component.scss'],
  providers: [ChartService],
})
export class LineValuesModalComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  public readonly dialogRef = inject(MatDialogRef<LineValuesModalComponent>)
  public readonly data = inject(MAT_DIALOG_DATA)

  //#region     BEGIN ChartService
  public readonly chartService$ = inject(ChartService)
  public get data$() { return this.chartService$.data$ }
   public readonly updateChartEffect$ = effect(() => {
      this.chartService$.data$.set({
        value: [],
        id: "",
        title: "",
        type: ChartType.LINE,
      } satisfies LineValuesKpi);
      this.chart?.update();
    });
  //#endregion  END ChartService

  ngOnInit(): void {
    this.data$.set(this.data);
  }

}
