import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgApexchartsModule } from 'ng-apexcharts';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

/*  combined common modules into one file */
import { SharedModule } from '../shared/shared.module';

// combined common modules into one file
import * as fromGuards from 'src/app/store/guards';

// components & containers
import * as fromComponents from './components';
import * as fromContainers from './containers';

// date and time pickers
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

const orderRoutes: Routes = [
    {
      path: '',
      component: fromContainers.MetricTableComponent,
      canActivate: [fromGuards.DynamicAuthGuard, fromGuards.OrdersGuard]
    }
  ];


@NgModule({
    imports: [
      BrowserModule,
      SharedModule,
      BrowserAnimationsModule,
      MatButtonToggleModule,
      MatCheckboxModule,
      MatToolbarModule,
      MatSortModule,
      MatGridListModule,
      MatSlideToggleModule,
      FormsModule,
      ReactiveFormsModule,
      NgApexchartsModule,
      MatTableModule,
      MatPaginatorModule,
      RouterModule.forChild(orderRoutes),
      NgxMatTimepickerModule,
      MatDatepickerModule
    ],
    declarations: [...fromComponents.components, ...fromContainers.containers],
    providers: [...fromGuards.guards],
    exports: [...fromContainers.containers, ...fromComponents.components]
  }) 
  export class MetricDetailsModule {}