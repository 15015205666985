<section
  id="ticketPage"
  class="ticket-page parent-container"
  (window:resize)="onWindowResize()"
>
  <ticket-top [tickets]="filteredTickets$ | async" (timeRangeChanged)="onTimeRangeChanged($event)"> </ticket-top>
  <div class="ticket-container">
    <tickets-list-display
      class="ticket-page-tickets-list"
      [browserSize]="browserSize$ | async"
      [tickets]="filteredTickets$ | async"
      [selectedTicket]="selectedTicket$ | async"
      [isLoading]="isLoading$ | async"
      [isLoaded]="isLoaded$ | async"
      [csvData]="ticketCsvData"
      (onSelectTicket)="onSelectTicket($event)"
      (onOpenDialog)="openDialog()"
    ></tickets-list-display>

    <app-tickets-detail-shell
      class="ticket-page-ticket-detail"
      *ngIf="browserSize !== 'small'"
      [ticket]="selectedTicket$ | async"
      [isLoading]="isLoading$ | async"
      [isLoaded]="isLoaded$ | async"
      (reloadTickets)="reloadTickets()"
    ></app-tickets-detail-shell>
  </div>
</section>
