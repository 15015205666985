<div class="data-container">
    <div class="header">Cellular Data Consumption Over Period</div>

    <div class="data-item">
        <span class="data-label">Highest Data Consumption:</span>
        <span class="data-value">{{ highestDataConsumption }}Gb</span>
    </div>

    <div class="data-item">
        <span class="data-label">Total Data Consumption:</span>
        <span class="data-value">{{ totalDataConsumption }}Gb</span>
    </div>

</div>