import { Component, OnInit, ViewChild, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';


interface Metrics {
  edgeName: string;
  links: string;
  timestamp: string;
  carrier: string;
  bandwidth: number;
}

@Component({
  selector: 'app-cellular-table',
  templateUrl: './cellular-table.component.html',
  styleUrls: ['./cellular-table.component.scss']
})
export class CellularTableComponent implements OnInit, OnChanges {

  @Input() dataSource: MatTableDataSource<Metrics>;
  @Input() loading: boolean = false; // Loading state
  @Input() isNoContent = false; // Display flag for content availability

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  // Define the displayed columns on the table
  displayedColumns: string[] = [
    'edgeName',
    'links',
    'timestamp',
    'carrier',
    'bandwidth'    
  ];

  // Filters and options
  filters = ['edgeNameFilter', 'linksFilter', 'timestampFilter', 'carrierFilter', 'bandwidthFilter'];
  filters2 = ['edgeNameFilter2', 'linksFilter2', 'timestampFilter2', 'carrierFilter2', 'bandwidthFilter2'];
  carrierOptions: string[] = ['all']; 
  showFilters = false; // Toggle filter visibility
  pageSizeOptions: number[] = [10, 25, 50, 75, 100]; // Pagination options
  defaultPageSizeOption: number = 10; // Default page size

  // Object to hold filter values
  filterValues: { [key: string]: string | null } = {
    edgeName: '',
    links: '',
    bandwidth: '',
    drops: '',
    carrier: 'all',
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
  };

  constructor() { }

  ngOnInit() {
    this.initTable();
  }

  ngOnChanges() {
    this.initTable();
  }

  private initTable(): void {
    if (!this.dataSource) {
      return;
    }

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'timestamp':
          return new Date(item.timestamp).getTime();
        default:
          return item[property];
      }
    };

    // Apply custom filtering logic
    this.dataSource.filterPredicate = this.createFilter();
    this.clearFilter();

    this.carrierOptions = this.getUniqueFieldValuesWithDefault(this.dataSource, 'carrier', 'all');
  }
 
  // Apply a filter to a specific column
  applyFilter(event: Event, column: string): void {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.filterValues[column] = filterValue;
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  applyDateFilter(): void {
    if (this.filterValues.startDate && !this.filterValues.startTime) {
      this.filterValues.startTime = this.getCurrentTimeIn12HourFormat();
    }
    if (this.filterValues.endDate && !this.filterValues.endTime) {
      this.filterValues.endTime = this.getCurrentTimeIn12HourFormat();
    }

    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  applyPicklistFilter(filterValue: string | null, column: string): void {
    this.filterValues[column] = filterValue ? filterValue.trim().toLowerCase() : null;
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  clearFilter(): void {
    this.filterValues = {
      edgeName: '',
      links: '',
      bandwidth: '',
      drops: '',
      carrier: 'all',
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
    };
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  // Custom filter function for multi-column filtering
  private createFilter(): (data: Metrics, filter: string) => boolean {
    return (data: Metrics, filter: string): boolean => {
      const searchTerms = JSON.parse(filter); 
      const timestamp = new Date(data.timestamp);

      return Object.keys(searchTerms).every((key) => {
        const filterValue = searchTerms[key];

        if (!filterValue) return true; 

        // Combine start date and time for filtering
        if (key === 'startDate' || key === 'startTime') {
          const startDateTime = searchTerms.startDate
            ? new Date(`${new Date(searchTerms.startDate).toLocaleDateString("en-US")} ${searchTerms.startTime || '12:00 AM'}`)
            : null;
          if (startDateTime) {
            return timestamp >= startDateTime;
          }
        }

        // Combine end date and time for filtering
        if (key === 'endDate' || key === 'endTime') {
          const endDateTime = searchTerms.endDate
            ? new Date(`${new Date(searchTerms.endDate).toLocaleDateString("en-US")} ${searchTerms.endTime || '11:59 PM'}`)
            : null;
          if (endDateTime) {
            return timestamp <= endDateTime;
          }
        }

        // Handle carrier filter with "all" logic
        if (key === 'carrier') {

          if (filterValue == 'all') {
            return true;
          }

          return data.carrier?.toLowerCase().includes(filterValue);
        }

        // Handle numeric and string filters
        if (['bandwidth'].includes(key)) {
          return data[key as keyof Metrics]?.toString().includes(filterValue);
        }

        // General string match for other fields
        return data[key as keyof Metrics]
          ?.toString()
          .toLowerCase()
          .includes(filterValue);
      });
    };
  }

  // Toggle the visibility of filters
  toggleFilters(): void {
    this.showFilters = !this.showFilters;
  }

  // This method retrieves the current local time, formats it into the 12-hour clock format
  private getCurrentTimeIn12HourFormat(): string {
    const now = new Date();

    // Get hours and minutes
    let hours = now.getHours();
    const minutes = now.getMinutes();

    // Determine AM/PM and adjust hours
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12; // Convert to 12-hour format and handle midnight (0 -> 12)

    // Format minutes to always have two digits
    const formattedMinutes = minutes.toString().padStart(2, '0');

    // Combine into 12-hour format string
    return `${hours}:${formattedMinutes} ${ampm}`;
  }

  private getUniqueFieldValuesWithDefault<Metrics>(
    dataSource: MatTableDataSource<Metrics>,
    field: keyof Metrics,
    defaultValue: string
  ): string[] {
    const uniqueValues = new Set(dataSource.data.map(item => item[field] as string));
    const picklistValues = Array.from(uniqueValues);
    
    // Add the default value at the beginning
    return [defaultValue, ...picklistValues];
  }
}
