<div class="account-menu">
  <a (click)="toggle()" #mobileMenuButton>
    <div class="profile-icon" [ngClass]="{ active: toggleMenu }"></div>
  </a>
  <nav [@parentAnimation]="toggleMenu" #mobileMenuView>
    <nav [@modalStateDisplay]="toggleMenu" class="menu">
      <div class="container" #mobileMenuView>
        <ul>
          <li
            [@childAnimation]="toggleMenu"
            (click)="menuItemSelect()"
            *ngFor="let item of nav"
          >
            <ng-template [ngxPermissionsOnly]="item.permissions">
              <a
                routerLinkActive="active-link"
                [routerLinkActiveOptions]="{ exact: item.exact }"
                routerLink="{{ item.link }}"
                ><div>{{ item.name }}</div></a
              >
            </ng-template>
          </li>
          <!-- TODO: Add monitoring to mobile menu -->

          <li
            [@childAnimation]="toggleMenu"
            (click)="menuItemSelect()"
            *ngFor="let item of nav3"
          >
            <ng-template [ngxPermissionsOnly]="item.permissions">
              <a
                routerLinkActive="active-link"
                [routerLinkActiveOptions]="{ exact: item.exact }"
                routerLink="{{ item.link }}"
                ><div>{{ item.name }}</div></a
              >
            </ng-template>
          </li>
          
          <li [@childAnimation]="toggleMenu" (click)="menuItemSelect()">
            <div (click)="editProfile()">Settings</div>
          </li>


          <ng-template ngxPermissionsOnly="Manage Users">
           <li [@childAnimation]="toggleMenu" (click)="menuItemSelect()">
              <a routerLink="/manage/users">
                <div>Manage Users</div>
              </a>
            </li>
          </ng-template>

          <li [@childAnimation]="toggleMenu" (click)="menuItemSelect()">
            <a routerLink="/logout">
              <div>Sign Out</div>
            </a>

        </ul>
      </div>
    </nav>
  </nav>
</div>
