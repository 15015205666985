<section class="noc-policy-page parent-view parent-container">
  <div class="widget noc-policy-widget">
    <div class="widget-header">
      <h1>Nitel Network Operations</h1>
    </div>
    <div>
      <div class="content-text">
        <h2>What we do</h2>

        <p>
          Nitel's Network Operations Center (NOC) strives to put customer satisfaction at the forefront of every aspect
          of our support model. Our NOC would be the first point of contact for any issues that may arise with your
          service after the service has been installed. This would include addressing any problems negatively impacting
          your service, change management, coordinating maintenance activity and analyzing and responding to deviations
          in our network performance monitoring.
        </p>
        <p>
          Our focus is clear and consistent communication, timely updates, and one-time resolution of any problems that
          may arise. Our technicians are available 24x7x365 to assist you in any way we can with the vast majority of
          our calls being answered and attended to in under 20 seconds.
        </p>
        <p>We look forward to helping you in any way we can!</p>
      </div>

      <div class="content-text">
        <h2>Contact Us</h2>
      </div>
      <div class="content-flex">
        <div class="header">Phone Number:</div>
        <div><a href="tel:8668920915">(866) 892-0915</a></div>
      </div>
      <div class="content-flex">
        <div class="header">Outage/Technical Issues:</div>
        <div><a href="mailto:noc@nitelusa.com">noc"&#64;"nitelusa.com</a></div>
      </div>
      <div class="content-flex">
        <div class="header">Change Management:</div>
        <div><a href="mailto:support@nitelusa.com">support"&#64;"nitelusa.com</a></div>
      </div>

      <div class="content-text">
        <p>
          <em>Please dial in for all urgent issues and Escalation requests to ensure a timely response.</em>
        </p>
      </div>
      <div class="content-flex">
        <div class="header">1st Level</div>
        <div>Technician on duty</div>
        <div><a href="tel:8668920915"> (866) 892-0915</a></div>
        <div><a href="mailto:noc@nitelusa.com">noc"&#64;"nitelusa.com</a></div>
      </div>
      <div class="content-flex">
        <div class="header">2nd Level</div>
        <div>Sr. Technician on duty</div>
        <div><a href="tel:8668920915">(866) 892-0915</a></div>
        <div><a href="mailto:noc@nitelusa.com">noc"&#64;"nitelusa.com</a></div>
      </div>
      <div class="content-flex">
        <div class="header">3rd Level</div>
        <div>Manager On Duty</div>
        <div><a href="tel:8668920915">(866) 892-0915</a></div>
        <div>
          <a
            href="mailto:noc@nitelusa.com
          "
            >noc"&#64;"nitelusa.com
          </a>
        </div>
      </div>
      <div class="content-flex">
        <div class="header">4th Level</div>
        <div>Director: Phil Lukacek</div>
        <div>
          <a href="tel:3129247729">(312) 924-7729</a> Office <br />
          or <a href="tel:7082851179 ">(708) 285-1179 </a> Cell
        </div>
        <div>
          <a
            href="mailto:plukacek@nitelusa.com
          "
            >plukacek"&#64;"nitelusa.com
          </a>
        </div>
      </div>
      <div class="content-flex">
        <div class="header">5th Level</div>
        <div>VP: Mauricio Lizano</div>
        <div>
          <a href="tel:2142759246">(214) 275-9246 </a> Office <br />
          or <a href="tel:50688216575 ">(+506) 8821-6575</a> Cell
        </div>
        <div>
          <a
            href="mailto:mlizano@nitelusa.com
          "
            >mlizano"&#64;"nitelusa.com
          </a>
        </div>
      </div>
      <div class="content-flex">
        <div class="header">6th Level</div>
        <div>SVP: Lawrence Edmond</div>
        <div>
          <a href="tel:7733643189">(773) 364-3189</a> Office <br />
          or <a href="tel:2197986414 ">(219) 798-6414</a> Cell
        </div>
        <div>
          <a
            href="mailto:ledmond@nitelusa.com
          "
            >ledmond"&#64;"nitelusa.com
          </a>
        </div>
      </div>
      <div class="content-flex">
        <div class="header">7th Level</div>
        <div>CEO: Margi Shaw</div>
        <div>
          <a href="tel:8727025050">(872) 702-5050</a> Office
          <br />
          or <a href="tel:6308009275 ">(630) 800-9275</a> Cell
        </div>
        <div><a href="mailto:mshaw@nitelusa.com"> mshaw"&#64;"nitelusa.com</a></div>
      </div>

      <!-- <div class="content-text">&nbsp;</div> -->

      <div class="content-text">
        <h2>Severity Definitions</h2>
        <h4>Severity 5: (Highest)</h4>
        <ul>
          <li>Critical network failure</li>
          <li>Entire network or systems down or inoperative</li>
          <li>Service affecting event &mdash; Severe business impact</li>
          <li>Continuous network performance impairment</li>
        </ul>
        <h4>Severity 4: (High)</h4>
        <ul>
          <li>Significant network degradation</li>
          <li>Service affecting &mdash; business impact</li>
        </ul>
        <h4>Severity 3: (Normal)</h4>
        <ul>
          <li>Minor network or system event/impairment</li>
          <li>Service affecting &mdash; minimal business impact</li>
          <li>Low priority</li>
        </ul>
        <h4>Severity 2: (Low)</h4>
        <ul>
          <li>Passive network or system issue/event</li>
          <li>Configuration and Design</li>
          <li>General Question</li>
          <li>Change Control</li>
        </ul>
      </div>

      <div class="content-flex">
        <div class="header">Tech on Duty</div>
        <div>Severity 5: Immediate</div>
        <div>Severity 4: Immediate</div>
        <div>Severity 3: Immediate</div>
        <div>Severity 2: Immediate</div>
      </div>
      <div class="content-flex">
        <div class="header">Manager</div>
        <div>Severity 5: 1 hour</div>
        <div>Severity 4: 4 hours</div>
        <div>Severity 3: 48 hours</div>
        <div>Severity 2: As Needed</div>
      </div>
      <div class="content-flex">
        <div class="header">Manager</div>
        <div>Severity 5: 4 hours</div>
        <div>Severity 4: 8 hours</div>
        <div>Severity 3: As Needed</div>
        <div>Severity 2: As Needed</div>
      </div>
      <div class="content-flex">
        <div class="header">Director</div>
        <div>Severity 5: 8 hours</div>
        <div>Severity 4: 16 hours</div>
        <div>Severity 3: As Needed</div>
        <div>Severity 2: As Needed</div>
      </div>
      <div class="content-flex">
        <div class="header">VP Level</div>
        <div>Severity 5: 16 hours</div>
        <div>Severity 4: 48 hours</div>
        <div>Severity 3: As Needed</div>
        <div>Severity 2: As Needed</div>
      </div>

      <div class="content-text">
        <h2>Proactive Monitoring and Notification Standards</h2>
        <p>
          Nitel proactively monitors Node/Device reachability of the Nitel managed CPE device every five (5) minutes
          with a failure being recognized if the device misses three (3) consecutive polling intervals. If there is a
          node reachability, Nitel will automatically open a Nitel trouble ticket and send an e-mail alert to the
          customer&apos;s Primary Outage Contact with the trouble ticket and a note for the customer to confirm the
          outage.
        </p>
        <p>
          This ticket will be set in to a “Pending Customer” status until the customer confirms the outage is genuine,
          and there is no local power issue or inside wiring work being performed at the customer premise. Once the
          customer confirms there is a genuine service issue, they should contact the Nitel NOC via phone, while
          referencing the Proactive Ticket number. The Proactive Ticket will then go in to a “Working” status, and Nitel
          will move forward to troubleshoot and isolate the problem. This change in status marks the beginning of the
          time interval for issue resolution.
        </p>
        <p>
          If the customer does not notify the Nitel NOC of any service affecting condition or issue, the trouble ticket
          will be automatically closed in 24 hours from the time of the initial notification if the service issues
          resolves. If the site remains unreachable, our ticket will remain open pending customer confirmation.
        </p>
        <ul>
          <li>
            Our NOC will require a site Local Contact name, phone number and site access-hours to open a ticket with our
            underlying vendors for investigation.
          </li>
          <li>Power should be verified to all equipment attached to the service</li>
        </ul>
        <h2>Change Management (Support)</h2>
        <ul>
          <li>All non-service affecting change requests should be submitted via email to support"&#64;"nitelusa.com</li>
          <li>
            Non-service affecting change management events are defined as items such as CPE Configuration Changes,
            Firewall Changes, Routing Changes, and Utilization Report Requests
          </li>
          <li>
            These non-service affecting change requests are managed by the Nitel Support Team during normal business
            hours of 8:00am to 8:00pm Central, Monday – Friday
          </li>
          <li>An acknowledgement of receipt for the change management request will be made within 2 hours</li>
          <li>
            Most change management request are completed within 24 &ndash; 72 hours, with Expedites being completed same
            day
          </li>
          <li>
            If additional time is needed to process your request Nitel Support support"&#64;"nitelusa.com will provide more
            details regarding any delays
          </li>
          <li>
            Change Requests requested outside of the normal business hours of 8:00am to 8:00pm Central, Monday &ndash;
            Friday will be subject to additional professional services fees.
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
