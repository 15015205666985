import {
  LoadTickets,
  LoadTicketsFail,
  LoadTicketsSuccess,
} from "../../../store";
import { getTicketType, Ticket, TicketType } from "src/app/models/ticket.model";
import { ChartService } from "./chart.service";
import { SingleValueKPI } from "../../types";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ChartType } from "../../types/chart-type";


@Injectable()
export class OpenMaintenanceCasesChartService extends ChartService<SingleValueKPI> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadTickets,
    failure: LoadTicketsFail,
    success: LoadTicketsSuccess,
  }).pipe(
    map(({ loading, data }) => {
      const dataValue: number =
        data?.filter((s) => this.filterByStatus(s)).length || 0;

      return {
        ngClass: [],
        loading,
        data: {
          type: ChartType.SINGLE,
          id: "Open/Scheduled maintenance cases",
          title: "Open Scheduled Maintenance Tickets",
          value: dataValue,
          hyperlink: "/tickets", // need flags
        } satisfies SingleValueKPI,
      };
    })
  );

  private filterByStatus(ticket: Ticket): boolean {
    return getTicketType(ticket) === TicketType.Maintenance && !ticket.IsClosed;
  }
}
