<h2 mat-dialog-title>Edit Profile Settings</h2>
<mat-dialog-content class="dialog-content">
  <div class="loading-overlay" *ngIf="isSubmitting$ | async">
    <mat-progress-spinner
      mode="indeterminate"
      color="primary"
      diameter="50"
    ></mat-progress-spinner>
  </div>
  <form [formGroup]="userForm">
    <div class="column">
      <!-- First Name -->
      <mat-form-field appearance="fill">
        <mat-label>First Name</mat-label>
        <input
          matInput
          id="firstName"
          formControlName="firstName"
          placeholder="Enter your first name"
          aria-label="First Name"
        />
        <mat-error
          *ngIf="
            userForm.get('firstName').invalid &&
            userForm.get('firstName').touched
          "
        >
          Please provide your first name.
        </mat-error>
      </mat-form-field>

      <!-- Last Name -->
      <mat-form-field appearance="fill">
        <mat-label>Last Name</mat-label>
        <input
          matInput
          id="lastName"
          formControlName="lastName"
          placeholder="Enter your last name"
          aria-label="Last Name"
        />
        <mat-error
          *ngIf="
            userForm.get('lastName').invalid && userForm.get('lastName').touched
          "
        >
          Please provide your last name.
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Email and Password Management -->
    <div class="email-pass-mngmt">
      <div class="form-actions">
        <!-- <button
          mat-raised-button
          color="accent"
          (click)="handleEmailOrPasswordChange()"
          aria-label="Change Email"
        >
          Change Email
        </button> -->
        <button
          mat-raised-button
          color="warn"
          (click)="handleEmailOrPasswordChange()"
          aria-label="Reset Password"
        >
          Reset Password
        </button>
      </div>
      <p class="note">
        <mat-icon aria-hidden="true">info</mat-icon>
        Note: Clicking the button to change your password will log you out.
      </p>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions">
  <button
    mat-button
    (click)="onCancel()"
    [disabled]="isSubmitting$ | async"
    aria-label="Cancel"
  >
    Cancel
  </button>
  <button
    mat-raised-button
    [disabled]="userForm.invalid || (isSubmitting$ | async)"
    color="primary"
    (click)="onSave()"
    cdkFocusInitial
    aria-label="Save Changes"
  >
    Save Changes
  </button>
</mat-dialog-actions>
