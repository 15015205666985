<div class="chart-container">
  <apx-chart
    [series]="chartOptionsQOEByTime.series"
    [chart]="chartOptionsQOEByTime.chart"
    [dataLabels]="chartOptionsQOEByTime.dataLabels"
    [plotOptions]="chartOptionsQOEByTime.plotOptions"
    [title]="chartOptionsQOEByTime.title"
    [xaxis]="chartOptionsQOEByTime.xaxis"
    [yaxis]="chartOptionsQOEByTime.yaxis"
  ></apx-chart>
</div>
