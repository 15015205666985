// common
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// ngrx
import { StoreModule } from '@ngrx/store';

// combined common modules into one file
import { SharedModule } from '../shared/shared.module';

// components & containers
import * as fromContainers from './containers';
import * as fromComponents from './components';
import * as fromUsersReducer from '../store/reducers/users.reducer';

// guards
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgChartsModule } from 'ng2-charts';

import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


// date and time pickers
import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

// apex charts
import { NgApexchartsModule } from "ng-apexcharts";
import { MatInputModule } from '@angular/material/input';

import { DashboardModule } from 'src/app/dashboard/dashboard.module';
import { WidgetSummaryComponent } from './components/widgets/widget-summary/widget-summary.component';
import { WidgetBillingComponent } from './components/widgets/widget-billing/widget-billing.component';
import { WidgetTicketsComponent } from './components/widgets/widget-tickets/widget-tickets.component';
import { WidgetOrdersComponent } from './components/widgets/widget-orders/widget-orders.component';
import { WidgetServicesComponent } from './components/widgets/widget-services/widget-services.component';

const overallNetworkStatusPageRoutes: Routes = [
  {
    path: '',
    component: fromContainers.OverallNetworkStatusPageComponent,
    canActivate: [NgxPermissionsGuard]
  }
];

@NgModule({
  imports: [
    SharedModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatFormFieldModule,
    NgChartsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    ScrollingModule,
    RouterModule.forChild(overallNetworkStatusPageRoutes),
    StoreModule.forFeature('user', fromUsersReducer.reducer),
    WidgetServicesComponent,
    WidgetSummaryComponent,
    WidgetBillingComponent,
    WidgetTicketsComponent,
    WidgetOrdersComponent,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    NgApexchartsModule,
    MatGridListModule,
    MatToolbarModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatInputModule,
    // DashboardModule
  ],
  declarations: [...fromComponents.components, ...fromContainers.containers],
  exports: [...fromComponents.components, ...fromContainers.containers],
})
export class OverallNetworkStatusModule {}
