  <div class="chart-container">
    <div class="loading-spinner" *ngIf="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div class = "chart-wrapper">
      <canvas
        class="chart-canvas"
        baseChart
        [data]="pieChartData"
        [options]="pieChartOptions"
        [labels]="pieChartData.labels"
        [type]="pieChartType"
      ></canvas>
      <div *ngIf="(!pieChartData?.datasets?.[0]?.data?.length) && !loading" class="no-data">
        <svg width="400" height="250px">
          <circle cx="200" cy="125" r="115" class="no-tickets-circle"></circle>
          <text x="200" y="125" font-size="15" text-anchor="middle" fill="#ffff">No tickets for given time range</text>
        </svg>
      </div>
    </div>
  </div>
