import { NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';

import { DashboardComponent } from './dashboard-page/dashboard.component';
import { DashboardWidgetComponent } from './dashboard-widget/dashboard-widget.component';
import { WidgetBillingComponent } from './widget-billing/widget-billing.component';
import { WidgetTicketsComponent } from './widget-tickets/widget-tickets.component';
import { WidgetOrdersComponent } from './widget-orders/widget-orders.component';
import { WidgetServicesComponent } from './widget-service/widget-services.component';
import { WidgetDirective } from './widget.directive';
import { SharedModule } from '../shared/shared.module';
import { WidgetMonitoringComponent } from './widget-monitoring/widget-monitoring.component';
import { WidgetSummaryComponent } from './widget-summary/widget-summary.component';

import * as fromReducer from '../store/reducers';
import * as fromNodesReducer from '../store/reducers/nodes.reducer';
import * as fromUsersReducer from '../store/reducers/users.reducer';
import { OverallNetworkStatusModule } from '../overall-network-status/overall-network-status.module';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    OverallNetworkStatusModule,
    StoreModule.forFeature('billing', fromReducer.billingReducers),
    StoreModule.forFeature('nodes', fromNodesReducer.reducer),
    StoreModule.forFeature('user', fromUsersReducer.reducer),
    StoreModule.forFeature('tickets', fromUsersReducer.reducer),
    StoreModule.forFeature('orders', fromUsersReducer.reducer),
  ],
  declarations: [
    DashboardComponent,
    DashboardWidgetComponent,
    WidgetBillingComponent,
    WidgetMonitoringComponent,
    WidgetOrdersComponent,
    WidgetSummaryComponent,
    WidgetTicketsComponent,
    WidgetServicesComponent,
    WidgetDirective,
  ],
  exports:[
    DashboardComponent,
    DashboardWidgetComponent,
    WidgetBillingComponent,
    WidgetMonitoringComponent,
    WidgetOrdersComponent,
    WidgetSummaryComponent,
    WidgetTicketsComponent,
    WidgetServicesComponent,
    WidgetDirective
  ],
  providers: [DecimalPipe]
})
export class DashboardModule {}
