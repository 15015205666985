<div class="line-values-item stats" [ngClass]="{ 'line-values-item--loading': loading }">
  <div class="line-values-item__header">
    <h1 class="line-values-item__title">
      {{ data$()?.title }}
    </h1>

    <div>
      <button
        mat-icon-button
        class="line-values-item__expand"
        (click)="downloadXlsx()"
      >
        <mat-icon>file_open</mat-icon>
      </button>
      <button
        mat-icon-button
        class="line-values-item__expand"
        (click)="openModal()"
      >
        <mat-icon>fullscreen</mat-icon>
      </button>
    </div>
  </div>
  <div class="line-values-item__body">
    <div class="line-values-item__chart">
      <canvas
        baseChart
        [type]="chartService$.lineChartType"
        [data]="chartService$.lineChartData$()"
        [options]="chartService$.lineChartOptions"
        [plugins]="chartService$.lineChartPlugins"
        (chartClick)="chartService$.chartClicked($event)"
      ></canvas>
    </div>
    <mat-spinner class="line-values-item__spinner"
      *ngIf="loading"
      [diameter]="75"
    ></mat-spinner>
  </div>

  <!-- <div class="line-values-item__footer">
      <a [routerLink]="data$()?.hyperlink">
        {{ data$()?.hyperlinkText || "View " + data$()?.title }}
      </a>
    </div> -->
</div>
