import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ApiService } from './api.service';
import { api } from '../models/api-url.model';
import { PortalRole } from '../models/portal-role.model';

@Injectable({
  providedIn: 'root'
})
export class PortalRoleService {
  constructor(private apiService: ApiService) {}

  getPortalRoles(): Observable<PortalRole[]> {
    return this.apiService.get(api.portalRolesURL);
  }
}
