<h2 mat-dialog-title>{{ isEditMode ? "Edit User" : "Invite User" }}</h2>
<mat-dialog-content>
  <div class="loading-overlay" *ngIf="isSubmitting$ | async">
    <mat-progress-spinner
      mode="indeterminate"
      color="primary"
      diameter="50"
    ></mat-progress-spinner>
  </div>
  <form [formGroup]="userForm" (ngSubmit)="onSave()">
    <div class="column">
      <mat-form-field appearance="fill">
        <mat-label>First Name</mat-label>
        <input
          matInput
          formControlName="firstName"
          placeholder="Enter first name"
        />
        <mat-error *ngIf="userForm.get('firstName').invalid">
          First name is required
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Last Name</mat-label>
        <input
          matInput
          formControlName="lastName"
          placeholder="Enter last name"
        />
        <mat-error *ngIf="userForm.get('lastName').invalid">
          Last name is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="fill" style="display: flex">
        <mat-label>Email</mat-label>
        <input
          matInput
          formControlName="email"
          placeholder="Enter email"
          type="email"
        />
        <mat-error *ngIf="userForm.get('email').invalid">
          A valid email is required
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field appearance="fill" style="display: flex">
        <mat-label>Role</mat-label>
        <mat-select formControlName="portalRole" placeholder="Select user role">
          <ng-container *ngFor="let portalRole of portalRoles$ | async">
            <mat-option *ngIf="portalRole.Name" [value]="portalRole">
              {{ portalRole.Name }}
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-error *ngIf="userForm.get('portalRole').invalid"
          >Role is required</mat-error
        >
      </mat-form-field>
    </div>
    <div>
      <mat-error *ngIf="hasSubmissionError$ | async">{{ errorMessage }}</mat-error>
    </div>
    
    <mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="userForm.invalid || (isSubmitting$ | async)"
      >
        {{ isEditMode ? "Save changes" : "Send invitation" }}
      </button>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>
