import { computed, inject, Injectable, signal } from "@angular/core";
import { LineValuesKpi } from "../../../types";
import { chartDefaults } from "../defaults";
import { DatePipe } from "@angular/common";

import type { ChartType, ChartEvent } from "chart.js";

@Injectable()
export class ChartService {
  public readonly lineChartType: ChartType = "line";
  public readonly lineChartPlugins = chartDefaults.plugins();
  public readonly lineChartOptions = chartDefaults.options();
  public readonly data$ = signal<LineValuesKpi>(null!);
  private readonly datePipe = inject(DatePipe);

  public readonly lineChartData$ = computed(() => {
    const yAxisLabel = this.data$()?.yAxisLabel?.trim();
    const lineChartData = chartDefaults.data();

    this.lineChartOptions.scales.y['title'] = {
      display: Boolean(yAxisLabel),
      text: yAxisLabel,
    };

    lineChartData.labels = [];
    lineChartData.datasets[0].data = [];
    lineChartData.datasets[0].label = "Ticket Count";

    this.data$()?.value.forEach((item) => {
      lineChartData.labels!.push(this.datePipe.transform(item.label, 'MM/dd/yyyy'));
      lineChartData.datasets[0].data.push(item.value);
    });

    return lineChartData;
  });

  public chartClicked(event: { event?: ChartEvent; active?: object[] }): void {
    if (!event.active?.length || event.active?.[0]?.["index"] === undefined) {
      return;
    }
  }
}
