import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import {
  Router,
  RouterEvent,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
  NavigationCancel,
} from "@angular/router";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { Store } from "@ngrx/store";
import {
  filter,
  Observable,
  Subject,
  Subscription,
  take,
  takeUntil,
} from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { NgxPermissionsService } from "ngx-permissions";

import * as fromStore from "src/app/store/";
import { environment } from "src/environments/environment";
import { SessionExpiredComponent } from "./shared/components";
import { StateService } from "./services/state.service";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from "@azure/msal-angular";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  PromptValue,
  RedirectRequest,
} from "@azure/msal-browser";
import { entraConfig } from "./msal.config";
import { AuthService } from "./services/auth.service";
import { User } from "./models/user.model";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  title = "MyNitel";
  loading = true;
  permissions = [];
  // How long a user can be inactive before considered idle, in seconds.
  sessionLengthInSeconds = 900; // 15 minutes.
  // How long a user can be idle before considered timed out, in seconds.
  timeoutWarningSeconds = 60; // 1 minute.
  timedOut = false;
  lastPing: Date = null;

  browserSubscription: Subscription;
  routerSubscription: Subscription;
  browserSize$: Observable<string>;
  browserSize: string;
  loggedInUser$: Observable<User>;

  displayLoadingSpinner: boolean = false;

  isIframe = false;

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private idle: Idle,
    private keepAlive: Keepalive,
    private router: Router,
    private store: Store<fromStore.State>,
    private state: StateService,
    private gtmService: GoogleTagManagerService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthService,
    private permissionsService: NgxPermissionsService
  ) {
    this.browserSize$ = this.store.select(fromStore.getBrowserSizeName);
    this.browserSubscription = this.browserSize$.subscribe((name) => {
      if (name) {
        this.browserSize = name;
      }
    });

    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      this.checkRouterEvent(event);
      // google analytics
      if (
        (environment.production || environment.dev) &&
        event instanceof NavigationEnd
      ) {
        const gtmTag = { page_path: event.urlAfterRedirects };
        this.gtmService.pushTag(gtmTag);
      }
    });

    this.initSessionTimeouts();

    this.state.getState().subscribe((st) => {
      if (st == "hide_loading_overlay") {
        this.toggleLoadingOverlay(false);
      }
      if (st == "show_loading_overlay") {
        this.toggleLoadingOverlay(true);
      }
    });
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.startMsalBroadcast();
    this.loggedInUser$ = this.store.select(fromStore.getCurrentUser);

    this.loggedInUser$
      .pipe(
        filter((user) => user != null),
        take(1)
      )
      .subscribe((user) => {
        if (user?.Permissions?.length) {
          this.permissions = user.Permissions;
          this.permissionsService.loadPermissions(this.permissions);
        } else if (user && !user?.Permissions?.length) {
          this.redirectToAccessDenied();
        }

        if (this.authService.isLoggedIn) {
          this.authService.trackUser(user);
          this.idle.watch();
        }
      });
  }

  startMsalBroadcast() {
    this.msalService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED
          // ||
          //   msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        // if (this.msalService.instance.getAllAccounts().length === 0) {
        //   window.location.pathname = "/dashboard";
        // }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(async (result: EventMessage) => {
        this.toggleLoadingOverlay(true);
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as any;

        if (
          idtoken.acr === entraConfig.userFlows.signIn ||
          idtoken.tfp === entraConfig.userFlows.signIn
        ) {
          this.msalService.instance.setActiveAccount(payload.account);
          this.authService
            .signIn()
            .pipe(take(1))
            .subscribe({
              next: (response) => {
                if (!response?.user?.Permissions?.length) {
                  this.redirectToAccessDenied();
                } else {
                  window.location.pathname = "/dashboard";
                  this.toggleLoadingOverlay(false);
                }
              },
              error: (error) => {
                console.error("API Error during sign-in:", error);
                window.location.pathname = "/login";
                this.toggleLoadingOverlay(false);
              },
            });
        }

        /**
         * Below we are checking if the user is returning from the reset password flow.
         * If so, we will ask the user to reauthenticate with their new password.
         * If you do not want this behavior and prefer your users to stay signed in instead,
         * you can replace the code below with the same pattern used for handling the return from
         * profile edit flow (see above ln. 74-92).
         */
        if (
          idtoken.acr === entraConfig.userFlows.passwordReset ||
          idtoken.tfp === entraConfig.userFlows.passwordReset
        ) {
          let signUpSignInFlowRequest: RedirectRequest = {
            authority: entraConfig.userFlows.signIn,
            scopes: [...environment.entra_nitelapi_scopes],
            prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
          };

          this.loginEntra(signUpSignInFlowRequest);
        }

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(async (result: EventMessage) => {
        this.authService.signout();
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(async (result: EventMessage) => {
        let payload = result.payload as AuthenticationResult;
        let idtoken = payload.idTokenClaims as any;

        if (
          idtoken.acr === entraConfig.userFlows.signIn ||
          idtoken.tfp === entraConfig.userFlows.signIn
        ) {
          this.msalService.instance.setActiveAccount(payload.account);
        }

        return result;
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_FAILURE ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        this.authService.clearCache();
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (result.error && result.error.message.indexOf("AADB2C90118") > -1) {
          let resetPasswordFlowRequest: RedirectRequest = {
            authority: entraConfig.userFlows.passwordReset,
            scopes: [],
          };

          this.loginEntra(resetPasswordFlowRequest);
        }
      });
  }

  loginEntra(userFlowRequest: RedirectRequest) {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
        ...userFlowRequest,
      } as RedirectRequest);
    } else {
      this.msalService.loginRedirect(userFlowRequest);
    }
  }

  checkAndSetActiveAccount() {
    /**
     *
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      activeAccount = this.msalService.instance.getActiveAccount();
      // window.location.pathname = "/";
    }
  }

  redirectToAccessDenied(): void {
    window.location.pathname = "/access-denied";
  }

  ngOnDestroy() {
    this.browserSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();

    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  initSessionTimeouts() {
    // Set idle and timeout limits.
    // See if there is an expires entry in local storage
    const expires = localStorage.getItem("ng2Idle.main.expiry");
    if (expires) {
      const timestamp = Number(expires);
      const expireDate = new Date(timestamp);
      const remainingSession = Math.floor(
        (expireDate.getTime() - Date.now()) / 1000
      );

      // If the user has time left in their session from a previous visit or another tab
      // Set the timeout from localstorage
      if (remainingSession > 0) {
        this.idle.setIdle(remainingSession);
      } else {
        this.idle.setIdle(this.sessionLengthInSeconds);
      }
    } else {
      this.idle.setIdle(this.sessionLengthInSeconds);
    }

    this.idle.setTimeout(this.timeoutWarningSeconds);

    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // No longer idle
    this.idle.onIdleEnd.subscribe(() => {
      this.resetSession();
    });

    // Timed out
    this.idle.onTimeout.subscribe(() => {
      this.timedOut = true;

      this.dialog.closeAll();
      this.authService.signout("expired");
    });

    // User is idle
    this.idle.onIdleStart.subscribe(() => {
      this.idle.clearInterrupts();
      this.openDialog();
    });

    // dialog countdown start
    this.idle.onTimeoutWarning.subscribe((/* countdown */) => {});

    // sets the ping interval
    this.keepAlive.interval(this.sessionLengthInSeconds);
    this.keepAlive.onPing.subscribe(() => {
      this.lastPing = new Date();
      return true;
    });
  }

  checkRouterEvent(routerEvent: Event | RouterEvent): void {
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.loading = false;
    }
  }

  resetSession() {
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.watch();
    this.timedOut = false;
  }

  openDialog() {
    // Dialog for session timeout
    const dialogConfig = {
      autoFocus: true,
      minHeight: "315px",
      width: this.browserSize === "small" ? "calc(100vw - 20px)" : "650px",
      maxWidth: "650px",
      data: {
        browserSize: this.browserSize,
      },
      disableClose: true,
    };

    const sessionExpiredDialog = this.dialog.open(
      SessionExpiredComponent,
      dialogConfig
    );
    sessionExpiredDialog.afterClosed().subscribe((staySignedIn) => {
      if (staySignedIn) {
        this.resetSession();
        this.changeDetectorRef.detectChanges();
      } else {
        this.authService.signout("expired");
        this.changeDetectorRef.detectChanges();
      }
    });
  }

  toggleLoadingOverlay(show: boolean) {
    this.displayLoadingSpinner = show;
    this.changeDetectorRef.detectChanges();
  }
}
