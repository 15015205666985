import { WidgetNumberDisplayComponent } from '../widget-number-display/widget-number-display.component';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { SharedModule } from '../../../shared.module';

@Component({
  standalone: true,
  selector: 'app-widget-tickets-display',
  styleUrls: ['./widget-tickets-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    WidgetNumberDisplayComponent,
    SharedModule,
  ],
  template: `
    <app-widget-number-display
      class="widget-tickets-display dimensions-stretch"

      [status]="'ACTIVE'"
      [number]="ticketCount"
      [label]="(ticketCount || 0) | pluralize: 'Ticket'"

      [hyperlink]="hyperlink"
      [ctaText]="'View Tickets'"
      [navigateOnClick]="navigateOnClick"
      [loading]="loading"
    >
      <i titlePrefixIcon class="widget-element-icon custom-icon ticket_lrg_cir_white"></i>

      <div class="ticket-icon">
        <ng-container *ngIf="ticketCount > 0; else noTickets">
          <img alt="ticket widget" src="/assets/images/img_widget_ticket@2x.png" />
        </ng-container>
        <ng-template #noTickets>
          <img alt="ticket widget" src="/assets/images/img_widget_ok@2x.png" />
        </ng-template>
      </div>
    </app-widget-number-display>
  `,
})
export class WidgetTicketsDisplayComponent {

  @Input() loading: boolean = true;
  @Input() hyperlink: string | null = null;
  @Input() navigateOnClick: boolean = false;
  @Input() ticketCount: number | null = 0;

}
