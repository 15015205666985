import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-data-consumption',
  templateUrl: './data-consumption.component.html',
  styleUrls: ['./data-consumption.component.scss']
})
export class DataConsumptionComponent {
  @Input() highestDataConsumption: number = 0;
  @Input() totalDataConsumption: number = 0;
}
