import { GaugeValueModalComponent } from './gauge-value-modal/gauge-value-modal.component';
import { Component, ElementRef, inject, Input, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { GaugeValueKPI } from '../../types';
import * as domtoimage from "dom-to-image-more";
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-gauge-value',
  templateUrl: './gauge-value.component.html',
  styleUrls: ['./gauge-value.component.scss']
})
export class GaugeValueComponent {

  @ViewChild("modalContent") modalContent: ElementRef | undefined;
  @Input({ required: true }) data: GaugeValueKPI;
  @Input() loading: boolean = false;

  public downloadImage(): void {
    const contentElement = this.modalContent?.nativeElement;

    if (contentElement) {
      const options = {
        width: contentElement.offsetWidth,
        height: contentElement.offsetHeight,
        scale: 1.5,
        style: {
          transform: "scale(1)",
          transformOrigin: "top center",
        },
      };

      domtoimage
        .toPng(contentElement, options)
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "component-image.png";
          link.click();
        })
        .catch((error) => {
          console.error("Error", error);
        });
    }
  }


  private readonly matDialog = inject(MatDialog);
  public openModal(): void {
    this.matDialog.open<GaugeValueModalComponent, GaugeValueKPI>(
      GaugeValueModalComponent,
      {
        width: "calc(100vw - 1rem)",
        hasBackdrop: true,
        data: this.data,
      },
    );
  }

  public downloadXlsx(): void {
    const data = [
      ['Metric', 'Value'],
      ['Current Value', this.data.value],
      ['Max Value', this.data.maxValue],
      ['Threshold 0', this.data.maxValue * 0.0],
      ['Threshold 25%', this.data.maxValue * 0.25],
      ['Threshold 50%', this.data.maxValue * 0.5],
      ['Threshold 75%', this.data.maxValue * 0.75],
      ['Threshold 100%', this.data.maxValue * 1.0],
    ];

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(data);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Gauge Data');

    XLSX.writeFile(wb, 'gauge-data.xlsx');
  }
}
