import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { NgChartsModule } from 'ng2-charts';
import { NgxGaugeModule } from 'ngx-gauge';

import { OperationsComponent } from './operations-dashboard/operations-page.component';
import { SingleValueComponent } from './widgets/single-value/single-value.component';
import { SingleValueModalComponent } from './widgets/single-value/single-value-modal/single-value-modal.component';
import { GaugeValueComponent } from './widgets/gauge-value/gauge-value.component';
import { GaugeValueModalComponent } from './widgets/gauge-value/gauge-value-modal/gauge-value-modal.component';
import { VerticalBarsComponent } from './widgets/vertical-bars/vertical-bars.component';
import { VerticalBarsModalComponent } from './widgets/vertical-bars/vertical-bars-modal/vertical-bars-modal.component';
import { HorizontalBarsComponent } from './widgets/horizontal-bars/horizontal-bars.component';
import { HorizontalBarsModalComponent } from './widgets/horizontal-bars/horizontal-bars-modal/horizontal-bars-modal.component';
import { LineValuesComponent } from './widgets/line-values/line-values.component';
import { LineValuesModalComponent } from './widgets/line-values/line-values-modal/line-values-modal.component';
import { DownloadChartModalComponent } from './operations-dashboard/services/download-chart/download-chart-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    MatMenuModule,
    NgChartsModule,
    NgxGaugeModule,
    MatTooltipModule,
  ],
  declarations: [
    OperationsComponent,
    SingleValueModalComponent,
    SingleValueComponent,
    VerticalBarsComponent,
    VerticalBarsModalComponent,
    HorizontalBarsComponent,
    HorizontalBarsModalComponent,
    GaugeValueComponent,
    GaugeValueModalComponent,
    LineValuesComponent,
    LineValuesModalComponent,
    DownloadChartModalComponent,
  ],
  providers: [DecimalPipe]
})
export class OperationsDashboardModule { }
