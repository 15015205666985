import { Component, ViewChild, Input } from '@angular/core';

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexXAxis,
  ApexYAxis,
  ApexPlotOptions,
  ApexTitleSubtitle
} from "ng-apexcharts";

export type chartOptionsQOEByTime = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle
};

@Component({
  selector: 'qoe-by-time-chart',
  templateUrl: './qoe-by-time-chart.component.html'
})
export class QOEByTimeChartComponent {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptionsQOEByTime: Partial<chartOptionsQOEByTime>;

  @Input() data: any[] = [];
  @Input() categories: string[] = [];

  constructor() {
    this.chartOptionsQOEByTime = {
      series: [
        {
          name: "basic",
          data: []
        }
      ],
      chart: {
        type: "bar",
        height: 360,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        }
      },
      title: {
        text: 'QoE By Time of Day',
        align: 'center',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
      },
      dataLabels: {
        enabled: false
      },
      yaxis: {
      },
      xaxis: {
        categories: [],
        labels: {
          hideOverlappingLabels: false,
          showDuplicates: true
        },
      },
    };
  }

  ngOnChanges() {
    this.chartOptionsQOEByTime.series = [
      {
        name: "QOE By Time",
        data: this.data
      }
     ];
    this.chartOptionsQOEByTime.xaxis.categories = this.categories;
  }
}
