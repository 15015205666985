import { WidgetNumberDisplayComponent } from '../widget-number-display/widget-number-display.component';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { SharedModule } from '../../../shared.module';

@Component({
  standalone: true,
  selector: 'app-widget-services-display',
  styleUrls: ['./widget-services-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    WidgetNumberDisplayComponent,
    SharedModule,
  ],
  template: `
    <app-widget-number-display
      class="widget-services-display dimensions-stretch"
      [status]="'ACTIVE'"
      [number]="serviceCount"
      [label]="(serviceCount || 0) | pluralize: 'Service'"

      [hyperlink]="hyperlink"
      [ctaText]="'View Services'"
      [navigateOnClick]="navigateOnClick"
      [loading]="loading"
    >
      <div titlePrefixIcon class="widget-element-icon custom-icon">
        <i class="material-icons">directions_run</i>
      </div>

      <div class="service_icon">
        <div class="total-locations">Spanning {{ locationCount }} locations</div>
      </div>
    </app-widget-number-display>
  `,
})
export class WidgetServicesDisplayComponent {

  @Input() loading: boolean = true;
  @Input() hyperlink: string | null = null;
  @Input() navigateOnClick: boolean = false;
  @Input() locationCount: number = 0;
  @Input() serviceCount: number = 0;

}
