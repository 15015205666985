import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subject, takeUntil } from "rxjs";

import * as fromStore from "src/app/store/";
import { widgetTypes } from "../widget-types";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent implements OnInit, OnDestroy {
  widgetTypes = widgetTypes;
  browserSize$: Observable<string> = this.store.select(
    fromStore.getBrowserSizeName
  );
  browserSize: string;
  orionUrl$ = this.store.select(fromStore.getOrionURL);
  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<fromStore.State>,
  ) {}

  ngOnInit() {
    this.browserSize$.pipe(takeUntil(this.destroy$)).subscribe((name) => {
      if (name) this.browserSize = name;
    });

    this.store.dispatch(new fromStore.LoadTickets());
    this.store.dispatch(new fromStore.LoadInvoices());
    this.store.dispatch(new fromStore.LoadPayments());
    this.store.dispatch(new fromStore.LoadOrders());
    this.store.dispatch(new fromStore.LoadDownNodes());
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
