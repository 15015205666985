import { ContactEffects } from './contacts.effect';
import { InvoiceAttachmentEffects } from './invoice-attachments.effect';
import { InvoiceEffects } from './invoices.effect';
import { LineItemEffects } from './line-items.effect';
import { NodeEffects } from './nodes.effect';
import { OrderEffects } from './orders.effect';
import { PaymentEffects } from './payments.effect';
import { PortalRoleEffects } from './portal-roles.effect';
import { RouterEffects } from './router.effect';
import { ServiceEffects } from './services.effect';
import { SOFEffects } from './sof.effect';
import { TicketEffects } from './tickets.effect';
import { UserEffects } from './users.effect';

// register effects in here as a whole to keep code well maintained
export const effects: any[] = [
  ContactEffects,
  InvoiceAttachmentEffects,
  InvoiceEffects,
  LineItemEffects,
  NodeEffects,
  OrderEffects,
  PaymentEffects,
  PortalRoleEffects,
  RouterEffects,
  ServiceEffects,
  SOFEffects,
  TicketEffects,
  UserEffects
];

export * from './contacts.effect';
export * from './invoice-attachments.effect';
export * from './invoices.effect';
export * from './line-items.effect';
export * from './nodes.effect';
export * from './orders.effect';
export * from './payments.effect';
export * from './portal-roles.effect';
export * from './router.effect';
export * from './services.effect';
export * from './sof.effect';
export * from './tickets.effect';
export * from './users.effect';
