<section class="ticket-content-container">
  <div class="custom-icon close" (click)="closeDialog()"></div>
  <section
    class="type-select-container"
    [ngClass]="{
      show: !(isTicketOptionSelected$ | async) && !(ticketSubmitted$ | async)
    }"
  >
    <div *ngIf="!(isTicketOptionSelected$ | async)">
      <form
        class="ticket-type-select"
        [formGroup]="ticketFormGroup"
        *ngIf="ticketFormGroup"
        [ngClass]="{ 'form-submit-attempt': formSubmitAttempted }"
        autocomplete="off"
      >
        <h1>Create Ticket</h1>

        <div
          class="loading-indicator"
          *ngIf="(areServicesLoading$ | async) || (areInvoicesLoading$ | async)"
        >
          <mat-spinner [diameter]="40"></mat-spinner>
        </div>
        <div
          class="question-wrapper"
          *ngIf="
            !(areServicesLoading$ | async) &&
              !(areInvoicesLoading$ | async) &&
              ((services$ | async).length || (invoices$ | async).length);
            else cannotCreateTicket
          "
        >
          <div class="question">What's the subject matter of your ticket?</div>
          <mat-form-field>
            <mat-label>Select an option</mat-label>
            <mat-select
              formControlName="supportType"
              (selectionChange)="validateFields()"
            >
              <ng-container *ngxPermissionsOnly="'Services'">
                <ng-container *ngIf="(services$ | async)?.length">
                  <mat-option value="Service"
                    >NOC - Service Outages or Performance Issues</mat-option
                  >
                </ng-container>
              </ng-container>
              <ng-container *ngxPermissionsOnly="'Invoice and Billing: Read'">
                <ng-container *ngIf="(invoices$ | async)?.length">
                  <mat-option value="Billing"
                    >Billing and Payment Inquiries</mat-option
                  >
                </ng-container>
              </ng-container>
              <ng-container *ngxPermissionsOnly="['Orders', 'Services']">
                <ng-container *ngIf="(services$ | async)?.length">
                  <mat-option value="TOB">Transfer of Services</mat-option>
                  <mat-option value="Disconnect"
                    >Disconnect a Service</mat-option
                  >
                </ng-container>
              </ng-container>
              <mat-option value="Care"
                >Service Alterations and General Inquiries</mat-option
              >
            </mat-select>
          </mat-form-field>
          <div class="form-error" *ngIf="inlineErrors.supportType">
            <div class="custom-icon inline_error"></div>
            {{ inlineErrors.supportType }}
          </div>
          <button class="button" (click)="makeTicketSelection()">
            <span>Next</span>
          </button>
        </div>
        <ng-template #cannotCreateTicket>
          <div
            class="question"
            *ngIf="
              (areInvoicesLoaded$ | async) &&
              (areServicesLoaded$ | async) &&
              !(areServicesLoading$ | async) &&
              !(areInvoicesLoading$ | async)
            "
          >
            Your account does not currently have any active services.
          </div>
        </ng-template>
      </form>
    </div>
  </section>

  <section
    class="form-type-container"
    [ngClass]="{
      show: (isTicketOptionSelected$ | async) && !(ticketSubmitted$ | async)
    }"
  >
    <button class="back-button" (click)="goBack()">
      <div class="custom-icon arrow_back"></div>
      Back
    </button>
    <app-ticket-noc
      *ngIf="
        (isTicketOptionSelected$ | async) &&
        ticketFormGroup.value.supportType === 'Service'
      "
      [services]="services$ | async"
      [selectedServiceId]="selectedServiceId"
      [isLoading]="areServicesLoading$ | async"
      [isLoaded]="areServicesLoaded$ | async"
      [primaryReporters]="contacts$ | async"
      [submissionError]="hasSubmissionError$ | async"
      [isSubmitting]="isSubmitting$ | async"
      (submitNocForm)="submitNocForm($event)"
      [errorMessage]="errorMessage"
    ></app-ticket-noc>
    <app-ticket-billing
      *ngIf="
        (isTicketOptionSelected$ | async) && ticketFormGroup.value.supportType === 'Billing'
      "
      [invoices]="invoices$ | async"
      [isLoading]="areInvoicesLoading$ | async"
      [isLoaded]="areInvoicesLoaded$ | async"
      [submissionError]="hasSubmissionError$ | async"
      [isSubmitting]="isSubmitting$ | async"
      (submitBillForm)="submitBillForm($event)"
      [errorMessage]="errorMessage"
    ></app-ticket-billing>
    <app-ticket-disconnect
      *ngIf="
        (isTicketOptionSelected$ | async) && ticketFormGroup.value.supportType === 'Disconnect'
      "
      [services]="services$ | async"
      [selectedServiceId]="selectedServiceId"
      [isLoading]="areServicesLoading$ | async"
      [isLoaded]="areServicesLoaded$ | async"
      [primaryReporters]="contacts$ | async"
      [disconnectReasons]="disconnectReasons$ | async"
      [submissionError]="hasSubmissionError$ | async"
      [errorMessage]="errorMessage"
      [isSubmitting]="isSubmitting$ | async"
      (submitForm)="submitOrderForm($event)"
    ></app-ticket-disconnect>
    <app-ticket-care
      *ngIf="(isTicketOptionSelected$ | async) && ticketFormGroup.value.supportType === 'Care'"
      [services]="services$ | async"
      [selectedServiceId]="selectedServiceId"
      [isLoading]="areServicesLoading$ | async"
      [isLoaded]="areServicesLoaded$ | async"
      [primaryReporters]="contacts$ | async"
      [submissionError]="hasSubmissionError$ | async"
      [errorMessage]="errorMessage"
      [isSubmitting]="isSubmitting$ | async"
      (submitCareForm)="submitCareForm($event)"
    ></app-ticket-care>
    <app-ticket-tob
      *ngIf="(isTicketOptionSelected$ | async) && ticketFormGroup.value.supportType === 'TOB'"
      [services]="services$ | async"
      [selectedServiceId]="selectedServiceId"
      [isLoading]="areServicesLoading$ | async"
      [isLoaded]="areServicesLoaded$ | async"
      [primaryReporters]="contacts$ | async"
      [submissionError]="hasSubmissionError$ | async"
      [errorMessage]="errorMessage"
      [isSubmitting]="isSubmitting$ | async"
      (submitForm)="submitOrderForm($event)"
    ></app-ticket-tob>
  </section>

  <section class="create-ticket-container" *ngIf="ticketSubmitted$ | async">
    <app-ticket-success
      (closed)="closeDialog()"
      [order]="createdOrder"
    ></app-ticket-success>
  </section>
</section>
