<section class="monitoring-section">
  <!-- <div class="loading-indicator" *ngIf="isLoading$ | async">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div> -->
  <ng-container *ngIf="downNodes$ | async as nodes; else nodesError">
    <div class="wide-view">
      <div class="column status">
        <div class="icon-text header">
          <div class="custom-icon monitoring_lrg"></div>
          <h1>Network Status</h1>
        </div>

        <div
          class="status-icon"
          [ngClass]="{ up: nodes.length == 0, down: nodes.length > 0 }"
        ></div>
      </div>

      <div class="column nodes" *ngIf="nodes && nodes.length > 0">
        <h2>
          {{ nodes.length }} critical node{{ nodes.length > 1 ? "s" : "" }}
        </h2>
        <div class="node-container">
          <div class="node-row" *ngFor="let node of nodes.slice(0, 2)">
            <div class="custom-icon health_down"></div>
            <div class="node-details">
              <div class="icon-text nit-container">
                <div class="custom-icon circuit_critical"></div>
                <div class="nit-number">{{ node.NITNumber }}</div>
              </div>
              <div
                class="icon-text address-container"
                *ngIf="node.FormattedAddress"
              >
                <div class="custom-icon location_dark"></div>
                <div class="address">{{ node.FormattedAddress }}</div>
              </div>
            </div>
          </div>
          <div *ngIf="nodes.length > 2" class="more-nodes divider">
            {{ nodes.length - 2 }} More...
          </div>
        </div>
      </div>

      <div class="column nodes healthy" *ngIf="nodes && nodes.length === 0">
        <div class="node-container">
          <div class="number-callout">
            <h1>0</h1>
            <div>
              <div class="status">critical</div>
              <div class="label">Nodes</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="narrow-view">
      <div class="icon-text header">
        <div class="custom-icon monitoring_lrg"></div>
        <h1>Network Status</h1>
      </div>
      <div
        class="column nodes critical"
        *ngIf="nodes && nodes.length > 0; else noCriticalNodes"
      >
        <div class="number-callout">
          <h1>{{ nodes.length }}</h1>
          <div>
            <div class="status">critical</div>
            <div class="label">Node{{ nodes.length > 1 ? "s" : "" }}</div>
          </div>
        </div>
        <div class="node-row">
          <div class="custom-icon health_down"></div>
          <div class="node-details">
            <div class="icon-text nit-container">
              <div class="custom-icon circuit_critical"></div>
              <div>{{ nodes[0].NITNumber }}</div>
            </div>
            <div
              class="icon-text address-container"
              *ngIf="nodes[0].FormattedAddress"
            >
              <div class="custom-icon location_dark"></div>
              <div class="address">{{ nodes[0].FormattedAddress }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="nodes.length > 1" class="more-nodes divider">
          {{ nodes.length - 1 }} More...
        </div>
      </div>
      <ng-template #noCriticalNodes>
        <div class="healthy-container" *ngIf="nodes && nodes.length === 0">
          <h2>No Critical Nodes.</h2>
          <div class="status-icon up"></div>
        </div>
      </ng-template>
    </div>

    <ng-container *ngIf="orionURL$ | async as orionURL">
      <div class="widget_cta" *ngIf="!(errorMessage$ | async)">
        <a [attr.href]="orionURL" target="_blank">
          View Network Monitoring
          <div class="custom-icon btn_external"></div>
        </a>
      </div>
      <a
        [attr.href]="orionURL"
        target="_blank"
        tabindex="-1"
        class="dashboard-link desktop"
        *ngIf="!(errorMessage$ | async)"
        ><div class="cta-link">desktop</div></a
      >
    </ng-container>
  </ng-container>

  <ng-template #nodesError>
    <div
      *ngIf="!(isLoading$ | async) && errorMessage$ | async as errorMessage"
      class="error-message"
    >
      {{ errorMessage }}
    </div>
  </ng-template>
</section>
