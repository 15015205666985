<div *ngxPermissionsOnly="['Nitel IQ Participant']">
  <app-overall-network-status-page />
</div>

<div *ngxPermissionsExcept="['Nitel IQ Participant']">
  <section class="dashboard" [ngClass]="browserSize">
    <div class="widgets-container">
      <div class="row short">
        <app-dashboard-widget
          class="widget-all widget-left"
          [widgetType]="widgetTypes.SUMMARY.label"
          >Summary</app-dashboard-widget
        >
        <ng-container *ngIf="(orionUrl$ | async).length">
          <app-dashboard-widget
            class="widget-all widget-wide monitoring-container"
            [widgetType]="widgetTypes.MONITORING.label"
            *ngxPermissionsOnly="'Monitoring'"
            >Monitoring</app-dashboard-widget
          >
        </ng-container>
      </div>
      <div class="row tall" *ngxPermissionsOnly="'Dashboard'">
        <app-dashboard-widget
          class="widget-all widget-standard"
          [widgetType]="widgetTypes.BILLING.label"
          *ngxPermissionsOnly="'Invoice and Billing: Read'"
          >Billing</app-dashboard-widget
        >
        <app-dashboard-widget
          class="widget-all widget-standard"
          [widgetType]="widgetTypes.TICKETING.label"
          *ngxPermissionsOnly="'Ticketing: Read'"
          >Tickets</app-dashboard-widget
        >
        <app-dashboard-widget
          class="widget-all widget-standard"
          [widgetType]="widgetTypes.ORDERS.label"
          *ngxPermissionsOnly="'Orders'"
          >Orders</app-dashboard-widget
        >
        <app-dashboard-widget
          class="widget-all widget-standard"
          [widgetType]="widgetTypes.SERVICES.label"
          *ngxPermissionsOnly="'Services'"
          >Services</app-dashboard-widget
        >
      </div>
    </div>
    <app-footer></app-footer>
  </section>
</div>
