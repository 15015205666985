import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, catchError, filter, switchMap, take, tap } from 'rxjs';

import * as fromStore from 'src/app/store';

@Injectable()
export class UsersGuard  {
  constructor(private store: Store<fromStore.State>) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(fromStore.getCurrentUserLoaded).pipe(
      tap((loaded) => {
        if (!loaded) {
          this.store.dispatch(new fromStore.LoadCurrentUser());
        }
      }),
      filter((loaded) => loaded),
      take(1)
    );
  }
}
