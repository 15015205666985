import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from "@angular/material/select";
import { MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from '@angular/material/dialog';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxPaginationModule } from 'ngx-pagination';

// components & containers
import * as fromComponents from './components';
import * as fromContainers from './containers';
import { NgChartsModule } from 'ng2-charts';

/*  combined common modules into one file */
import * as fromServicesReducer from '../store/reducers/services.reducer';
import { effects } from '../store';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    NgChartsModule,
    SharedModule,
    RouterModule,

    MatIconModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatDialogModule,
    NgxPaginationModule,

    ReactiveFormsModule,
    ScrollingModule,

    StoreModule.forFeature('services', fromServicesReducer.reducer),
    EffectsModule.forFeature(effects)
  ],
  exports: [RouterModule],
  declarations: [...fromComponents.components, ...fromContainers.containers]
})
export class InventoryModule {}
