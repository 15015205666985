import {
  LoadTickets,
  LoadTicketsFail,
  LoadTicketsSuccess,
} from "../../../store";
import { Ticket } from "src/app/models/ticket.model";
import { ChartService } from "./chart.service";
import { GaugeValueKPI } from "../../types";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ChartType } from "../../types/chart-type";

enum RTAStates {
  GREEN = 0,
  YELLOW = 10,
  RED = 18,
}

const DEFAULT_MAX_VALUE = 24;
const DEFAULT_MIN_VALUE = RTAStates.GREEN;
const THRESHOLDS: GaugeValueKPI["thresholds"] = Object.freeze({
  [RTAStates.GREEN]:  { color: "#0F0A", bgOpacity: 0.2 },
  [RTAStates.YELLOW]: { color: "#FD0A", bgOpacity: 0.2 },
  [RTAStates.RED]:    { color: "#F00A", bgOpacity: 0.2 },
});
const ALLOWED_BUSINESS_PURPOSES = Object.freeze([
  "MSC - Bandwidth/Throughput",
  "MSC - Configuration Change",
  "MSC - CPE/Hardware Issue",
  "MSC - Customer Facilities Issue",
  "MSC - Customer RFO Request",
  "MSC - Firewall Issue",
  "MSC - Latency/Packet Loss",
  "MSC - Network Outage",
  "MSC - SDWAN Issue",
  "MSC - Service Review",
  "MSC - Technical Question",
  "MSC - Utilization Request",
  "MSC - VOIP Issue",
  "NOC - Bandwidth/Throughput",
  "NOC - Chronic",
  "NOC - Circuit Down",
  "NOC - Circuit Down - Broadband",
  "NOC - Circuit Down/Disconnected",
  "NOC - Circuit Errors/Bouncing",
  "NOC - Circuit Up/No Surf",
  "NOC - COS/Applications",
  "NOC - CPE/Hardware issue",
  "NOC - Customer Facilities issue",
  "NOC - Firewall Issue",
  "NOC - Latency/Packet Loss",
  "NOC - Network Outage",
  "NOC - Routing issue",
  "NOC - SDWAN Issue",
  "NOC - Technical Question",
  "NOC - VOIP/CoS MPLS",
  "SD-WAN Add IPs",
  "SD-WAN Config Change",
  "SD-WAN General",
  "Support - Config Change",
  "Support - Config Request",
  "Support - DNS Change",
  "Support - IP Request",
  "Support - SDWAN Config Change",
  "Support - Utilization Request",
]);

@Injectable()
export class MTTRAllNOCCasesLifeChartService extends ChartService<GaugeValueKPI> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadTickets,
    failure: LoadTicketsFail,
    success: LoadTicketsSuccess,
  }).pipe(
    map(({ loading, data }) => {
      let times = 0;
      let rta = 0;

      for (const ticket of (data || [])) {
        // Time_to_Repair_Days__c | Time_to_Repair_Hours__c | Time_to_Repair__c
        if (!this.filterTickets(ticket)) continue;
        times++;

        rta += ticket.Time_to_Repair_Days__c || 0;
      }

      const dataValue = Number((rta / (times || 1)).toFixed(2));

      return {
        loading,
        ngClass: ["gauge-widget-label--limited"],
        data: {
          type: ChartType.GAUGE,
          id: "MTTR All NOC Cases LIFE",
          title: "Average Time to Resolve NOC Tickets",
          numberLabel: "Restoration Time Average",
          records: loading ? undefined : times,
          value: dataValue,
          hyperlink: "/tickets",
          minValue: DEFAULT_MIN_VALUE,
          maxValue: Math.max(dataValue, DEFAULT_MAX_VALUE),
          markers: this.makeMarkers(dataValue),
          thresholds: { ...THRESHOLDS },
        } satisfies GaugeValueKPI,
      };
    })
  );

  private filterTickets(ticket: Ticket): boolean {
    if (!ticket.IsClosed) return false;
    if (!ticket.ClosedDate) return false;

    return ALLOWED_BUSINESS_PURPOSES.includes(ticket.Business_Purpose__c);
  }

  private makeMarkers(value: number): GaugeValueKPI["markers"] {
    const defaults = Object.freeze({
      color: "#888",
      type: "line",
      size: 8,
    });

    return {
      [RTAStates.GREEN]: { ...defaults, label: `${RTAStates.GREEN}` },
      [RTAStates.YELLOW]: { ...defaults, label: `${RTAStates.YELLOW}` },
      [RTAStates.RED]: { ...defaults, label: `${RTAStates.RED}` },
      [DEFAULT_MAX_VALUE]: { ...defaults, label: `${DEFAULT_MAX_VALUE}` },
      [value]: {
        ...defaults,
        label: (DEFAULT_MAX_VALUE < value) ? `${value}` : "",
        type: "triangle",
        color: "#000",
      },
    };
  }
}
