import { Component, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MatGridList } from '@angular/material/grid-list';
import { MatTableDataSource } from '@angular/material/table';
import { SynapseService } from 'src/app/services/synapse.service';
import { Subscription } from "rxjs";
import { Store } from '@ngrx/store';
import { DatePipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as fromStore from 'src/app/store/';

interface QualityOfExperience {
  edgeName: string;
  sourceName: string;
  timestamp: string;
  carrier: string;
  region: string;
  score: number;
  qos: number;
  value: string;
  qos_category: string;
  datausage: number;
  anomalies: string;
}

@Component({
  selector: 'app-qoe-page',
  templateUrl: './qoe-page.component.html',
  styleUrls: ['./qoe-page.component.scss'],
  providers: [DatePipe],
})
export class QoePageComponent implements OnDestroy {
  @ViewChild(MatGridList, { static: true }) matGridList!: MatGridList;

  timeOfDayBreakdownTitleProvider: string = 'QOE Events per Carrier';

  accountName: string = '';
  nitelIQData: any[] = [];
  selectedTime: string = '';
  showMockData = false;
  isNoContent = false;
  loading: boolean = false;
  QOEScoresByRegionData: number[] = [];
  QOEScoresByRegionCategories: string[] = [];
  carrierLabels : string[] = [];
  carrierData: number[] = [];
  timeOfDayBreakdownData: number[] = [];
  timeOfDayBreakdownLabels: string[] = ['12a - 8a', '8a - 4p', '4p - 12a'];
  timeOfDayBreakdownTitle: string = 'Time of Day Breakdown';

  qoeTableData = new MatTableDataSource<QualityOfExperience>();

  private destroy$ = new Subject<void>();

  constructor(
    private synapseService: SynapseService,
    private cdr: ChangeDetectorRef,
    private store: Store<fromStore.State>,
    private datePipe: DatePipe
    ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChildSelectionChange(value: string): void {
    this.selectedTime = value;
    this.getUserInfoAndLoadData();
  }

  toggleMockData(event: boolean): void {
    this.showMockData = event;
    this.getUserInfoAndLoadData();
  }

  getUserInfoAndLoadData(): void {
    if (this.accountName) {
      this.loadNitelIQData();
    }
    else {
      this.store
        .select(fromStore.getCurrentUser)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data) => {
            this.accountName = data.Account?.Name;
            this.loadNitelIQData();
          },
          error: (err) => console.error('Error fetching user data:', err)
      });
    }
  }

  loadNitelIQData(): void {

    this.qoeTableData = new MatTableDataSource<QualityOfExperience>();
    this.loading = true;
    this.isNoContent = false;

   this.synapseService.fetchAllLeads(this.selectedTime, this.showMockData, this.accountName)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (data) => {
        this.nitelIQData = data;
        if (this.nitelIQData?.length) {
          this.updateMetrics();
          this.isNoContent = false;
        } else {
          this.isNoContent = true;
        }
        this.loading = false;
        this.cdr.detectChanges();
      },
      error: (err) => {
        console.error('Error fetching metrics:', err);
        this.isNoContent = true;
        this.loading = false;
        this.cdr.detectChanges();
      }
    });
  }

  private updateMetrics(): void {
    this.calculateTimeOfDayBreakdown();
    this.populateQOETableData();
    this.calculateQosScore();
    this.getEventsCountPerCarrier();
    
  }
  private calculateTimeOfDayBreakdown(): void {
    const timeBuckets = [0, 0, 0];
    this.nitelIQData.forEach((item) => {
      const formattedTime = this.datePipe.transform(item.time_frame, 'HH', 'en-US');
      const hour = parseInt(formattedTime, 10);
      if (hour >= 0 && hour < 8) {
        timeBuckets[0]++;
      } else if (hour >= 8 && hour < 16) {
        timeBuckets[1]++;
      } else {
        timeBuckets[2]++;
      }
    });
    this.timeOfDayBreakdownData = timeBuckets;
  }

getEventsCountPerCarrier(): void {
  const result: { [carrier: string]: number } = {};

  this.nitelIQData.forEach(event => {
    const carrier = event.carrier;

    if (!result[carrier]) {
      result[carrier] = 0;
    }

    result[carrier]++;
  });
  this.carrierLabels = Object.keys(result);
  this.carrierData = Object.values(result);
}

  calculateQosScore(): void {
    const regionCounts: { [region: string]: number } = {};
    this.nitelIQData.forEach(item => {
      const region = item.region;
      if (!regionCounts[region]) {
        regionCounts[region] = 0;
      }
      regionCounts[region]++;
    });

    this.QOEScoresByRegionCategories = Object.keys(regionCounts);
    this.QOEScoresByRegionData = Object.values(regionCounts);
  }
  
  populateQOETableData(): void {
    const tableData = this.nitelIQData.map((item) => {
      const metrics = item.metadata.reduce(
        (acc: any, metric: any) => ({ ...acc, [metric.property]: metric.value }),
        {}
      );
      return {
        edgeName: item.device,
        sourceName: item.source,
        carrier: item.carrier,
        region: item.region,
        timestamp: this.formatDate(item.time_frame),
        ...metrics,
      };
    });
    this.qoeTableData.data = tableData;
  }

  private formatDate(dateString: string): string {
    return this.datePipe.transform(dateString, 'MM/dd/yyyy hh:mm:ss a', 'en-US');
  }
}