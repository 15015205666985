import { WidgetOrdersDisplayComponent } from '../../../../shared/components/widget-displays/widget-orders-display/widget-orders-display.component';
import { State, getActiveOrders, getTotalActiveLocations, LoadOrders, getOrdersLoading } from '../../../../store';
import { SharedModule } from '../../../../shared/shared.module';
import { Order } from '../../../../models/order.model';

import { ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';

@Component({
  standalone: true,
  selector: 'ons-widget-orders',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    WidgetOrdersDisplayComponent,
    SharedModule,
  ],
  template: `
    <app-widget-orders-display
      class="dimensions-stretch"
      [hyperlink]="'/orders'"
      [loading]="isLoading$()"
      [navigateOnClick]="navigateOnClick"
      [orderCount]="totalOpenOrders$()?.length"
      [locationCount]="totalActiveLocations$()"
    ></app-widget-orders-display>
  `,
})
export class WidgetOrdersComponent implements OnInit {
  @Input() navigateOnClick: boolean = false;

  private readonly store$ = inject(Store<State>);

  public readonly totalActiveLocations$ = toSignal<number>(this.store$.select(getTotalActiveLocations));
  public readonly totalOpenOrders$ = toSignal<Order[]>(this.store$.select(getActiveOrders));
  public readonly isLoading$ = toSignal<boolean>(this.store$.select(getOrdersLoading));

  ngOnInit() {
    this.store$.dispatch(new LoadOrders());
  }

}
