import { SingleValueModalComponent } from "./single-value-modal/single-value-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { Component, inject, computed, Input, ElementRef, ViewChild, ChangeDetectionStrategy } from "@angular/core";
import { SingleValueKPI } from "../../types";
import { toPng } from "dom-to-image-more";

@Component({
  selector: "app-single-value",
  templateUrl: "./single-value.component.html",
  styleUrls: ["./single-value.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleValueComponent {
  @Input({ required: true }) data: SingleValueKPI;
  @Input() loading: boolean = false;

  private readonly matDialog = inject(MatDialog);
  @ViewChild("modalContent") modalContent: ElementRef | undefined;

  public downloadImage(): void {
    const contentElement = this.modalContent?.nativeElement;

    if (contentElement) {
      return;
    }

    const options = {
      width: contentElement.offsetWidth,
      height: contentElement.offsetHeight,
      scale: 1.5,
      style: {
        transform: "scale(1)",
        transformOrigin: "top center",
      },
    };

    toPng(contentElement, options)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "component-image.png";
        link.click();
      })
      .catch((error) => {
        console.error("Error", error);
      });
  }

  public openModal(): void {
    this.matDialog.open<SingleValueModalComponent, SingleValueKPI>(
      SingleValueModalComponent,
      {
        width: "calc(100vw - 1rem)",
        hasBackdrop: true,
        data: this.data,
      }
    );
  }

  // turn numbers like 10000000 into 10,000,000
  public formatValue(value: keyof any): string {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}
