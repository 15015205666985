import { NgModule } from '@angular/core';
import { CommonModule, NgFor, AsyncPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatMenuModule } from '@angular/material/menu';
import { NgChartsModule } from 'ng2-charts';
import { NgApexchartsModule } from "ng-apexcharts";

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from "@angular/material/button";
import { MatDatepickerModule } from '@angular/material/datepicker';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';

// custom pipes
import { PositiveNumberPipe } from './custom-pipes/positive-number.pipe';
import { PhoneNumberPipe } from './custom-pipes/phone-number.pipe';
import { SearchFilterPipe } from './custom-pipes/search-filter.pipe';
import { PluralizePipe } from './custom-pipes/pluralize.pipe';

// components
import * as fromComponents from './components';
import { AccountMenuComponent } from './portal-layout/menu/account-menu/account-menu.component';
import { FooterComponent } from './portal-layout/footer/footer.component';
import { HeaderComponent } from './portal-layout/header/header.component';
import { AccessDeniedComponent } from '../core/access-denied/access-denied.component';
import { TooltipDirective } from './directives/tooltip.directive'

// analytics
import * as fromAnalytics from './analytics';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgChartsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forChild(),
    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    NgFor,
    AsyncPipe,
    MatMenuModule,
    NgApexchartsModule
  ],

  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    MatProgressBarModule,
    MatNativeDateModule,
    MatIconModule,

    AccountMenuComponent,
    FooterComponent,
    HeaderComponent,
    AccessDeniedComponent,
    ...fromComponents.components,
    TooltipDirective,
    ...fromAnalytics.components,

    PositiveNumberPipe,
    PhoneNumberPipe,
    SearchFilterPipe,
    PluralizePipe,
  ],
  declarations: [
    ...fromComponents.components,
    TooltipDirective,
    ...fromAnalytics.components,
    AccountMenuComponent,
    FooterComponent,
    HeaderComponent,
    PositiveNumberPipe,
    PhoneNumberPipe,
    SearchFilterPipe,
    PluralizePipe,
  ],
  providers: [
    PositiveNumberPipe,
    PhoneNumberPipe,
    SearchFilterPipe,
    PluralizePipe,
  ]
})
export class SharedModule {}
