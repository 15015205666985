export const api = {
  forgotPasswordURL: "/reset-request",
  loginURL: "/login",
  resetPasswordURL: "/reset-password",
  validateTokenURL: "/token-status",
  validateUsernameURL: "/validate-username",
  impersonateURL: `/impersonate`,
  verifyUserURL: "/verify-user",

  contactsURL: "/contacts",
  loggedInContactURL: "/contacts/logged-in-contact",
  usersURL: "/users",

  attachmentURL: "/attachments",
  invoiceAttachmentURL: "/attachments/invoices",
  sofAttachmentURL: "/attachments/sof",
  boxAttachmentsUrl: "/attachments/box",

  lineItemsURL: "/line-items",
  ordersURL: "/orders",
  ordersV2URL: "/ordersv2",
  servicesURL: "/services",

  taskAssignmentsURL: "/task-assignments",
  tasksURL: "/tasks",

  invoicesURL: "/invoices",
  paymentsURL: "/payments",

  nodesDownURL: "/nodes/down",

  commentsURL: "/comments",
  ticketsURL: "/tickets",

  banLookupsURL: "/banlookups",
  locationsURL: "/locations",
  portalRolesURL: "/portal-roles",

  addAttachmentURL: '/addAttachment',
  getAttachmentsUrl: '/getAttachments',
  getQOELeads: '/qoe/leads/',

  getNitelIQ: '/iq/ticket-insight',
  getUnusualMetrics: '/anomalies/unusual-metrics',
  getMetricDetails: '/insights/metrics/details',
  getAggregatedMetrics: '/insights/metrics/aggregate',
  getAnomaliedLeadsAggregate: '/anomalies/leads/aggregate',
  getAnomaliedLeads: '/anomalies/leads',
  getDeviceLocations: '/site',
  surveyURL: `/surveys`,
};