import { Component, OnInit, ViewChild, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';

interface QoEMetrics {
  edgeName: string;
  sourceName: string;
  timestamp: string;
  carrier: string;
  region: string;
  score: number;
  value: string;
  qos: number;
  qos_category: string;
}

@Component({
  selector: 'app-qoe-data-table',
  templateUrl: './qoe-data-table.component.html',
  styleUrls: ['./qoe-data-table.component.scss'],
  providers: [DatePipe],
})
export class QoeDataTableComponent implements OnInit, OnChanges {
  @Input() dataSource: MatTableDataSource<QoEMetrics>;
  @Input() loading: boolean = false;
  @Input() isNoContent = false;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  displayedColumns: string[] = [
    'edgeName',
    'sourceName',
    'timestamp',
    'carrier',
    'region',
    'score',
    'value',
  ];

  filters = ['edgeNameFilter', 'sourceNameFilter', 'timestampFilter', 'carrierFilter', 'regionFilter', 'scoreFilter', 'valueFilter'];
  filters2 = ['edgeNameFilter2', 'sourceNameFilter2', 'timestampFilter2', 'carrierFilter2', 'regionFilter2', 'scoreFilter2', 'valueFilter2'];
  showFilters = false;
  pageSizeOptions: number[] = [10, 25, 50, 75, 100]; 
  defaultPageSizeOption: number = 10;

  filterValues: { [key: string]: string | null } = {
    edgeName: '',
    sourceName: '',
    carrier: '',
    region: '',
    score: '',
    value: '',
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
  };

  constructor(private datePipe: DatePipe) { }

  ngOnInit() {
    this.initTable();
  }

  ngOnChanges() {
    this.initTable();
  }

  private initTable(): void {
    if (!this.dataSource) {
      return;
    }

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'timestamp':
          return new Date(item.timestamp).getTime();
        case 'score':
          return item.qos ? item.qos : 0;
        case 'value':
          return item.qos_category ? item.qos_category : 0; 
          return +item[property]; 
        case 'qos':
          return +item[property];
        default:
          return item[property];
      }
    };

    this.dataSource.filterPredicate = this.createFilter();
    this.clearFilter();
  }
 
  applyFilter(event: Event, column: string): void {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase(); // Get filter value
    this.filterValues[column] = filterValue;
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  applyDateFilter(): void {
    if (this.filterValues.startDate && !this.filterValues.startTime) {
      this.filterValues.startTime = this.getCurrentTimeIn12HourFormat();
    }
    if (this.filterValues.endDate && !this.filterValues.endTime) {
      this.filterValues.endTime = this.getCurrentTimeIn12HourFormat();
    }

    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  applyPicklistFilter(filterValue: string | null, column: string): void {
    this.filterValues[column] = filterValue ? filterValue.trim().toLowerCase() : null; // Update filter value
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  clearFilter(): void {
    this.filterValues = {
      edgeName: '',
      sourceName: '',
      qos: '',
      qos_category: '',
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
    };
    this.dataSource.filter = JSON.stringify(this.filterValues);
  }

  private createFilter(): (data: QoEMetrics, filter: string) => boolean {
    return (data: QoEMetrics, filter: string): boolean => {
      const searchTerms = JSON.parse(filter);
      const timestamp = new Date(data.timestamp);

      return Object.keys(searchTerms).every((key) => {
        const filterValue = searchTerms[key];

        if (!filterValue) return true;

        if (key === 'startDate' || key === 'startTime') {
          const startDateTime = searchTerms.startDate
            ? new Date(`${new Date(searchTerms.startDate).toLocaleDateString("en-US")} ${searchTerms.startTime || '12:00 AM'}`)
            : null;
          if (startDateTime) {
            return timestamp >= startDateTime;
          }
        }

        if (key === 'endDate' || key === 'endTime') {
          const endDateTime = searchTerms.endDate
            ? new Date(`${new Date(searchTerms.endDate).toLocaleDateString("en-US")} ${searchTerms.endTime || '11:59 PM'}`)
            : null;
          if (endDateTime) {
            return timestamp <= endDateTime;
          }
        }

     
        return data[key as keyof QoEMetrics]
          ?.toString()
          .toLowerCase()
          .includes(filterValue);
      });
    };
  }

  toggleFilters(): void {
    this.showFilters = !this.showFilters;
  }

  private getCurrentTimeIn12HourFormat(): string {
    const now = new Date();
    return this.datePipe.transform(now, 'hh:mm a', 'en-US');
  }
}
