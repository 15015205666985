import { WidgetNumberDisplayComponent } from '../widget-number-display/widget-number-display.component';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { SharedModule } from '../../../shared.module';

@Component({
  standalone: true,
  selector: 'app-widget-orders-display',
  styleUrls: ['./widget-orders-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    WidgetNumberDisplayComponent,
    SharedModule,
  ],
  template: `
    <app-widget-number-display
      class="widget-orders-display dimensions-stretch"

      [status]="'ACTIVE'"
      [number]="orderCount"
      [label]="(orderCount || 0) | pluralize: 'Order'"

      [hyperlink]="hyperlink"
      [ctaText]="'View Orders'"
      [navigateOnClick]="navigateOnClick"
      [loading]="loading"
    >
      <i titlePrefixIcon class="widget-element-icon custom-icon order_lrg_cir_white"></i>

      <div class="order_icon">
        <div class="total-locations">Spanning {{ locationCount }} locations</div>
      </div>
    </app-widget-number-display>
  `,
})
export class WidgetOrdersDisplayComponent {

  @Input() loading: boolean = true;
  @Input() hyperlink: string | null = null;
  @Input() navigateOnClick: boolean = false;
  @Input() locationCount: number = 0;
  @Input() orderCount: number = 0;

}
