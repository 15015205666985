/**
 * Abstract ChartService class to handle reusable logic for loading and managing chart data.
 * 
 * Key Features:
 * - Utilizes NgRx effects and store for state management.
 * - Provides a utility method for managing loading, success, and failure states of chart data.
 * - Automates the dispatching of request actions to initiate data loading.
 * - Encapsulates common patterns for combining success and failure actions into a unified observable.
 * 
 * Intended Usage:
 * - Extend this service for specific chart implementations.
 * - Define the `chart$` observable in derived classes to provide specific chart data logic.
 */


import { Actions, ofType } from "@ngrx/effects";
import { inject, Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { catchError, combineLatest, map, Observable, of, startWith } from "rxjs";
import { BaseKPI } from "../../types";

type PayloadType<T extends PayloadAction> = ConstructorParameters<T>[0];
type PayloadAction = { new (...args: any): { type: string, payload: any } };
type TriggerAction = { new (...args: any): { type: string } };
type OperationChart<T> = {
  loading: boolean;
  error: any | undefined;
  data: T | undefined;
};

@Injectable()
export abstract class ChartService<T extends BaseKPI<any, any>> {

  private readonly actions$ = inject(Actions);
  private readonly store$ = inject(Store);

  public abstract readonly chart$: Observable<{
    loading: boolean,
    ngClass: string[],
    data: T,
  }>;

  //#region Abstraction
  protected chartsDataLoader<
    RequestAction extends TriggerAction,
    SuccessAction extends PayloadAction,
    FailedAction extends PayloadAction,
  >(
    actions: {
      request: RequestAction;
      success: SuccessAction;
      failure: FailedAction;
    },
    // mapper: (payload: InstanceType<SuccessAction>) => T
  ): Observable<OperationChart<PayloadType<SuccessAction>>> {
    const observable$ = combineLatest([
      this.actions$.pipe(
        ofType(new actions.success().type),
        map(({ payload }) => payload),
        startWith(undefined)
      ),
      this.actions$.pipe(
        ofType(new actions.failure().type),
        startWith(undefined)
      ),
    ]).pipe(
      map(([success, failure]) => {
        const response: OperationChart<PayloadType<SuccessAction>> = {
          loading: true,
          error: failure,
          data: success,
        };

        if (success !== undefined || failure !== undefined) {
          response.loading = false;
        }

        return response;
      }),
      catchError((failure) => {
        const response: OperationChart<T> = {
          loading: true,
          error: failure,
          data: undefined,
        };

        return of(response);
      })
    );

    this.triggerDispatch(actions.request);
    return observable$;
  }
  //#endregion

  private static readonly autoDispatched = new Map<TriggerAction, NodeJS.Timeout>();
  private triggerDispatch(action: TriggerAction) {
    clearTimeout(ChartService.autoDispatched.get(action));
    ChartService.autoDispatched.set(action, setTimeout(() => {
      this.store$.dispatch(new action());
    }, 100))
  }
}
