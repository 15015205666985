import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumberWithError, CountryCode } from 'libphonenumber-js';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {
  transform = (phoneValue: number | string, countryCode: string = 'US'): string => {
    try {
      const phoneNumber = parsePhoneNumberWithError(`${phoneValue}`, countryCode as CountryCode);
      return phoneNumber.formatNational();
    } catch (error) {
      return `${phoneValue}`; // Ensure it's returned as a string
    }
  };
}
