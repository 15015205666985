import { Ticket, ticketGetTypeString } from 'src/app/models/ticket.model';
import { Component, Input, ViewChild, OnChanges } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'ticket-type-chart',
  styleUrls: ["ticket-type-chart.component.scss"],
  templateUrl: './ticket-type-chart.component.html'
})
export class TicketTypeChartComponent implements OnChanges {
  @Input() tickets: Ticket[];
  loading: boolean;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  public pieChartData: ChartData<'pie'> = {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Tickets',
        borderWidth: 1,
        hoverBorderWidth: 1,
        backgroundColor: ['#2a4b98', '#2199e8', '#ff742e', '#ffbb2c', '#8793a2', '#3dcc64', '#ff742e']
      }
    ]
  };
  backgroundColor = [];
  public pieChartType: ChartType = 'pie' as ChartType;
  public pieChartOptions: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        maxWidth: 195,
        display: true,
        position: 'right',
        labels: {
          color: 'white',
          boxHeight: 14,
          boxWidth: 14,
          font: {
            size: 15
          },
        },

      },

    },
    layout: {
      padding: {
        bottom: 5,
        left: 5,
        right: 5
      },
    }

  };

  ngOnInit() {
    this.pieChartData.labels = Object.keys(this.countTicketsByType(this.tickets));
    this.pieChartData.datasets[0].data = Object.values(this.countTicketsByType(this.tickets));
    // this.backgroundColor = this.generateColors(this.pieChartData.labels.length);
    this.backgroundColor = ['#2a4b98', '#2199e8', '#ff742e', '#ffbb2c', '#8793a2', '#3dcc64', '#ff742e'];
    this.chart?.update();
  }

  ngOnChanges() {
    this.pieChartData.labels = Object.keys(this.countTicketsByType(this.tickets));
    this.pieChartData.datasets[0].data = Object.values(this.countTicketsByType(this.tickets));
    this.backgroundColor = ['#2a4b98', '#2199e8', '#ff742e', '#ffbb2c', '#8793a2', '#3dcc64', '#ff742e'];

    this.chart?.update();
  }

  ngAfterViewChecked() {
    this.loading = false;
  }

  countTicketsByType(tickets: Ticket[]): Record<string, number> {
    const ticketCounts = tickets.reduce<Record<string, number>>((acc, ticket) => {
      const type = ticketGetTypeString(ticket);
      if (type) acc[type] = (acc[type] ?? 0) + 1;
      return acc;
    }, {});

    this.chart?.update();
    return this.sortRecordByKey(ticketCounts);
  }

  sortRecordByKey(record: Record<string, number>): Record<string, number> {
    const sortedKeys = Object.keys(record).sort();
    const sortedRecord: Record<string, number> = {};

    for (const key of sortedKeys) {
      sortedRecord[key] = record[key];
    }

    return sortedRecord;
  }

}
