<div class="container-table">
  <div class="container-top">
    <div class="container-title">
      <mat-toolbar>
        <span class="spacer"></span>
        <!-- Toggle Filters Button -->
        <button mat-raised-button (click)="clearFilter()">Clear Filters</button>
        <button mat-raised-button color="primary" (click)="toggleFilters()">
          {{ showFilters ? "Hide Filters" : "Show Filters" }}
        </button>
      </mat-toolbar>
    </div>
  </div>

  <section class="table-unm">
    <div class="table-container">
      <!-- Angular Material Table -->
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Suppressed Column -->
        <ng-container matColumnDef="Suppressed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Suppressed</th>
          <td mat-cell *matCellDef="let product">{{ true }}</td>
        </ng-container>

        <!-- Edge Name Column -->
        <ng-container matColumnDef="edgeName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Edge Name</th>
          <td mat-cell *matCellDef="let product">{{ product.edgeName }}</td>
        </ng-container>

        <ng-container matColumnDef="edgeNameFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'edgeName')"
              placeholder="Filter Edge Name"
              class="table-input"
              [(ngModel)]="filterValues.edgeName"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="edgeNameFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Links Column -->
        <ng-container matColumnDef="links">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Links</th>
          <td mat-cell *matCellDef="let product">
            {{ product.links }}
          </td>
        </ng-container>

        <ng-container matColumnDef="linksFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'links')"
              placeholder="Filter Links"
              class="table-input"
              [(ngModel)]="filterValues.links"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="linksFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Timestamp Column -->
        <ng-container matColumnDef="timestamp">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Timestamp</th>
          <td mat-cell *matCellDef="let product">{{ product.timestamp }}</td>
        </ng-container>

        <ng-container matColumnDef="timestampFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <div class="date-filter-container">
              <!-- Start Date Picker -->
              <input
                matInput
                [matDatepicker]="startDatePicker"
                [(ngModel)]="filterValues.startDate"
                (dateChange)="applyDateFilter()"
                placeholder="Start Date"
                class="table-input"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startDatePicker"
                class="date-picker-button"
              />
              <mat-datepicker #startDatePicker />

              <!-- Start Time Picker -->
              <input
                matInput
                [ngxMatTimepicker]="startTimePicker"
                [(ngModel)]="filterValues.startTime"
                placeholder="Start Time"
                class="table-input"
                (ngModelChange)="applyDateFilter()"
              />
              <ngx-mat-timepicker-toggle [for]="startTimePicker" matSuffix />
              <ngx-mat-timepicker #startTimePicker />
            </div>
          </th>
        </ng-container>
        <ng-container matColumnDef="timestampFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th">
            <div class="date-filter-container">
              <!-- End Date Picker -->
              <input
                matInput
                [matDatepicker]="endDatePicker"
                [(ngModel)]="filterValues.endDate"
                (dateChange)="applyDateFilter()"
                placeholder="End Date"
                class="table-input"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDatePicker"
                class="date-picker-button"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>

              <!-- End Time Picker -->
              <input
                matInput
                [ngxMatTimepicker]="endTimePicker"
                [(ngModel)]="filterValues.endTime"
                placeholder="End Time"
                class="table-input"
                (ngModelChange)="applyDateFilter()"
              />
              <ngx-mat-timepicker-toggle [for]="endTimePicker" matSuffix />
              <ngx-mat-timepicker #endTimePicker />
            </div>
          </th>
        </ng-container>

        <!-- Bandwidth (MB) Column -->
        <ng-container matColumnDef="bandwidth">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Bandwidth (MB)
          </th>
          <td mat-cell *matCellDef="let product">{{ product.bandwidth }}</td>
        </ng-container>

        <ng-container matColumnDef="bandwidthFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th"
          >
            <input
              matInput
              (keyup)="applyFilter($event, 'bandwidth')"
              placeholder="Filter Bandwidth"
              class="table-input"
              [(ngModel)]="filterValues.bandwidth"
            />
          </th>
        </ng-container>

        <ng-container matColumnDef="bandwidthFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Carrier Column -->
        <ng-container matColumnDef="carrier">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Carrier</th>
          <td mat-cell *matCellDef="let product">{{ product.carrier }}</td>
        </ng-container>

        <ng-container matColumnDef="carrierFilter">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="table-filter-th table-header-th filter-severity"
          >
            <mat-select
              [(value)]="filterValues.carrier"
              (selectionChange)="applyPicklistFilter($event.value, 'carrier')"
              placeholder="Filter Carrier"
              class="table-filter-select"
              [(ngModel)]="filterValues.carrier"
            >
              <mat-option
                *ngFor="let option of carrierOptions"
                [value]="option"
              >
                {{ option | titlecase }}
              </mat-option>
            </mat-select>
          </th>
        </ng-container>

        <ng-container matColumnDef="carrierFilter2">
          <th mat-header-cell *matHeaderCellDef class="table-filter-th"></th>
        </ng-container>

        <!-- Header and Row Declarations -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="filters"
          [ngClass]="{ 'hidden-row': !showFilters }"
        ></tr>
        <tr
          mat-header-row
          *matHeaderRowDef="filters2"
          [ngClass]="{ 'hidden-row': !showFilters }"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!-- No Data Message -->
        <tr *matNoDataRow [hidden]="loading">
          <td [attr.colspan]="displayedColumns.length" class="no-data-cell">
            <div class="no-content">No data available to display.</div>
          </td>
        </tr>
      </table>

      <div class="spinner-container" *ngIf="loading">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>

      <!-- Pagination Controls -->
      <mat-paginator
        showFirstLastButtons
        [pageSize]="defaultPageSizeOption"
        [pageSizeOptions]="pageSizeOptions"
      ></mat-paginator>
    </div>
  </section>
</div>
