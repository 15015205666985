<section class="users-page flex-col">
  <div class="container-title">
    <h1>
      Manage Users
      {{
        (users$ | async).length > 0 ? "(" + (users$ | async).length + ")" : ""
      }}
    </h1>
    <button class="button" (click)="onCreateEditUser()">
      <span>Invite User</span>
    </button>
  </div>
  <div class="flex-col">
    <div class="mat-elevation-z8 manage-users-table">
      <table
        mat-table
        matSort
        [dataSource]="dataSource"
        matSortActive="FirstName"
        matSortDirection="asc"
      >
        <ng-container matColumnDef="FirstName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
          <td mat-cell *matCellDef="let user">{{ user.FirstName }}</td>
        </ng-container>

        <ng-container matColumnDef="LastName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
          <td mat-cell *matCellDef="let user">{{ user.LastName }}</td>
        </ng-container>

        <ng-container matColumnDef="Email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let user">{{ user.Email }}</td>
        </ng-container>

        <ng-container matColumnDef="Role">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
          <td mat-cell *matCellDef="let user">
            {{ user.Portal_Role__r?.Name || "" }}
          </td>
        </ng-container>

        <!-- Actions Column -->
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let user">
            <button
              mat-icon-button
              (click)="onCreateEditUser(user)"
              aria-label="Edit user"
              matTooltip="Edit User"
            >
              <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="onRemoveUser(user)"
              aria-label="Remove user"
              matTooltip="Remove User"
            >
              <mat-icon>delete</mat-icon>
            </button>
            <button
              *ngIf="!user.HasLoggedInWithEntra__c"
              mat-icon-button
              (click)="onResendInvite(user)"
              aria-label="Resend invite"
              matTooltip="Resend Invite"
            >
              <mat-icon>mail</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Header and Row Definitions -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <!-- No Users Message -->
      <p *ngIf="(users$ | async).length === 0" class="no-users-message">
        No users found. Please invite a user.
      </p>
    </div>
    <div class="w-1/2 my-10">
      <h2 class="text-white text-2xl mb-4">Role Permissions Overview</h2>
      <table
        class="table-auto text-left border-collapse text-sm bg-white rounded-lg shadow-md"
      >
        <thead >
          <tr>
            <th class="px-2 py-2 border-b">Role</th>
            <th class="px-2 py-2 border-b text-center">
              View Invoices & Billing
            </th>
            <th class="px-2 py-2 border-b text-center">View Orders</th>
            <th class="px-2 py-2 border-b text-center">View Services</th>
            <th class="px-2 py-2 border-b text-center">View Tickets</th>
            <th class="px-2 py-2 border-b text-center">Create Tickets</th>
            <th class="px-2 py-2 border-b text-center">Manage Users</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let role of portalRoles$ | async"
            class="odd:bg-gray-70 even:bg-white text-sm"
          >
            <td class="px-2 py-2 border-b">{{ role.Name }}</td>
            <td class="px-2 py-2 border-b text-center">
              <mat-icon
                *ngIf="
                  role?.Permissions__c?.includes('Invoice and Billing: Read')
                "
                class="text-green-700"
                >check_circle</mat-icon
              >
              <mat-icon
                *ngIf="
                  !role?.Permissions__c?.includes('Invoice and Billing: Read')
                "
              ></mat-icon>
            </td>
            <td class="px-2 py-2 border-b text-center">
              <mat-icon
                *ngIf="role?.Permissions__c?.includes('Orders')"
                class="text-green-700"
                >check_circle</mat-icon
              >
              <mat-icon
                *ngIf="!role?.Permissions__c?.includes('Orders')"
              ></mat-icon>
            </td>
            <td class="px-2 py-2 border-b text-center">
              <mat-icon
                *ngIf="role?.Permissions__c?.includes('Services')"
                class="text-green-700"
                >check_circle</mat-icon
              >
              <mat-icon
                *ngIf="!role?.Permissions__c?.includes('Services')"
              ></mat-icon>
            </td>
            <td class="px-2 py-2 border-b text-center">
              <mat-icon
                *ngIf="role?.Permissions__c?.includes('Ticketing: Read')"
                class="text-green-700"
                >check_circle</mat-icon
              >
              <mat-icon
                *ngIf="!role?.Permissions__c?.includes('Ticketing: Read')"
              ></mat-icon>
            </td>
            <td class="px-2 py-2 border-b text-center">
              <mat-icon
                *ngIf="role?.Permissions__c?.includes('Ticketing: Write')"
                class="text-green-700"
                >check_circle</mat-icon
              >
              <mat-icon
                *ngIf="!role?.Permissions__c?.includes('Ticketing: Write')"
              ></mat-icon>
            </td>
            <td class="px-2 py-2 border-b text-center">
              <mat-icon
                *ngIf="role?.Permissions__c?.includes('Manage Users')"
                class="text-green-700"
                >check_circle</mat-icon
              >
              <mat-icon
                *ngIf="!role?.Permissions__c?.includes('Manage Users')"
              ></mat-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
