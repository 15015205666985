import {
  LogLevel,
  Configuration,
  BrowserCacheLocation,
} from "@azure/msal-browser";
import { environment } from "src/environments/environment";

export const entraConfig = {
  clientId: environment.entra_spa_client_id,
  tenantId: environment.entra_tenant_id,
  tenantName: "mynitel",
  userFlows: {
    passwordReset: "B2C_1_PasswordReset",
    profileEdit: "B2C_1_ProfileEdit",
    signIn: "B2C_1_SignIn",
    signUpSignIn: "B2C_1_SignUpSignIn",
  },
  appScopes: {
    graphApi: {
      scopes: ["User.Read", "offline_access", "openid", "profile"],
    },
    nitelApi: {
      scopes: [ environment.entra_nitelapi_scopes ],
    },
  },
};

const baseAuthorityUrl = `https://${entraConfig.tenantName}.b2clogin.com/${entraConfig.tenantName}.onmicrosoft.com`;

export const entraPolicies = {
  authorities: {
    passwordReset: `${baseAuthorityUrl}/${entraConfig.userFlows.passwordReset}`,
    profileEdit: `${baseAuthorityUrl}/${entraConfig.userFlows.profileEdit}`,
    signIn: `${baseAuthorityUrl}/${entraConfig.userFlows.signIn}`,
  },
};

export const msalConfig: Configuration = {
  auth: {
    clientId: entraConfig.clientId,
    authority: entraPolicies.authorities.signIn,
    redirectUri: "/dashboard",
    postLogoutRedirectUri: "/login",
    knownAuthorities: [`${entraConfig.tenantName}.b2clogin.com`],
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        // console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
};
