import { computed, Injectable, signal } from "@angular/core";
import type { ChartType, ChartEvent } from "chart.js";
import { HorizontalBarsKpi } from "../../../types";
import { chartDefaults } from "../defaults";

const HEIGHT_PER_ITEM = 45;
const CHART_HEADER_HEIGHT = 30;

@Injectable()
export class ChartService {
  public readonly barChartType: ChartType = "bar";
  public readonly barChartPlugins = chartDefaults.plugins();
  public readonly barChartOptions = chartDefaults.options();
  public readonly data$ = signal<HorizontalBarsKpi>(null!);
  public readonly recommendedHeight$ = computed(() => {
    const minHeight = 280;
    const length = this.data$()?.value?.length || 0;
    const calculated = (length * HEIGHT_PER_ITEM) + CHART_HEADER_HEIGHT;
    return Math.max(minHeight, calculated);
  });

  public readonly barChartData$ = computed(() => {
    const xAxisLabel = this.data$()?.xAxisLabel?.trim();
    const records = this.data$()?.value || [];

    const datasetsCount = Math.max(...records.map(
      (item) => item.values.length,
    ));

    this.barChartOptions.scales.x['title'] = {
      display: Boolean(xAxisLabel),
      text: xAxisLabel,
    };

    const barChartData = chartDefaults.data({
      labels: this.data$()?.groupsLabels,
      datasetsCount,
      bgColors: [
        ['rgba(54, 162, 235, 0.8)'],
        ['rgba(118, 185, 244, 0.8)'],
      ],
    });

    barChartData.labels = records.map(item => item.generalLabel);
    for (let datasetIndex = 0; datasetIndex < datasetsCount; datasetIndex++) {
      const datasetValues = records.map(item => item.values[datasetIndex] || 0);
      barChartData.datasets[datasetIndex].data = datasetValues;
    }

    return barChartData;
  });

  public chartClicked(event: { event?: ChartEvent; active?: object[] }): void {
    if (!event.active?.length || event.active?.[0]?.["index"] === undefined) {
      return;
    }
  }

}
