<section>
  <div class="icon-text header">
    <div class="custom-icon ticket_lrg_cir_white"></div>
    <h1>Tickets</h1>
  </div>
  <div class="ticket_content">
    <!-- Ticket Count -->
    <div class="loading-indicator" *ngIf="isLoading$ | async">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div class="ticket_count" *ngIf="!(isLoading$ | async)">
        <div class="number">{{ totalOpenTickets$ | async }}</div>
        <div class="description">
          <div class="ticket_status upper">Open</div>
          <div class="ticket_label">{{ totalOpenTickets$ | async | pluralize: 'Ticket' }}</div>
        </div>
    </div>
    <!-- Ticket Icon -->
    <div class="ticket_icon" *ngIf="!(isLoading$ | async)">
      <img *ngIf="(totalOpenTickets$ | async) > 0" alt="ticket widget" src="/assets/images/img_widget_ticket@2x.png" />
      <img *ngIf="(totalOpenTickets$ | async) == 0" alt="ticket widget" src="/assets/images/img_widget_ok@2x.png" />
    </div>
  </div>
  <div class="widget_cta"><a routerLink="/tickets" [queryParams]="{ groupBy: 'Type' }">View Tickets</a></div>
  <a routerLink="/tickets" [queryParams]="{ groupBy: 'Type' }" tabindex="-1" class="dashboard-link desktop">
    <div class="cta-link">desktop</div>
  </a>
</section>
