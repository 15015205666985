import {
  LoadServices,
  LoadServicesFail,
  LoadServicesSuccess,
} from "../../../store";
import { ChartService } from "./chart.service";
import { SingleValueKPI } from "../../types";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ChartType } from "../../types/chart-type";

@Injectable()
export class DisconnectedOrdersChartService extends ChartService<SingleValueKPI> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadServices,
    success: LoadServicesSuccess,
    failure: LoadServicesFail,
  }).pipe(
    map(({ loading, data }) => {
      let dataValue: number = data?.filter((s) =>
        ["New", "Pending"].includes(s.Status__c)
      ).length || 0;

      return {
        ngClass: [],
        loading,
        data: {
          type: ChartType.SINGLE,
          id: "Disconnected Orders Last 90 Days",
          title: "Disconnected Orders Last 90 Days",
          value: dataValue,
          hyperlink: '/orders' // need flags
        } satisfies SingleValueKPI,
      };
    })
  );
}
