import { Component, Inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from "@angular/material/dialog";
import { User } from "src/app/models/user.model";

@Component({
  selector: "confirm-remove-user-dialog",
  template: `
    <mat-dialog-content class="dialog-content">
      <p>
        Please confirm if you'd like to remove {{ data.Name }}.
      </p>
    </mat-dialog-content>

    <mat-dialog-actions class="dialog-actions">
      <button mat-button (click)="onCancel()">Cancel</button>
      <button
        mat-raised-button
        color="warn"
        (click)="onConfirm()"
        cdkFocusInitial
      >
        Confirm
      </button>
    </mat-dialog-actions>
  `,
  standalone: true,
  imports: [MatDialogModule, MatButtonModule],
  styleUrls: ["confirm-remove-user-dialog.component.scss"]
})
export class ConfirmRemoveUserDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmRemoveUserDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: Partial<User>
  ) { }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
