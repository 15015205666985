<section class="orders-page">
  <div class="container">
    <div class="loading-spinner" *ngIf="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>

    <app-table-page
      [hidden]="selectedOrder || loading"
      [orders]="orders"
      [noOrders]="noOrders"
      (selectOrder)="open($event)"
    >
    </app-table-page>

    <app-view-order
      *ngIf="selectedOrder && !loading"
      [order]="selectedOrder"
      [otherSites]="otherSiteServices"
      (selectOrder)="open($event)"
      (back)="goBack()"
    >
    </app-view-order>
  </div>
</section>
