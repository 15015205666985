import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';

import { ContactService } from 'src/app/services/contact.service';
import * as userActions from '../actions/users.action';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private contactService: ContactService,
    private permissionsService: NgxPermissionsService
  ) {}

  loadCurrentUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userActions.LOAD_CURRENT_USER),
      switchMap(() => {
        return this.contactService.getLoggedInContact().pipe(
          map((user) => new userActions.LoadCurrentUserSuccess(user)),
          catchError((error) => of(new userActions.LoadCurrentUserFail(error)))
        );
      })
    );
  });

  setPermissions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.LOAD_CURRENT_USER_SUCCESS),
        tap((action: userActions.LoadCurrentUserSuccess) => {
          if (action.payload?.Permissions) {
            this.permissionsService.loadPermissions(action.payload.Permissions);
          }
        })
      ),
    { dispatch: false } // No new action is dispatched, just a side effect
  );
}
