import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as fromStore from 'src/app/store/';
import { Contact } from 'src/app/models/contact.model';

@Component({
  selector: 'app-primary-reporter',
  templateUrl: './primary-reporter.component.html',
  styleUrls: ['../../../tickets/containers/create-ticket/create-ticket.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimaryReporterComponent implements OnInit, OnDestroy {
  @Input() formRef: UntypedFormGroup;
  @Input() inlineErrors: any = {};
  @Input() reporters: Contact[];
  @Input() altContact: boolean = true;
  @Output() usersLoaded = new EventEmitter(false);

  isLoading$: Observable<boolean>;
  isLoaded$: Observable<boolean>;
  error$: Observable<string>;

  private destroy$ = new Subject<void>();

  primaryReporter: Contact;
  userId: string;
  isLoadingReporters = true;

  constructor(private store: Store<fromStore.State>) {}

  ngOnInit() {
    this.store
      .select(fromStore.getCurrentUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.userId = data?.Id;
        },
        error: (err) => console.error("Error fetching user data:", err),
      });

    if (this.reporters) {
      this.primaryReporter = this.reporters.find((x) => x.Id === this.userId);
      this.formRef.controls.reporter.setValue(this.primaryReporter?.Id);
    }

    this.usersLoaded.emit(true);
    this.isLoadingReporters = false;

    this.isLoading$ = this.store.select(fromStore.getContactsLoading);
    this.isLoaded$ = this.store.select(fromStore.getContactsLoaded);
  }

  canSubmit() {
    for (const prop in this.formRef.controls) {
      if (this.formRef.controls.hasOwnProperty(prop)) {
        const control: UntypedFormControl = this.formRef.controls[prop] as UntypedFormControl;
        control.updateValueAndValidity();
        this.inlineErrors[prop] = control.valid ? null : 'This is a required field';
      }
    }
    return this.formRef.valid;
  }

  primaryReporterChanged(reporterId) {
    this.primaryReporter = this.reporters.find((rep) => rep.Id === reporterId.value);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}