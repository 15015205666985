import { Params } from "@angular/router";

export interface Navigation {
  name: string;
  exact: boolean;
  ariaLabel: string;
  permissions: string | string[];
  showOverlay?: boolean;
  children?: Navigation[];
  link?: string;
  linkParams?: Params;
  icon?: string;
}

export const nav: Navigation[] = [
  {
    link: "/billing",
    name: "Billing",
    exact: true,
    permissions: "Invoice and Billing: Read",
    ariaLabel: "Navigate to Billing",
  },
];

export const nav2: Navigation[] = [
  {
    name: "Nitel IQ Analytics",
    exact: false,
    permissions: "Nitel IQ Participant",
    ariaLabel: "Navigate to Nitel IQ Analytics",
    children: [
      // {
      //   link: "/analytics/cellular-usage",
      //   name: "Cellular Usage",
      //   exact: true,
      //   permissions: "",
      //   ariaLabel: "Navigate to Cellular Usage",
      // },
      {
        name: "Events & Leads",
        exact: false,
        permissions: "",
        ariaLabel: "Navigate to Events & Leads",
        children: [
          {
            link: "/analytics/metric-details",
            name: "Metric Details",
            exact: true,
            permissions: "",
            ariaLabel: "Navigate to Metric Details",
          },
        ],
      },
      // {
      //   link: "/analytics/overall-network-status",
      //   name: "Overall Network Status",
      //   exact: true,
      //   permissions: "",
      //   ariaLabel: "Navigate to Overall Network Status",
      // },
      // {
      //   link: "/analytics/quality-of-experience",
      //   name: "Quality of Experience",
      //   exact: true,
      //   permissions: "",
      //   ariaLabel: "Navigate to Quality of Experience",
      // },
      {
        link: "/analytics/unusual-network-metrics",
        name: "Unusual Network Metrics",
        exact: true,
        permissions: "",
        ariaLabel: "Navigate to Unusual Network Metrics",
      },
    ],
  },
  {
    link: "/orders",
    name: "Orders",
    exact: true,
    permissions: "Orders",
    ariaLabel: "Navigate to Orders",
  },
  {
    name: "Reports",
    exact: false,
    permissions: ["Orders", "Services", "Ticketing: Read"],
    ariaLabel: "Navigate to Reports",
    children: [
      {
        link: "/reports/operations-summary",
        name: "Operations Summary",
        exact: true,
        permissions: ["Ticketing:read", "Orders", "Services"],
        ariaLabel: "Navigate to Operations Summary Report",
      },
      {
        link: "/reports/orders-summary",
        name: "Orders Summary",
        exact: true,
        permissions: "Orders",
        ariaLabel: "Navigate to Orders Summary Report",
      },
    ],
  },
  {
    link: "/services",
    linkParams: { groupBy: "Location" },
    name: "Services",
    exact: true,
    permissions: "Services",
    ariaLabel: "Navigate to Services",
  },
  {
    link: "/tickets",
    linkParams: { groupBy: "Type" },
    name: "Tickets",
    exact: true,
    permissions: "Ticketing: Read",
    ariaLabel: "Navigate to Tickets",
  },
];

export const entraNav = {
  icon: "person",
  exact: false,
  menu: [
    {
      label: "Settings",
      icon: "settings",
      exact: true,
      action: "editProfile", // Function reference in the component
    },
    {
      label: "Manage Users",
      icon: "manage_accounts",
      link: "/manage/users",
      exact: true,
      permissions: "Manage Users",
    },
    {
      label: "Sign Out",
      icon: "logout",
      link: "/logout",
    },
  ],
};


export const mobileNav: Navigation[] = [
  {
    link: "/orders",
    name: "Nitel IQ: Unusual Network Metrics",
    exact: true,
    permissions: "Nitel IQ Participant",
    ariaLabel: "Navigate to Unusual Network Metrics",
  },
  {
    link: "/orders",
    name: "Nitel IQ: Metric Details",
    exact: true,
    permissions: "Nitel IQ Participant",
    ariaLabel: "Navigate to Metric Details",
  },
  {
    link: "/operations-summary",
    name: "Operations Summary Report",
    exact: true,
    permissions: "",
    ariaLabel: "Navigate to Operations Summary Report",
  },
  {
    link: "/orders",
    name: "Orders",
    exact: true,
    permissions: "Orders",
    ariaLabel: "Navigate to Orders",
  },
  {
    link: "/orders-dashboard",
    name: "Orders Summary Report",
    exact: true,
    permissions: "Orders",
    ariaLabel: "Navigate to Orders Summary Report",
  },
  {
    link: "/services",
    name: "Services",
    exact: true,
    permissions: "Services",
    ariaLabel: "Navigate to Services",
  },
  {
    link: "/tickets",
    name: "Tickets",
    exact: true,
    permissions: "Ticketing: Read",
    ariaLabel: "Navigate to Tickets",
  },
];
