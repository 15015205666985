<section class="parent-container">
  <div class="block max-w-6xl mx-auto gap-2 px-2 pb-6">
    <div class="container-top">
      <div class="container-title">
        <mat-toolbar>
          <h1>Network Status</h1>
          <div class="mock-data-toggle">
            <mat-slide-toggle
              [(ngModel)]="showMockData"
              (ngModelChange)="toggleMockData($event)"
              color="primary"
              [disabled]="loading"
            >
              {{
                showMockData ? "Switch to Real Data" : "Switch to Sample Data"
              }}
            </mat-slide-toggle>
          </div>
        </mat-toolbar>
      </div>
    </div>

    <div class="flex flex-col md:flex-row gap-2 transition-all duration-300 ease-in-out h-full">
      <div class="w-full flex-[3]">
        <app-analytics-widget
          class="flex shadow-md rounded-[15px] bg-white p-0.5 border-[1.5px] border-white/50 relative overflow-hidden h-full"
        >
          <app-leaflet-map
            class="h-full"
            [markers]="markers"
            [categories]="categories"
          />
          <ng-container *ngIf="loading">
            <div class="widget-element-loading">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
          </ng-container>
        </app-analytics-widget>
      </div>
      <div class="flex gap-2 flex-col flex-[1]">
        <app-analytics-widget class="flex-1 shadow-md rounded-[15px]">
          <ons-widget-summary />
        </app-analytics-widget>

        <ng-container *ngxPermissionsOnly="'Invoice and Billing: Read'">
          <app-analytics-widget class="flex-1 shadow-md rounded-[15px]">
            <ons-widget-billing [navigateOnClick]="true" />
          </app-analytics-widget>
        </ng-container>
      </div>
    </div>

    <div class="flex flex-col md:flex-row gap-2 w-full h-full pt-4">
        <ng-container *ngxPermissionsOnly="'Ticketing: Read'">
          <app-analytics-widget class="flex-1 shadow-md rounded-[15px]">
            <ons-widget-tickets [navigateOnClick]="true" />
          </app-analytics-widget>
        </ng-container>

        <ng-container *ngxPermissionsOnly="'Orders'">
          <app-analytics-widget class="flex-1 shadow-md rounded-[15px]">
            <ons-widget-orders [navigateOnClick]="true" />
          </app-analytics-widget>
        </ng-container>

        <ng-container *ngxPermissionsOnly="'Services'">
          <app-analytics-widget class="flex-1 shadow-md rounded-[15px]">
            <ons-widget-services [navigateOnClick]="true" />
          </app-analytics-widget>
        </ng-container>
      </div>
  </div>
</section>
