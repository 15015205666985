import {
  Component,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  ChangeDetectionStrategy,
} from "@angular/core";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  query,
  animateChild,
  stagger,
} from "@angular/animations";
import { Subscription } from "rxjs";

import { AuthService } from 'src/app/services/auth.service';
import { Navigation, mobileNav, nav } from 'src/app/models/navigation.model';
import { MsalService } from "@azure/msal-angular";
import { RedirectRequest } from "@azure/msal-browser";
import { entraPolicies } from "src/app/msal.config";
import { ContactService } from "src/app/services/contact.service";
import { MSGraphService } from "../../../../services/ms-graph.service";
import { NgxPermissionsService } from "ngx-permissions";

@Component({
  selector: "app-account-menu",
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: "./account-menu.component.html",
  styleUrls: ["./account-menu.component.scss"],
  host: {
    "(document:click)": "onClick($event)",
    "(document:pan)": "onPan()",
  },
  animations: [
    trigger("parentAnimation", [
      state(
        "true",
        style({
          opacity: 1,
        })
      ),
      state(
        "false",
        style({
          opacity: 0,
        })
      ),
      transition("false => true", [
        animate("200ms"),
        query("@childAnimation", [animateChild(), stagger(50, animateChild())]),
      ]),
      transition("true => false", [animate("50ms")]),
    ]),
    trigger("modalStateDisplay", [
      state("true", style({ display: "block" })),
      state("false", style({ display: "none" })),
      transition("0 => 1", animate("0ms ease")),
      transition("1 => 0", animate("0ms 200ms ease")),
    ]),
    trigger("childAnimation", [
      state(
        "true",
        style({
          opacity: 1,
        })
      ),
      state(
        "false",
        style({
          opacity: 0,
        })
      ),
      transition("*=>true", [animate(250)]),
    ]),
  ],
})
export class AccountMenuComponent {
  toggleMenu = false;
  subscription: Subscription;
  nav: Navigation[] = nav;
  nav3: Navigation[] = mobileNav;

  @ViewChild("mobileMenuButton", { static: true }) mobileMenuButton: ElementRef;
  @ViewChildren("mobileMenuView") mobileMenuView: QueryList<ElementRef>;

  constructor(
    private elementRef: ElementRef,
    private msalService: MsalService,
    private contactService: ContactService,
  ) {
  }

  toggle() {
    this.toggleMenu = !this.toggleMenu;
  }

  menuItemSelect() {
    this.toggleMenu = false;
  }

  // Hide Menu
  onClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target))
      // or some similar check
      this.toggleMenu = false;
  }

  // Hide Menu
  onPan() {
    this.toggleMenu = false;
  }

  editProfile() {
    const editProfileRequest: RedirectRequest = {
      authority: entraPolicies.authorities.passwordReset,
      scopes: ["openid", "profile", "offline_access"],
    };

    this.msalService.loginPopup(editProfileRequest).subscribe({
      next: (result) => {
        this.contactService.updateContact({
          firstName: result.account.idTokenClaims.given_name as string,
          lastName: result.account.idTokenClaims.family_name as string,
        });
      },
      error: (error) => {
        console.error("Profile edit failed", error);
      },
    });
  }
}
