import {
  Component,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  ViewEncapsulation,
} from "@angular/core";
import { ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ApexTitleSubtitle,
  ApexLegend,
} from "ng-apexcharts";

type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  colors: any;
};

interface DataPointSelectionConfig {
  dataPointIndex: number;
  seriesIndex: number;
  [key: string]: any; // To allow additional properties if needed
}

@Component({
  selector: "app-analytics-apex-chart-pie",
  templateUrl: "./analytics-apex-chart-pie.component.html",
  styleUrls: ["../analytics.scss"],
  encapsulation: ViewEncapsulation.None, // Disables encapsulation
})
export class AnalyticsApexChartPieComponent {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  @Input() series: number[] = [];
  @Input() labels: string[] = [];
  @Input() title: string = "Pie Chart";

  @Output() categoryChange = new EventEmitter<string>();

  constructor() {
    this.setOptions();
  }

  ngOnChanges() {
    this.setOptions();
  }

  onChangeCategory(config: DataPointSelectionConfig) {
    const selectedPointIndex = config.dataPointIndex;
    const selectedlabel = this.chartOptions.labels[selectedPointIndex];
    this.categoryChange.emit(selectedlabel.toLowerCase());
  }

  setOptions() {
    this.chartOptions = {
      series: this.series,
      chart: {
        height: 350, // Minimum height in pixels
        type: "pie",
        events: {
          dataPointSelection: (
            event: any,
            chartContext: any,
            config: DataPointSelectionConfig
          ) => {
            if (this.categoryChange.observed) {
              this.onChangeCategory(config);
            }
          },
        },
      },
      labels: this.labels,
      legend: {
        show: true,
        position: "bottom",
        floating: false,
      },
      title: {
        text: this.title,
        align: "center",
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: true,
              position: "bottom",
            },
          },
        },
      ],
      colors: [
        "#ffbb2c",
        "#ef4732",
        "#3dcc64",
        "#7a4b8a",
        "#e7f9fc",
        "#ff742e",
        "#17328d",
        "#3e95dc",
        "#8793a2",
        "#365fbf",
        "#81548f",
      ],
    };
  }
}
