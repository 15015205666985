import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

import {
  Ticket,
  TicketGrouping,
  TicketType,
  getTicketType,
  mapStringToTicketGrouping
} from 'src/app/models/ticket.model';
import { Comment } from 'src/app/models/comment.model';
import { UntypedFormControl } from '@angular/forms';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BaseChartDirective } from 'ng2-charts';
import { ActivatedRoute, Router } from '@angular/router';

const validGroupByValues: Record<string, string> = {
  Location: 'Location',
  Status: 'Status',
  Type: 'Type',
};

const validStatusValues: string[] = ['Open', 'Closed'];

@Component({
  selector: 'tickets-list-display',
  templateUrl: './ticket-list-display.component.html',
  styleUrls: ['./ticket-list-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketListDisplayComponent implements OnInit, OnChanges {
  @Input() browserSize: string;
  @Input() tickets: Ticket[];
  @Input() csvData: any[];
  @Input() selectedTicket: Ticket;
  @Input() isLoading: boolean;
  @Input() isLoaded: boolean;
  @Output() onSelectTicket: EventEmitter<Ticket> = new EventEmitter();
  @Output() onOpenDialog = new EventEmitter();
  @ViewChild('headerContainer', { static: false }) headerContainer: ElementRef;

  headerHeight: number;
  ticketListHeight: number;
  searchTermControl = new UntypedFormControl();
  formCtrlSub: Subscription;
  searchTerm: string = '';
  selectedPanel: string = '';
  isOrderingAsc: boolean = true;
  currentGrouping: TicketGrouping = TicketGrouping.Type;
  previousGrouping: TicketGrouping | null = null;
  groupedTickets: { key: string; tickets: Ticket[] }[] = [];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private route: Router,
  ) {}

  ngOnInit() {
    this.initialize();

    this.formCtrlSub = this.searchTermControl.valueChanges.pipe(debounceTime(300)).subscribe((newValue) => {
      this.searchTerm = newValue;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnChanges() {
    this.initialize();

    this.activatedRoute.queryParams.subscribe(params => {
      const groupBy = validGroupByValues[params['groupBy']];
      if (!groupBy) return;

      this.currentGrouping = mapStringToTicketGrouping(groupBy);

      if (groupBy == 'Status' && validStatusValues.includes(params['status'])) {
        this.groupedTickets = this.groupedTickets.filter((t) => t.key == params['status']);
      }
    });
  }

  initialize() {
    if (this.tickets) {
      this.groupedTickets = [];
      this.groupTickets(this.currentGrouping, this.tickets);
      if (this.groupedTickets.length >= 1 && this.selectedTicket == null) {
        this.onSelect(this.groupedTickets[0].tickets[0]);
      }
    }
  }

  onExpanded() {
    var serviceDiv = document.getElementById(this.selectedPanel);
    serviceDiv.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  }

  onHeaderClick(e) {
    this.selectedPanel = e.currentTarget.id;
  }

  openDialog() {
    this.onOpenDialog.emit();
  }

  onSelect($event: Ticket) {
    this.onSelectTicket.emit($event);
  }

  hasUnreadComments = (comments: Comment[]) => {
    if (comments) {
      return comments.some((c) => !c.Is_Read__c);
    }
    return false;
  };

  onClickGroupBy(value: string) {
    const grouping = mapStringToTicketGrouping(value);
    if (this.currentGrouping === grouping) {
      this.isOrderingAsc = !this.isOrderingAsc;
      this.sortTicketGroups();
    } else {
      this.currentGrouping = grouping;
      this.route.navigate([], { queryParams: { groupBy: value }  });
      this.groupTickets(grouping, this.tickets);
      if (!this.isOrderingAsc) {
        this.sortTicketGroups();
      }
    }
  }

  groupTickets(newGroup: TicketGrouping, currTickets: Ticket[]): void {
    // don't attempt to group if we never changed the grouping
    // if (this.previousGrouping === newGroup) {
    //   return;
    // }
    this.previousGrouping = this.currentGrouping;
    if (this.currentGrouping === TicketGrouping.Type) {
      this.groupTicketsByType(currTickets);
    } else if (this.currentGrouping === TicketGrouping.Status) {
      this.groupTicketsByStatus(currTickets);
    } else if (this.currentGrouping == TicketGrouping.Location) {
      this.groupTicketsByLocation(currTickets);
    } else {
      // in case more groups are added latter and forgotten to be added, group by type.
      this.groupTicketsByType(currTickets);
    }
  }

  groupTicketsByType(tickets: Ticket[]) {
    this.groupedTickets = [];
    const careTickets = {
      key: 'Care',
      tickets: tickets.filter((ticket) => getTicketType(ticket) === TicketType.Care)
    };
    const financeTickets = {
      key: 'Finance',
      tickets: tickets.filter((ticket) => getTicketType(ticket) === TicketType.Finance)
    };
    const maintenanceTickets = {
      key: 'Maintenance',
      tickets: tickets.filter((ticket) => getTicketType(ticket) === TicketType.Maintenance)
    };
    const proactiveTickets = {
      key: 'Proactive',
      tickets: tickets.filter((ticket) => getTicketType(ticket) === TicketType.Proactive)
    };
    const supportTickets = {
      key: 'Support',
      tickets: tickets.filter((ticket) => getTicketType(ticket) === TicketType.Support)
    };

    if (careTickets.tickets.length > 0) {
      this.groupedTickets.push(careTickets);
    }
    if (financeTickets.tickets.length > 0) {
      this.groupedTickets.push(financeTickets);
    }
    if (maintenanceTickets.tickets.length > 0) {
      this.groupedTickets.push(maintenanceTickets);
    }
    if (proactiveTickets.tickets.length > 0) {
      this.groupedTickets.push(proactiveTickets);
    }
    if (supportTickets.tickets.length > 0) {
      this.groupedTickets.push(supportTickets);
    }
  }

  groupTicketsByStatus(tickets: Ticket[]) {
    this.groupedTickets = [];
    // get all the statuses that there could be
    //const allStatuses = new Set(this.tickets.map((ticket) => ticket.Status));
    const allStatuses = new Map();
    allStatuses.set('Open', { key: 'Open', tickets: [] });
    allStatuses.set('Closed', { key: 'Closed', tickets: [] });

    for (const ticket of tickets) {
      let groupName = ticket.Status == 'Closed' ? 'Closed' : 'Open';
      allStatuses.get(groupName).tickets.push(ticket);
    }
    this.groupedTickets.push(allStatuses.get('Open'));
    this.groupedTickets.push(allStatuses.get('Closed'));
  }

  groupTicketsByLocation(tickets: Ticket[]) {
    this.groupedTickets = [];
    // get all locations that there could be
    const locations = new Set(this.tickets.map((ticket) => ticket.Service_Location_Address__c));
    // loop through all the addresses, and create a group for them
    for (const location of locations) {
      const locationGroup = {
        key: location,
        tickets: tickets.filter((ticket) => ticket.Service_Location_Address__c === location)
      };
      this.groupedTickets.push(locationGroup);
    }
    this.groupedTickets = this.groupedTickets.sort((statusA, statusB) =>
      statusA.key < statusB.key ? -1 : statusA.key > statusB.key ? 1 : 0
    );
  }

  sortTicketGroups() {
    this.groupedTickets.reverse();
  }

  setTicketListHeight() {
    setTimeout(() => {
      if (this.browserSize !== 'small') {
        this.headerHeight = this.headerContainer.nativeElement ? this.headerContainer.nativeElement.offsetHeight : 0;
        const totalHeaderSpace = this.headerHeight + 110;
        const viewportHeight = window.innerHeight; // browser height

        this.ticketListHeight = viewportHeight - totalHeaderSpace;
      }
    }, 1000);
  }
}
