/* eslint-disable no-restricted-syntax */
import {
  Component,
  OnDestroy,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterContentInit,
  OnInit,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Store } from "@ngrx/store";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  EMPTY,
  finalize,
  Observable,
  Subject,
  takeUntil,
  tap,
} from "rxjs";

import * as fromStore from "src/app/store/";
import { TicketService } from "src/app/services/ticket.service";
import { OrderService } from "src/app/services/order.service";
import { ContentDocumentLink } from "src/app/models/content-document-link.model";
import { Order } from "src/app/models/order.model";
import { Service } from "src/app/models/service.model";
import { User } from "src/app/models/user.model";

@Component({
  selector: "app-create-ticket",
  templateUrl: "./create-ticket.component.html",
  styleUrls: ["./create-ticket.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTicketComponent
  implements OnInit, AfterContentInit, OnDestroy
{
  browserSize: string;
  dialogWidth: string;
  dialogHeight: string;

  ticketFormGroup: UntypedFormGroup = this.fb.group({
    supportType: this.fb.control("", Validators.required),
  });

  formSubmitAttempted: boolean = false;
  inlineErrors: any = {};
  createdOrder: Order = null;

  errorMessage: string = null;
  private hasSubmissionErrorSubject: BehaviorSubject<boolean> =
    new BehaviorSubject(false);
  hasSubmissionError$ = this.hasSubmissionErrorSubject.asObservable();

  private isTicketOptionSelectedSubject = new BehaviorSubject<boolean>(false);
  isTicketOptionSelected$ = this.isTicketOptionSelectedSubject.asObservable();

  private ticketSubmittedSubject = new BehaviorSubject<boolean>(false);
  ticketSubmitted$ = this.ticketSubmittedSubject.asObservable();

  private isSubmittingSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  isSubmitting$: Observable<boolean> = this.isSubmittingSubject.asObservable();

  invoices$: Observable<ContentDocumentLink[]> = this.store.select(
    fromStore.getAllInvoiceAttachments
  );
  areInvoicesLoading$: Observable<boolean> = this.store.select(
    fromStore.getInvoiceAttachmentsLoading
  );
  areInvoicesLoaded$: Observable<boolean> = this.store.select(
    fromStore.getInvoiceAttachmentsLoaded
  );

  services$: Observable<Service[]> = this.store.select(
    fromStore.getServicesInService
  );
  areServicesLoading$: Observable<boolean> = this.store.select(
    fromStore.getServicesLoading
  );
  areServicesLoaded$: Observable<boolean> = this.store.select(
    fromStore.getServicesLoaded
  );
  selectedServiceId: string;

  contacts$: Observable<User[]> = this.store.select(fromStore.getContacts);
  disconnectReasons$: Observable<string[]> = this.store.select(
    fromStore.getDisconnectReasons
  );

  private destroy$ = new Subject<void>();

  get canSubmit(): boolean {
    return true;
  }

  constructor(
    public dialogRef: MatDialogRef<CreateTicketComponent>,
    private store: Store<fromStore.State>,
    private ticketService: TicketService,
    private orderService: OrderService,
    private fb: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) private data
  ) {}

  ngOnInit() {
    this.dialogRef.disableClose = true;
    this.browserSize = this.data.browserSize;
    this.dialogWidth =
      this.browserSize === "small" ? "calc(100vw - 20px)" : "650px";
    this.dialogHeight = this.browserSize === "small" ? "315px" : "380px";

    // ✅ Dispatch only if services or invoices are missing
    combineLatest([this.areServicesLoaded$, this.areInvoicesLoaded$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([areServicesLoaded, areInvoicesLoaded]) => {
        if (!areServicesLoaded) {
          this.store.dispatch(new fromStore.LoadServices());
        }
        if (!areInvoicesLoaded) {
          this.store.dispatch(new fromStore.LoadInvoiceAttachments());
        }
      });
    this.store.dispatch(new fromStore.LoadContacts());
    this.store.dispatch(new fromStore.LoadDisconnectReasons());
  }

  ngAfterContentInit() {
    if (this.data.originPage) {
      this.ticketFormGroup.controls.supportType.setValue(this.data.originPage);
    }
    this.selectedServiceId = this.data?.selectedServiceId || null;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  makeTicketSelection() {
    this.formSubmitAttempted = true;
    this.validateFields();
    if (this.ticketFormGroup.value.supportType !== "") {
      this.isTicketOptionSelectedSubject.next(true);
      this.dialogRef.updateSize(this.dialogWidth, "calc(100vh - 20px)");
    }
    return false;
  }

  validateFields() {
    for (const prop in this.ticketFormGroup.controls) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.ticketFormGroup.controls.hasOwnProperty(prop)) {
        const control: any = this.ticketFormGroup.controls[prop];
        this.inlineErrors[prop] = control.valid
          ? null
          : "This is a required field";
      }
    }
  }

  submitTicket() {
    this.ticketSubmittedSubject.next(true);
    this.dialogRef.updateSize(this.dialogWidth, "550px");
  }

  submitNocForm(request) {
    this.isSubmittingSubject.next(true);

    this.ticketService
      .createNocTicket(request)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.hasSubmissionErrorSubject.next(false);
          setTimeout(() => {
            this.store.dispatch(new fromStore.ClearTickets());
            this.store.dispatch(new fromStore.LoadTickets());
          }, 1000);
          this.submitTicket();
          this.changeDetectorRef.detectChanges();
        }),
        catchError((error) => {
          this.hasSubmissionErrorSubject.next(true);
          this.getErrorMessage(error);
          this.changeDetectorRef.detectChanges();
          return EMPTY; // Prevents the stream from breaking
        }),
        finalize(() => this.isSubmittingSubject.next(false))
      )
      .subscribe();
  }

  submitCareForm(request) {
    this.isSubmittingSubject.next(true);

    this.ticketService
      .createCareTicket(request)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.hasSubmissionErrorSubject.next(false);
          setTimeout(() => {
            this.store.dispatch(new fromStore.ClearTickets());
            this.store.dispatch(new fromStore.LoadTickets());
          }, 1000);
          this.submitTicket();
          this.changeDetectorRef.detectChanges();
        }),
        catchError((error) => {
          this.hasSubmissionErrorSubject.next(true);
          this.getErrorMessage(error);
          this.changeDetectorRef.detectChanges();
          return EMPTY; // Prevents stream from breaking
        }),
        finalize(() => this.isSubmittingSubject.next(false))
      )
      .subscribe();
  }

  submitBillForm(request) {
    this.isSubmittingSubject.next(true);

    this.ticketService
      .createBillingTicket(request)
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.hasSubmissionErrorSubject.next(false);
          setTimeout(() => {
            this.store.dispatch(new fromStore.ClearTickets());
            this.store.dispatch(new fromStore.LoadTickets());
          }, 1000);
          this.submitTicket();
          this.changeDetectorRef.detectChanges();
        }),
        catchError((error) => {
          this.hasSubmissionErrorSubject.next(true);
          this.getErrorMessage(error);
          this.changeDetectorRef.detectChanges();
          return EMPTY; // Prevents stream from breaking
        }),
        finalize(() => this.isSubmittingSubject.next(false))
      )
      .subscribe();
  }

  submitOrderForm(request) {
    this.isSubmittingSubject.next(true);

    this.orderService
      .createOrder(request)
      .pipe(
        takeUntil(this.destroy$),
        tap((data) => {
          this.hasSubmissionErrorSubject.next(false);
          this.createdOrder = data;
          this.store.dispatch(new fromStore.ClearOrders());
          this.store.dispatch(new fromStore.LoadOrders());
          this.submitTicket();
          this.changeDetectorRef.detectChanges();
        }),
        catchError((error) => {
          this.hasSubmissionErrorSubject.next(true);
          this.getErrorMessage(error);
          this.changeDetectorRef.detectChanges();
          return EMPTY; // Prevents stream from breaking
        }),
        finalize(() => this.isSubmittingSubject.next(false))
      )
      .subscribe();
  }

  private getErrorMessage(error: any) {
    if (error.errors && Array.isArray(error.errors)) {
      this.errorMessage = error.errors.join("\n");
    } else {
      this.errorMessage =
        error || "Server Error: Unable to submit the ticket. Please try again!";
    }
  }

  closeDialog() {
    this.isSubmittingSubject.next(false);
    this.hasSubmissionErrorSubject.next(false);
    this.errorMessage = null;
    this.dialogRef.close();
  }

  goBack() {
    this.isTicketOptionSelectedSubject.next(false);
    this.isSubmittingSubject.next(false);
    this.hasSubmissionErrorSubject.next(false);
    this.errorMessage = null;
    this.dialogRef.updateSize(this.dialogWidth, this.dialogHeight);
  }
}
