/* eslint-disable no-shadow */
import { createFeatureSelector } from "@ngrx/store";
import * as fromActions from "../actions";
import * as fromRoot from ".";
import { PortalRole } from "../../models/portal-role.model";

// State for Service Order Forms
export interface PortalRoleState {
  entities: { [id: string]: PortalRole };
  loaded: boolean;
  loading: boolean;
}

export const initialState: PortalRoleState = {
  entities: {},
  loaded: false,
  loading: false,
};

export interface State extends fromRoot.State {
  portalRoles: PortalRoleState;
}

export const getPortalRoleFeatureState =
  createFeatureSelector<PortalRoleState>("portalRoles");

export function reducer(
  state = initialState,
  action: fromActions.PortalRolesAction
): PortalRoleState {
  switch (action.type) {
    case fromActions.LOAD_PORTAL_ROLES: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromActions.LOAD_PORTAL_ROLES_SUCCESS: {
      const portalRoles = action.payload;
      let entities = {};

      if (portalRoles !== null) {
        entities = portalRoles.reduce(
          (entities: { [id: string]: PortalRole }, portalRole) => {
            return {
              ...entities,
              [portalRole.Id]: portalRole,
            };
          },
          {
            ...state.entities,
          }
        );
      }

      return {
        ...state,
        loading: false,
        loaded: true,
        entities,
      };
    }

    case fromActions.LOAD_PORTAL_ROLES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getPortalRoleEntities = (state: PortalRoleState) =>
  state.entities;
export const getPortalRoleLoading = (state: PortalRoleState) => state.loading;
export const getPortalRoleLoaded = (state: PortalRoleState) => state.loaded;
