import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
  OnChanges,
  EventEmitter,
  Output,
  OnInit,
  DoCheck,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { AuthService } from 'src/app/services/auth.service';
import { HeaderService } from 'src/app/services/header.service';

import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import moment from 'moment';

import { CSVFormatterService } from 'src/app/services/csv-formatter.service';
import { Service } from '../../../models/service.model';
import { ServiceGroup } from '../../../models/service-group.model';

import { LocationNameComponent } from '../location-name/location-name.component';
import { SlideInOutAnimation } from '../../inventory.animations';

const validGroupByValues: Record<string, string> = {
  Location: "Z_Location__r.Name",
  Service: "RecordType.Name",
  Status: "Status__c",
};

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss'],
  animations: [SlideInOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InventoryListComponent implements OnInit, OnChanges, DoCheck, OnDestroy {
  @Input() browserSize: string;

  @Input() services: Service[];
  @Input() isLoading: boolean;
  @Input() isLoaded: boolean;

  @Input() groupedServices: { [key: string]: ServiceGroup } = {};
  @Input() activeServices: Service[];

  @Input() selectedService: Service;
  @Output() onSetSelectedService: EventEmitter<any> = new EventEmitter();

  @Input() isServicesInProgress: boolean;
  @Input() groupByFormControl: UntypedFormControl;
  @Input() isAscFormControl: UntypedFormControl;

  // Filter
  @Output() onLoadingIndicator: EventEmitter<any> = new EventEmitter();
  @Input() locationsFilterOptions: string[];
  @Input() locationsFilterFormControl: UntypedFormControl;
  selectedLocationFilters: string[] = [];

  @Input() serviceFilterOptions: string[];
  @Input() serviceFilterFormControl: UntypedFormControl;
  selectedServiceFilters: string[] = [];

  @Input() statusFilterOptions: string[];
  @Input() statusFilterFormControl: UntypedFormControl;
  selectedStatusFilters: string[] = [];
  formattedCSVData = [];

  @Input() totalSelectedFilters = 0;
  @ViewChild('headerContainer', { static: false }) headerContainer: ElementRef;
  animationState = 'out';
  headerHeight: number;
  inventoryListHeight: number;

  searchTerm: any;
  searchTermControl = new UntypedFormControl();
  formCtrlSub: Subscription;
  page = 1;

  constructor(
    protected authService: AuthService,
    protected route: Router,
    private activatedRoute: ActivatedRoute,
    private header: HeaderService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private csvFormatter: CSVFormatterService
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const groupBy = validGroupByValues[params["groupBy"]];
      if (groupBy) this.groupByFormControl.setValue(groupBy);
    });

    this.header.show();

    // RXJS for search input
    this.formCtrlSub = this.searchTermControl.valueChanges
      .pipe(debounceTime(300))
      // eslint-disable-next-line no-return-assign
      .subscribe((newValue) => {
        this.searchTerm = newValue === 'Pending' ? 'New' : newValue;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnChanges() {
    if (this.groupedServices) {
      if ((this.selectedService === null || this.selectedService === undefined) && Object.keys(this.groupedServices).length > 0) {
        this.onSetSelectedService.emit(this.groupedServices[0].services[0].Id);
      }
    }
    this.formatCSVData();
  }

  ngDoCheck() {
    this.setInventoryListHeight();
  }

  ngOnDestroy() {
    if (this.formCtrlSub) {
      this.formCtrlSub.unsubscribe();
    }
  }

  onSelectService(service: Service): void {
    this.onSetSelectedService.emit(service.Id);
  }

  onHeaderClick(service) {
    var serviceDiv = document.getElementsByClassName('inventory-viewport');
    serviceDiv[0].scrollTop = 0;
    this.onSetSelectedService.emit(service.Id);
  }

  removeFilterSelection(key, value) {
    if (key === 'Z_Location__r.Name') {
      const newSelections = this.locationsFilterFormControl.value.filter((s) => s !== value);
      this.locationsFilterFormControl.setValue(newSelections);
    }
    if (key === 'RecordType.Name') {
      const newSelections = this.serviceFilterFormControl.value.filter((s) => s !== value);
      this.serviceFilterFormControl.setValue(newSelections);
    }
    if (key === 'Status__c') {
      const newSelections = this.statusFilterFormControl.value.filter((s) => s !== value);
      this.statusFilterFormControl.setValue(newSelections);
    }
  }

  onClickGroupBy(value: string) {
    this.onLoadingIndicator.emit();
    const groupBy = validGroupByValues[value];

    if (this.groupByFormControl.value === groupBy) {
      this.isAscFormControl.setValue(!this.isAscFormControl.value);
    } else {
      this.route.navigate([], { queryParams: { groupBy: value }  });
      this.groupByFormControl.setValue(groupBy);
      this.page = 1;
    }
  }

  formatCSVData() {
    const newCSVData = [];

    this.services.forEach((service) => {
      const elementInfo = this.csvFormatter.getElementInfo(service);

      const object = {
        NIT: service.Name,
        'Bundle Type': service.RecordType.Name,
        Status: service.Status__c === 'New' ? 'Pending' : service.Status__c,
        Customer: service.Account__r.Name,
        'Customer PON':
          service.Orders__r && Array.isArray(service.Orders__r) && service.Orders__r.length > 0
            ? service.Orders__r[0].Customer_PON__c
            : '',
        'Customer Site ID': service.Z_Location_Customer_Side_Id__c,
        'End User Company': service.Z_Location_Company_Name__c,
        'Street Address':
          service.Z_Location__r.Street_2__c !== null
            ? `${service.Z_Location__r.Street_1__c}, ${service.Z_Location__r.Street_2__c}`
            : service.Z_Location__r.Street_1__c,
        City: service.Z_Location__r.City__c,
        State: service.Z_Location__r.State__c,
        ZIP: service.Z_Location__r.Zip_Code__c,
        'Local Contact': service.localContacts,
        'Sales Representative': service.Sales_Rep__r ? service.Sales_Rep__r.Name : '',
        'Account Manager':
          service.Account__r && service.Account__r.Account_Manager__r ? service.Account__r.Account_Manager__r.Name : '',
        'Connector Type': service.Connector_Type_Z_Location__c,
        Bandwidth: service.Asymmetrical_Bandwidth__c
          ? `${service.Bandwidth_Downstream__c} / ${service.Bandwidth_Upstream__c}`
          : service.Bandwidth_Downstream__c,
        Term: service.Term__c,
        'End of Term': service.Current_Term_End_Date__c,
        Provider: service?.Vendor__r?.Display_Alias__c
          ? service?.Vendor__r?.Display_Alias__c
          : ( service?.Vendor__r?.Name
          ? service?.Vendor__r?.Name
              : ''
            )
        ,

        'Provider Circuit ID': elementInfo.vendorCircuitId ? elementInfo.vendorCircuitId : '',
        'WAN IP Block': elementInfo.wanIpBlock ? elementInfo.wanIpBlock : '',
        'WAN Mask': elementInfo.wanMask ? elementInfo.wanMask : '',
        'LAN IP Block': elementInfo.lanIpBlock ? elementInfo.lanIpBlock : '',
        'LAN Mask': elementInfo.lanMask ? elementInfo.lanMask : '',
        Dmarc: elementInfo.dmarcZLoc ? elementInfo.dmarcZLoc : '',
        'Nitel Equipment': elementInfo.equipmentName ? elementInfo.equipmentName : '',
        'Start of Service': service.Start_Of_Service__c,
        'End of Service': service.End_Of_Service__c,
        'Activation Completed Date': service.Activation_Date__c ? moment(service.Activation_Date__c).format('L') : '',
        'Billing Initiated Date': service.First_Invoice__c,
        MRC: service.MRR__c
      };

      newCSVData.push(object);
    });

    this.formattedCSVData = newCSVData;
  }

  onEditLocationName(service: Service) {
    // open modal
    const dialogConfig = {
      autoFocus: true,
      minHeight: this.browserSize === 'small' ? '315px' : '380px',
      width: this.browserSize === 'small' ? 'calc(100vw - 20px)' : '650px',
      maxWidth: '650px',
      data: {
        browserSize: this.browserSize,
        originPage: 'Service',
        locationId: service.Z_Location__c,
        locationAddress: service.Z_Location__r.Name,
        banLookupId: service.BanLookupId
      },
      disableClose: false
    };

    this.dialog.open(LocationNameComponent, dialogConfig);
  }

  toggleShowDiv(divName: string) {
    if (divName === 'filter-wrapper') {
      this.animationState = this.animationState === 'out' ? 'in' : 'out';
      this.setInventoryListHeight();
    }
  }

  setInventoryListHeight() {
    setTimeout(() => {
      if (this.browserSize !== 'small') {
        this.headerHeight = this.headerContainer.nativeElement ? this.headerContainer.nativeElement.offsetHeight : 0;
        const totalHeaderSpace = this.headerHeight + 110;
        const viewportHeight = window.innerHeight; // browser height

        this.inventoryListHeight = viewportHeight - totalHeaderSpace;
      }
    }, 1000);
  }

  hasObjectLength(dataObj: any, expectedLength: number): boolean {
    return Object.keys(dataObj ?? {}).length === expectedLength;
  }
}
