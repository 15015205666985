import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, from, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { MsalService } from "@azure/msal-angular";
import { AuthService } from "../services/auth.service";
import { entraConfig } from "../msal.config";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private msalService: MsalService,
    private authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const salesforceAuthToken = localStorage.getItem("salesforce_auth_token");
    const resetToken = localStorage.getItem("mynitel_reset_token");
    const account = this.msalService.instance.getAllAccounts()[0];

    if (account) {
      return from(
        this.msalService.acquireTokenSilent({
          account: account,
          scopes: entraConfig.appScopes.nitelApi.scopes,
        })
      ).pipe(
        switchMap((response) => {
          const clonedRequest = req.clone({
            setHeaders: {
              Authorization: `Bearer ${response.accessToken}`,
              "Salesforce-Authorization": `Bearer ${salesforceAuthToken}`,
              "Cache-control": "no-cache, no-store, must-revalidate",
              Pragma: "no-cache",
              Expires: "-1",
            },
          });
          return next.handle(clonedRequest);
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.authService.signout();
          }
          // console.error("Token acquisition failed:", error);
          return throwError(() => error);
        })
      );
    }

    if (salesforceAuthToken) {
      req = req.clone({
        setHeaders: {
          "Salesforce-Authorization": `Bearer ${salesforceAuthToken}`,
          "Cache-control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "-1",
        },
      });
      return next.handle(req);
    }

    if (resetToken) {
      req = req.clone({
        setHeaders: {
          "Salesforce-Authorization": `Bearer ${resetToken}`,
          "Content-Type": "application/json",
          "Cache-control": "no-cache, no-store, must-revalidate",
          Pragma: "no-cache",
          Expires: "-1",
        },
      });
      localStorage.removeItem("mynitel_reset_token");
    }

    return next.handle(req);
  }
}
