import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Store } from "@ngrx/store";

import * as fromStore from "src/app/store/";
import { User } from "src/app/models/user.model";
import { AuthService } from "src/app/services/auth.service";
import { NgxPermissionsService } from "ngx-permissions";

@Component({
  selector: "app-widget-summary",
  templateUrl: "./widget-summary.component.html",
  styleUrls: ["../widgets.scss", "./widget-summary.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetSummaryComponent implements OnInit {
  user$: Observable<User>;
  isLoading$: Observable<boolean>;
  isLoaded$: Observable<boolean>;
  subscription: Subscription;
  errorMessage: string;

  constructor(
    private store: Store<fromStore.State>,
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromStore.LoadCurrentUser);
    this.user$ = this.store.select(fromStore.getCurrentUser);
    this.isLoading$ = this.store.select(fromStore.getCurrentUserLoading);
    this.isLoaded$ = this.store.select(fromStore.getCurrentUserLoaded);
  }
}
