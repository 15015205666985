import { SharedModule } from '../../../shared.module';

import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-widget-summary-display',
  templateUrl: './widget-summary-display.component.html',
  styleUrls: ['../common/styles/widget-element.scss', './widget-summary-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    SharedModule,
  ],
})
export class WidgetSummaryDisplayComponent {

  @Input() loading: boolean = true;
  @Input({ alias: 'user.firstName' }) userFirstName: string;
  @Input({ alias: 'user.lastName' }) userLastName: string;
  @Input({ alias: 'user.title' }) userTitle: string;
  @Input({ alias: 'user.phone' }) userPhone: number | string;
  @Input({ alias: 'user.email' }) userEmail: string;
  @Input({ alias: 'user.accountName' }) userAccountName: string;
  @Input({ alias: 'user.billingAddress' }) userBillingAddress: string;

}
