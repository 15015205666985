import {
  LoadTickets,
  LoadTicketsFail,
  LoadTicketsSuccess,
} from "../../../store";
import { Ticket } from "src/app/models/ticket.model";
import { ChartService } from "./chart.service";
import { VerticalBarsKpi, BaseKPI } from "../../types";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import moment from "moment";
import { ChartType } from "../../types/chart-type";

type VerticalBarsKpiRecord = VerticalBarsKpi['value'][0];
const ALLOWED_BUSINESS_PURPOSES = Object.freeze([
  "NOC - Circuit Down",
  "NOC - Circuit Down/Disconnected",
  "NOC - Circuit Errors/Bouncing",
  "NOC - Circuit Up/No Surf",
  "NOC - Network Outage",
]);

const STATUS = "Closed";
const CURRENT_FY_START = moment("2024-01-01");
const CURRENT_FY_END = moment("2024-12-31");

@Injectable()
export class MTTRCircuitDownChartService extends ChartService<VerticalBarsKpi> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadTickets,
    failure: LoadTicketsFail,
    success: LoadTicketsSuccess,
  }).pipe(
    map(({ loading, data }) => {
      const dataValue: VerticalBarsKpiRecord[] = Object.values(
        (data || []).reduce(
          (response: Record<string, VerticalBarsKpiRecord>, ticket) => {
            if (!this.filterTickets(ticket)) return response;
            const ticketType = ticket.Portal_Ticket_Type__c;

            (response[ticketType] ??=  {
              label: ticketType || "(Other)",
              secondaryValue: 0,
              color: "#3498db",
              value: 0,
            }).value += 1;

            return response;
          },
          {} as Record<string, VerticalBarsKpiRecord>
        )
      );

      return {
        loading,
        ngClass: [],
        data: {
          type: ChartType.BARSV,
          id: "MTTR Circuit Down",
          title: "Circuit Downtime and Average Repair Time",
          hyperlink: "/tickets",
          value: dataValue,
          yAxisLabel: "Ticket Count",
        } satisfies VerticalBarsKpi,
      };
    })
  );

  private filterTickets(ticket: Ticket): boolean {
    if (!ticket.IsClosed) return false;
    if (!ticket.ClosedDate) return false;

    const dateToCheck = moment(ticket.ClosedDate || null);
    const isBetween = dateToCheck.isBetween(CURRENT_FY_START, CURRENT_FY_END, undefined, '[]')
    if (!isBetween) return false;

    return ALLOWED_BUSINESS_PURPOSES.includes(ticket.Business_Purpose__c)
  }
}
