import {
  LoadTickets,
  LoadTicketsFail,
  LoadTicketsSuccess,
} from "../../../store";
import { Ticket } from "src/app/models/ticket.model";
import { ChartType } from "../../types/chart-type";
import { HorizontalBarsKpi } from "../../types";
import { ChartService } from "./chart.service";
import { Injectable } from "@angular/core";
import { map } from "rxjs";

const STATES = Object.freeze(["Closed"]);
const BUSINESS_TYPES = Object.freeze([
  "NOC - Bandwidth / Throughput",
  "NOC - Chronic",
  "NOC - Circuit Down",
  "NOC - Circuit Down / Disconnected",
  "NOC - Circuit Down - Broadband",
  "NOC - Circuit Errors / Bouncing",
  "NOC - Circuit Up / No Surf",
  "NOC - COS / Applications",
  "NOC - CPE / Hardware issue",
  "NOC - Customer Facilities issue",
  "NOC - Firewall Issue",
  "NOC - Latency / Packet Loss",
  "NOC - Network Outage",
  "NOC - Routing issue",
  "NOC - SDWAN Issue",
  "NOC - Technical Question",
  "NOC - VOIP / CoS MPLS",
  "SD - WAN Add IPs",
  "SD - WAN Config Change",
  "SD - WAN General",
  "Support - Config Change",
  "Support - Config Request",
  "Support - DNS Change",
  "Support - Firewall Config Change",
  "Support - IP Request",
  "Support - SDWAN Config Change",
  "Support - Utilization Request",
])

@Injectable()
export class YtdNocCasesClosedCodeChartService extends ChartService<HorizontalBarsKpi> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadTickets,
    failure: LoadTicketsFail,
    success: LoadTicketsSuccess,
  }).pipe(
    map(({ loading, data }) => {
      type DataValue = HorizontalBarsKpi["value"];

      const dataValue: DataValue = Object.values(
        (data || []).reduce(
          (response: Record<string, DataValue[0]>, ticket) => {
            if (!this.filterByStatus(ticket)) return response;
            const ticketType = ticket.Close_Code__c;

            const item = (response[ticketType] ??= {
              generalLabel: ticketType || '(Other)',
              values: [0, 0],
            });

            item.values[1] += ticket.Time_to_Repair_Days__c || 0;
            item.values[0] += 1;

            return response;
          },
          {} as Record<string, DataValue[0]>
        )
      );

      for (const item of dataValue) {
        item.values[1] /= item.values[0];
        item.values[1] = Number(item.values[1].toFixed(2));
      }

      dataValue.sort((a, b) => b.values[0] - a.values[0]);

      return {
        ngClass: [],
        loading,
        data: {
          type: ChartType.BARSH,
          id: "YTD NOC cases closed code",
          title: "YTD Closed NOC Tickets by Code",
          hyperlink: "/tickets", // need flags
          value: dataValue,
          groupsLabels: ["Ticket Count", "RTA (Days)"],
          xAxisLabel: "Ticket Count / RTA (Days)",
        } satisfies HorizontalBarsKpi,
      };
    })
  );

  private filterByStatus(ticket: Ticket): boolean {
    return STATES.includes(ticket.Status);
  }
}
