<header class="static-header" *ngIf="header.visible">
  <a routerLink="/">
    <div class="header-logo"></div>
  </a>

  <nav class="header-nav">
    <ul class="menu-items">
      <ng-container *ngFor="let item of nav">
        <ng-template [ngxPermissionsOnly]="item.permissions">
          <li class="nav-item">
            <a
              *ngIf="!item.showOverlay"
              class="label"
              routerLinkActive="active-link"
              [routerLinkActiveOptions]="{ exact: item.exact }"
              routerLink="{{ item.link }}"
              [queryParams]="item.linkParams"
            >
              {{ item.name }}
            </a>
          </li>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="sortedMonitoringList.length">
        <ng-template [ngxPermissionsOnly]="'Monitoring'">
          <li class="nav-item" [matMenuTriggerFor]="monitoringMenu">
            <span>Monitoring</span>
          </li>
          <mat-menu #monitoringMenu="matMenu" xPosition="after">
            <a
              mat-menu-item
              *ngFor="let link of sortedMonitoringList"
              [attr.href]="link.URL__c"
              target="_blank"
              >{{ link.Name }}</a
            >
          </mat-menu>
        </ng-template>
      </ng-container>

      <ng-container *ngFor="let item of nav2">
        <ng-template [ngxPermissionsOnly]="item.permissions">
          <li
            class="nav-item"
            [matMenuTriggerFor]="item.children ? headerSubmenu : undefined"
          >
            <a
              *ngIf="!item.showOverlay"
              routerLinkActive="active-link"
              [routerLink]="item.link"
              [queryParams]="item.linkParams"
              [routerLinkActiveOptions]="{ exact: item.exact }"
              [ngClass]="{
                label: true,
                'active-link': isParentOrChildActive(item)
              }"
              [attr.aria-label]="item.ariaLabel"
            >
              {{ item.name }}
            </a>
          </li>
          <mat-menu #headerSubmenu="matMenu" xPosition="after">
            <ng-container *ngFor="let child of item.children">
              <ng-template [ngxPermissionsOnly]="child.permissions">
                <ng-container *ngIf="child.children?.length; else singleLevel">
                  <a
                    mat-menu-item
                    [matMenuTriggerFor]="subSubMenu"
                    [attr.aria-label]="child.ariaLabel"
                    routerLinkActive="active-link"
                    [routerLink]="child.link"
                    [ngClass]="{ 'active-link': isParentOrChildActive(child) }"
                  >
                    {{ child.name }}
                  </a>
                  <mat-menu #subSubMenu="matMenu" xPosition="after">
                    <ng-container *ngFor="let subChild of child.children">
                      <ng-template [ngxPermissionsOnly]="subChild.permissions">
                        <a
                          mat-menu-item
                          [routerLink]="subChild.link"
                          routerLinkActive="active-link"
                          [attr.aria-label]="subChild.ariaLabel"
                          [ngClass]="{
                            'active-link': isParentOrChildActive(subChild)
                          }"
                        >
                          {{ subChild.name }}
                        </a>
                      </ng-template>
                    </ng-container>
                  </mat-menu>
                </ng-container>
                <ng-template #singleLevel>
                  <a
                    mat-menu-item
                    [routerLink]="child.link"
                    [attr.aria-label]="child.ariaLabel"
                    routerLinkActive="active-link"
                    [ngClass]="{ 'active-link': isParentOrChildActive(child) }"
                  >
                    {{ child.name }}
                  </a>
                </ng-template>
              </ng-template>
            </ng-container>
          </mat-menu>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="(user$ | async)?.EntraUserId__c; else noEntraUserId">
        <li
          class="nav-item"
          [matMenuTriggerFor]="userMenu"
          routerLinkActive="active-link"
        >
          <mat-icon
            aria-hidden="false"
            aria-label="User icon"
            fontIcon="person"
          ></mat-icon>
        </li>

        <mat-menu #userMenu="matMenu" xPosition="after">
          <div mat-menu-item (click)="editProfile()">
            <mat-icon
              aria-hidden="false"
              aria-label="Settings icon"
              fontIcon="settings"
            ></mat-icon>
            Settings
          </div>

          <ng-template ngxPermissionsOnly="Manage Users">
            <a mat-menu-item class="label" routerLink="/manage/users">
              <mat-icon
                aria-hidden="false"
                aria-label="Manage users icon"
                fontIcon="manage_accounts"
              ></mat-icon>
              Manage Users
            </a>
          </ng-template>

          <a mat-menu-item class="label" routerLink="/logout">
            <mat-icon
              aria-hidden="false"
              aria-label="Logout icon"
              fontIcon="logout"
            ></mat-icon>
            Sign Out
          </a>
        </mat-menu>
      </ng-container>

      <ng-template #noEntraUserId>
        <li class="nav-item">
          <a class="label" routerLink="/logout"> Sign Out </a>
        </li>
      </ng-template>

      <app-account-menu></app-account-menu>
    </ul>
  </nav>
</header>
