import { NgModule } from "@angular/core";
import { Routes, RouterModule, ActivatedRouteSnapshot } from "@angular/router";
import { RouterStateSerializer } from "@ngrx/router-store";
import { BrowserUtils } from "@azure/msal-browser";
import { MsalGuard } from "@azure/msal-angular";

/* Components */
import * as fromServiceInventoryComponents from "src/app/inventory/components";
import * as fromOrdersContainers from "src/app/orders/containers";
import * as fromTicketContainers from "src/app/tickets/containers";
import * as fromBillingContainers from "src/app/billing/containers";
import * as fromServiceInventoryContainers from "src/app/inventory/containers";
import * as UnusualNetworkMetricsContainers from "src/app/unusual-network-metrics/containers";
import * as fromOrdersTableComponents from "./orders-table";
import { NocPolicyComponent } from "./noc-policy/noc-policy.component";
import { ManageUsersComponent } from "./manage-users/manage-users.component";
import { CellularUsagePageComponent } from "src/app/cellular-usage/containers";
import { OverallNetworkStatusPageComponent } from "src/app/overall-network-status/containers";
import { AppLayoutComponent } from "./_layout/app-layout/app-layout.component";
import { PublicLayoutComponent } from "./_layout/public-layout/public-layout.component";
import { LoginComponent } from "./core/login/login.component";
import { ResetPasswordComponent } from "./core/reset-password/reset-password.component";
import { ForgotPasswordComponent } from "./core/forgot-password/forgot-password.component";
import { DashboardComponent } from "./dashboard/dashboard-page/dashboard.component";
import { SummaryComponent } from "./summary/summary-page/summary-page.component";
import { OperationsComponent } from "./operations-dashboard/operations-dashboard/operations-page.component";
import { ImpersonationComponent } from "./core/impersonate/impersonation.component";
import { LogoutComponent } from "./core/logout/logout.component";
import { PageNotFoundComponent } from "./core/page-not-found/page-not-found.component";
import { AccessDeniedComponent } from "./core/access-denied/access-denied.component";
import { MetricTableComponent } from "./metric-details/containers";
import { QoePageComponent } from "./quality-of-experience/containers";
import { AccessUnauthorizedComponent } from "./core/access-unauthorized/access-unauthorized.component";

import * as fromGuards from "src/app/store/guards";
import { CustomSerializer } from "./store/reducers/router.reducer";

const ROUTES: Routes = [
  {
    // Portal routes goes here here
    path: "",
    component: AppLayoutComponent,
    children: [
      {
        path: "overall-network-status",
        redirectTo: "analytics/overall-network-status",
        pathMatch: "full",
      },
      {
        path: "analytics/overall-network-status",
        component: OverallNetworkStatusPageComponent,
        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.InvoiceAttachmentsGuard,
          fromGuards.PaymentsGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Nitel IQ Participant",
          },
        },
      },
      {
        path: "cellular-usage",
        redirectTo: "analytics/cellular-usage",
        pathMatch: "full",
      },
      {
        path: "analytics/cellular-usage",
        component: CellularUsagePageComponent,
        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.InvoiceAttachmentsGuard,
          fromGuards.PaymentsGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Nitel IQ Participant",
          },
        },
      },
      {
        path: "unusual-network-metrics",
        redirectTo: "analytics/unusual-network-metrics",
        pathMatch: "full",
      },
      {
        path: "analytics/unusual-network-metrics",
        component: UnusualNetworkMetricsContainers.UnmPageComponent,

        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.InvoiceAttachmentsGuard,
          fromGuards.PaymentsGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Nitel IQ Participant",
          },
        },
      },
      {
        path: "billing",
        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.InvoiceAttachmentsGuard,
          fromGuards.PaymentsGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Invoice and Billing: Read",
          },
        },
        children: [
          { path: "", component: fromBillingContainers.BillingPageComponent },
        ],
      },

      {
        path: "metric-details",
        redirectTo: "analytics/metric-details",
        pathMatch: "full",
      },
      {
        path: "analytics/metric-details",
        component: MetricTableComponent,
        canActivate: [fromGuards.DynamicAuthGuard, fromGuards.UsersGuard],
        data: {
          permissions: {
            only: "Nitel IQ Participant",
          },
        },
      },
      {
        path: "quality-of-experience",
        redirectTo: "analytics/quality-of-experience",
        pathMatch: "full",
      },
      {
        path: "analytics/quality-of-experience",
        component: QoePageComponent,
        canActivate: [fromGuards.DynamicAuthGuard, fromGuards.UsersGuard],
        data: {
          permissions: {
            only: "Nitel IQ Participant",
          },
        },
      },
      {
        path: "services/:serviceId",
        component: fromServiceInventoryComponents.InventoryDetailComponent,
        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.ServicesGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Services",
          },
        },
      },
      {
        path: "services",
        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Services",
          },
        },
        children: [
          {
            path: "",
            component: fromServiceInventoryContainers.InventoryPageComponent,
          },
        ],
      },
      {
        path: "orders/:orderId/line-items/:lineItemId",
        component: fromOrdersContainers.OrdersDetailShellComponent,
        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.OrdersGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Orders",
          },
        },
      },
      {
        path: "orders/:orderId",
        component: fromOrdersContainers.OrdersDetailShellComponent,
        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.OrdersGuard,
          fromGuards.SOFGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Orders",
          },
        },
      },
      {
        path: "orders",
        component: fromOrdersContainers.OrdersShellComponent,
        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Orders",
          },
        },
      },
      {
        path: "orders-table",
        component: fromOrdersTableComponents.OrdersIndex,
        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Orders",
          },
        },
      },
      {
        path: "orders-table/:orderId/view",
        component: fromOrdersTableComponents.OrdersIndex,
        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Orders",
          },
        },
      },
      {
        path: "tickets/:ticketId/comments",
        component: fromTicketContainers.TicketCommentComponent,
        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.TicketsGuard,
        ],
        data: {
          permissions: {
            only: "Ticketing: Read",
          },
        },
      },
      {
        path: "tickets/:ticketId",
        component: fromTicketContainers.TicketsDetailShellComponent,
        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.TicketsGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Ticketing: Read",
          },
        },
      },
      {
        path: "tickets",
        component: fromTicketContainers.TicketsShellComponent,
        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Ticketing: Read",
          },
        },
      },
      {
        path: "summary",
        redirectTo: "reports/orders-summary",
        pathMatch: "full",
      },
      {
        path: "orders-dashboard",
        redirectTo: "repors/orders-summary",
        pathMatch: "full",
      },
      {
        path: "reports/orders-summary",
        component: SummaryComponent,
        canActivate: [
          fromGuards.DynamicAuthGuard,
          fromGuards.UsersGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Orders",
          },
        },
      },
      {
        path: "operations-summary",
        redirectTo: "repors/operations-summary",
        pathMatch: "full",
      },
      {
        path: "reports/operations-summary",
        component: OperationsComponent,
        canActivate: [fromGuards.DynamicAuthGuard, fromGuards.UsersGuard],
        data: {
          permissions: {
            except: [],
          },
        },
      },
      {
        path: "",
        component: DashboardComponent,
        canActivate: [fromGuards.DynamicAuthGuard, fromGuards.UsersGuard],
        data: {
          permissions: {
            except: [],
          },
        },
      },
      {
        path: "noc-policy",
        component: NocPolicyComponent,
        canActivate: [fromGuards.DynamicAuthGuard, fromGuards.UsersGuard],
        data: {
          permissions: {
            except: [],
          },
        },
      },
      {
        path: "manage/users",
        component: ManageUsersComponent,
        canActivate: [
          MsalGuard,
          fromGuards.UsersGuard,
          fromGuards.PermissionsGuard,
        ],
        data: {
          permissions: {
            only: "Manage Users",
          },
        },
      },
      {
        path: "access-denied",
        component: AccessDeniedComponent,
        canActivate: [MsalGuard],
        data: {
          permissions: {
            except: [],
          },
        },
      },
      { path: 'unauthorized', component: AccessUnauthorizedComponent },
    ],
  },
  {
    // Logged out routes go here
    path: "",
    component: PublicLayoutComponent,
    children: [
      { path: "page-not-found", component: PageNotFoundComponent },
      { path: "logout", component: LogoutComponent },
      { path: "login", component: LoginComponent },
      { path: "forgot-password", component: ForgotPasswordComponent },
      { path: "reset-password/:token", component: ResetPasswordComponent },
      { path: "signin_as/:token", component: ImpersonationComponent },
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "**", redirectTo: "login", pathMatch: "full" },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? "enabledNonBlocking"
          : "disabled", // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
  providers: [
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    {
      provide: "externalUrlRedirectResolver",
      useValue: (route: ActivatedRouteSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      },
    },
  ],
})
export class AppRoutingModule {}
