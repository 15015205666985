import { Component, Inject } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from "@angular/material/dialog";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { Store } from "@ngrx/store";

import * as fromStore from "src/app/store/";
import { MSGraphService } from "src/app/services/ms-graph.service";
import { User } from "src/app/models/user.model";
import { MatFormFieldModule } from "@angular/material/form-field";
import { CommonModule } from "@angular/common";
import { MatInputModule } from "@angular/material/input";
import { entraConfig, entraPolicies } from "src/app/msal.config";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/environments/environment";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

interface UserForm {
  id: FormControl<string>;
  entraId: FormControl<string>;
  firstName: FormControl<string>;
  lastName: FormControl<string>;
  email: FormControl<string>;
}

@Component({
  selector: "edit-profile-user-dialog",
  templateUrl: "edit-profile-dialog.component.html",
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CommonModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    MatProgressSpinnerModule
  ],
  styleUrls: ["edit-profile-dialog.component.scss"],
})
export class EditProfileDialog {
  subscription: Subscription;
  userForm: FormGroup;
  user$: Observable<User>;
  userData: User;
  entraUserFlow = entraPolicies.authorities;

  errorMessage: string = null;
  isSubmittingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSubmitting$ = this.isSubmittingSubject.asObservable();
  hasSubmissionErrorSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  hasSubmissionError$ = this.hasSubmissionErrorSubject.asObservable();

  constructor(
    private graphService: MSGraphService,
    public dialogRef: MatDialogRef<EditProfileDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: { firstName: string; lastName: string },
    private store: Store<fromStore.State>,
    private fb: FormBuilder,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(new fromStore.LoadCurrentUser());

    this.user$ = this.store.select(fromStore.getCurrentUser);
    this.subscription = this.user$.subscribe((user) => {
      this.userData = user;
    });

    this.userForm = this.fb.group({
      id: [this.userData?.Id],
      entraId: [this.userData?.EntraUserId__c],
      firstName: [this.userData?.FirstName || "", Validators.required],
      lastName: [this.userData?.LastName || "", Validators.required],
      email: [this.userData?.Email],
    });
  }

  onSave(): void {
    if (!this.userForm.valid) {
      this.errorMessage = "Please fill in all required fields correctly.";
      this.isSubmittingSubject.next(false);
      return;
    }

    this.isSubmittingSubject.next(true);

    this.updateUser();
  }

  updateUser(): void {
    const formData: UserForm = this.userForm.value;

    this.subscription = this.graphService
      .updateEntraUser(formData?.entraId as unknown as string, formData)
      .subscribe(
        () => {
          this.isSubmittingSubject.next(false);
          this.hasSubmissionErrorSubject.next(false);
          this.store.dispatch(new fromStore.LoadCurrentUser());
          this.dialogRef.close(formData);
        },
        (error) => {
          this.hasSubmissionErrorSubject.next(true);
          this.isSubmittingSubject.next(false);
          this.errorMessage =
            error.message || "An error occurred. Please try again.";
        }
      );
  }

  handleEmailOrPasswordChange() {
    this.authService.clearCache();
    const redirectUri = `${environment.siteUrl}/dashboard`;
    const passwordResetUrl = `https://mynitel.b2clogin.com/${entraConfig.tenantId}/oauth2/v2.0/authorize?p=${entraConfig.userFlows.passwordReset}&client_id=${entraConfig.clientId}&nonce=defaultNonce&redirect_uri=${redirectUri}&scope=openid&response_type=id_token&prompt=login`;
    window.location.href = passwordResetUrl;
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
