import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { ApiService } from "src/app/services/api.service";
import { api } from "src/app/models/api-url.model";

@Injectable({
  providedIn: "root",
})
export class MSGraphService {
  constructor(private apiService: ApiService) {}

  getEntraUserProfile(): Observable<any> {
    return this.apiService.get(`${api.usersURL}`);
  }
  
  inviteEntraUser(payload: any): Observable<any> {
    return this.apiService.post(`${api.usersURL}`, payload);
  }

  resendEntraInvitation( email: string): Observable<any> {
    return this.apiService.post(`${api.usersURL}/resend-invitation`, { email })
  }

  updateEntraUser(userEntraId: string, payload: any): Observable<any> {
    return this.apiService.put(`${api.usersURL}/${userEntraId}`, payload);
  }

  deactivateEntraUser(userEntraId: string, sfUserId: string): Observable<any> {
    return this.apiService.put(`${api.usersURL}/${userEntraId}/deactivate`, {sfUserId: sfUserId});
  }

  cancelEntraInvitation(invitationId: string, sfUserId: string): Observable<any> {
    return this.apiService.put(`${api.usersURL}/cancel-invitation/${invitationId}`, {sfUserId: sfUserId});
  }
}
