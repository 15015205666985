import {
  LoadServices,
  LoadServicesFail,
  LoadServicesSuccess,
} from "../../../store";
import { ChartService } from "./chart.service";
import { SingleValueKPI } from "../../types";
import { Injectable } from "@angular/core";
import { map } from "rxjs";
import { ChartType } from "../../types/chart-type";

@Injectable()
export class ActiveServicesChartService extends ChartService<SingleValueKPI> {
  public readonly chart$ = this.chartsDataLoader({
    request: LoadServices,
    success: LoadServicesSuccess,
    failure: LoadServicesFail,
  }).pipe(
    map(({ loading, data }) => {
      let dataValue: number = data?.filter(
        (s) => s.Status__c === "In Service"
      ).length  || 0;

      return {
        ngClass: [],
        loading,
        data: {
          type: ChartType.SINGLE,
          id: "Active Services",
          title: "Active Services",
          value: dataValue,
          linkEnabled: true,
          linkParams: { groupBy: "Status" },
          hyperlink: '/services'
        } satisfies SingleValueKPI,
      };
    })
  );
}
