import { State, getInvoicesLoading, getPaymentsLoading, getLatestInvoiceDate, getLatestPayment, LoadPayments, LoadInvoices } from '../../../../store/';
import { WidgetBillingDisplayComponent } from '../../../../shared/components/widget-displays/widget-billing-display/widget-billing-display.component';
import { SharedModule } from '../../../../shared/shared.module';
import { Payment } from '../../../../models/payment.model';

import { ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';

@Component({
  standalone: true,
  selector: 'ons-widget-billing',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    WidgetBillingDisplayComponent,
    SharedModule,
  ],
  template: `
    <app-widget-billing-display
      class="dimensions-stretch"
      [hyperlink]="'/billing'"
      [navigateOnClick]="navigateOnClick"
      [loadingInvoices]="isLoadingInvoices$()"
      [loadingPayments]="isLoadingPayments$()"

      [invoiceDate]="invoiceDate$()"
      [payment.transactionDate]="payment$()?.Transaction_Date__c"
      [payment.amount]="payment$()?.Transaction_Amount__c"
    ></app-widget-billing-display>
  `,
})
export class WidgetBillingComponent implements OnInit {
  @Input() navigateOnClick: boolean = false;

  private readonly store$ = inject(Store<State>);

  public readonly isLoadingInvoices$ = toSignal<boolean>(this.store$.select(getInvoicesLoading));
  public readonly isLoadingPayments$ = toSignal<boolean>(this.store$.select(getPaymentsLoading));
  public readonly invoiceDate$ = toSignal<Date | null>(this.store$.select(getLatestInvoiceDate));
  public readonly payment$ = toSignal<Payment | any>(this.store$.select(getLatestPayment));

  ngOnInit(): void {
    this.store$.dispatch(new LoadPayments());
    this.store$.dispatch(new LoadInvoices());
  }

}
