<section id="simple-container" class="simple-container">

  <div>
    <p>Summary Dashboard Report</p>
    <p>{{ today | date: 'MM/dd/yy' }}</p>
  </div>

  <ng-container *ngFor="let displayGroup of displayCharts">
    <div class="simple-flex">
      <ng-container *ngFor="let displayChart of displayGroup">
        <ng-container *ngIf="displayChart() as chart" [ngSwitch]="chart.data.type">
          <app-gauge-value
            *ngSwitchCase="ChartType.GAUGE"
            class="stat__item"
            [ngClass]="chart.ngClass"
            [data]="chart.data"
          ></app-gauge-value>
          <app-single-value
            *ngSwitchCase="ChartType.SINGLE"
            class="stat__item"
            [ngClass]="chart.ngClass"
            [data]="chart.data"
          ></app-single-value>
          <app-line-values
            *ngSwitchCase="ChartType.LINE"
            class="stat__item"
            [ngClass]="chart.ngClass"
            [data]="chart.data"
            [loading]="chart.loading"
          ></app-line-values>
          <app-horizontal-bars
            *ngSwitchCase="ChartType.BARSH"
            class="stat__item"
            [ngClass]="chart.ngClass"
            [data]="chart.data"
            [loading]="chart.loading"
          ></app-horizontal-bars>
          <app-vertical-bars
            *ngSwitchCase="ChartType.BARSV"
            class="stat__item"
            [ngClass]="chart.ngClass"
            [data]="chart.data"
            [loading]="chart.loading"
          ></app-vertical-bars>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>

</section>