import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromStore from "../../store/";

@Component({
  selector: "app-contact-admin",
  template: `
    <section class="contact-admin">
      <div class="widget">
        <h1 class="title">Unauthorized Access</h1>
        <p class="subtitle">
          You currently do not have access permissions in MyNitel. Please reach
          out to your
          {{ (loggedInUser$ | async)?.SuperAdmins?.length ? 'administrator' : 'account manager' }}

          for assistance:
        </p>
        <div class="contact-info" *ngIf="!(loggedInUser$ | async)?.SuperAdmins?.length">
          <p class="contact-name">
            {{ (loggedInUser$ | async)?.Account?.Account_Manager__r?.Name || "N/A" }}
          </p>
          <p>
            <strong>Phone:</strong>
            {{ (loggedInUser$ | async)?.Account?.Account_Manager__r?.Phone || "N/A" }}
          </p>
          <p class="contact-email">
            <strong>Email:</strong>
            <a
              [href]="
                'mailto:' +
                ((loggedInUser$ | async)?.Account?.Account_Manager__r?.Email || '')
              "
            >
              {{ (loggedInUser$ | async)?.Account?.Account_Manager__r?.Email || "N/A" }}
            </a>
          </p>
        </div>

        <div class="contact-info" *ngIf="(loggedInUser$ | async)?.SuperAdmins?.length">
          <ng-container *ngFor="let admin of (loggedInUser$ | async)?.SuperAdmins">
            <p class="contact-name">
              {{ admin?.Name || "N/A" }}
            </p>
            <p>
              <strong>Phone:</strong>
              {{ admin?.Phone || "N/A" }}
            </p>
            <p class="contact-email">
              <strong>Email:</strong>
              <a [href]="'mailto:' + (admin?.Email || '')">
                {{ admin?.Email || "N/A" }}
              </a>
            </p>
          </ng-container>
        </div>
      </div>
    </section>
  `,
  styleUrls: ["access-denied.component.scss"],
})
export class AccessDeniedComponent implements OnInit {
  loggedInUser$ = this.store.select(fromStore.getCurrentUser);

  constructor(private store: Store<fromStore.State>) {}

  ngOnInit() {
    this.store.dispatch(new fromStore.LoadCurrentUser());
  }
}
