import { Component, Input } from '@angular/core';
import { categoryColor } from '../../functions';
import { CommonModule } from '@angular/common';

export interface LegendCategory {
  label: string;
  count: number;
}

@Component({
  standalone: true,
  selector: 'app-leaflet-legend',
  styleUrls: ['./leaflet-legend.component.scss'],
  imports: [CommonModule],
  template: `
    <div class="legend-container">
      <strong>Legend</strong>
      <ng-container *ngFor="let cat of categories">
        <div class="legend-item">
          <i class="legend-icon" [style.background-color]="getColor(cat.label)">
            {{ cat.count }}
          </i>
          {{ cat.label }}
        </div>
      </ng-container>
    </div>
  `,
})
export class LeafletLegendComponent {
  @Input() categories: LegendCategory[] = [];

  getColor(label: string): string {
    return categoryColor(label);
  }
}
