import { getTotalOpenTickets, LOAD_TOTAL_OPEN_TICKETS, LOAD_TOTAL_OPEN_TICKETS_FAIL, LOAD_TOTAL_OPEN_TICKETS_SUCCESS, LoadTotalOpenTickets, State } from '../../../../store/';
import { WidgetTicketsDisplayComponent } from '../../../../shared/components/widget-displays/widget-tickets-display/widget-tickets-display.component';
import { SharedModule } from '../../../../shared/shared.module';

import { ChangeDetectionStrategy, Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Actions, ofType } from '@ngrx/effects';
import { map, startWith } from 'rxjs';
import { Store } from '@ngrx/store';

@Component({
  standalone: true,
  selector: 'ons-widget-tickets',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    WidgetTicketsDisplayComponent,
    SharedModule,
  ],
  template: `
    <app-widget-tickets-display
      class="dimensions-stretch"
      [hyperlink]="'/tickets'"
      [loading]="isLoading$()"
      [navigateOnClick]="navigateOnClick"
      [ticketCount]="totalOpenTickets$()"
    ></app-widget-tickets-display>
  `,
})
export class WidgetTicketsComponent implements OnInit {
  @Input() navigateOnClick: boolean = false;

  private readonly actions$ = inject(Actions);
  private readonly store$ = inject(Store<State>);

  public readonly totalOpenTickets$ = toSignal<number>(this.store$.select(getTotalOpenTickets));
  public readonly isLoading$ = toSignal<boolean>(
    this.actions$.pipe(
      ofType(
        LOAD_TOTAL_OPEN_TICKETS,
        LOAD_TOTAL_OPEN_TICKETS_FAIL,
        LOAD_TOTAL_OPEN_TICKETS_SUCCESS,
      ),
      map(action => {
        switch (action.type) {
          case LOAD_TOTAL_OPEN_TICKETS_SUCCESS:
          case LOAD_TOTAL_OPEN_TICKETS_FAIL:
            return false;
          default:
            return true;
        }
      }),
      startWith(false),
    ),
  );

  ngOnInit() {
    this.store$.dispatch(new LoadTotalOpenTickets());
  }

}
