import { Component, Input } from '@angular/core';
import { categoryColor } from '../../functions';
import { CommonModule } from '@angular/common';

export interface LeafletMarker {
  device_id: string;
  latitude: string;
  longitude: string;
  name: string;
  record_type: string;
  source: string;
  status: string;
  elements?: ElementData[];
}

export interface ElementData {
  Name: string;
  RecordType?: { Name?: string };
  Vendor_Name__c?: string;
  Vendor_Circuit_Id__c?: string;
}

@Component({
  standalone: true,
  selector: 'app-leaflet-popup-content',
  styleUrls: ['./leaflet-popup-content.component.scss'],
  imports: [CommonModule],
  template: `
    <div class="dropdown" [class.as-tooltip]="asTooltip">
      <div class="dropdown-content">

        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th>Service</th>
                <th>Record Type</th>
                <th>Source</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Service">
                  {{ item.name }}
                </td>
                <td data-label="Record Type">
                  {{ item.record_type }}
                </td>
                <td data-label="Source">
                  {{ item.source }}
                </td>
                <td data-label="Status">
                  <div class="flex items-center text-ellipsis whitespace-nowrap overflow-hidden">
                    <svg width="22" height="22" style="margin-right: 5px;">
                      <circle r="10" cx="50%" cy="50%" stroke="black" stroke-width="1" [attr.fill]="color"></circle>
                    </svg>
                    {{ item.status }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="!asTooltip" class="table-container">
          <div class="table-v-scroll">
            <table>
              <thead>
                <tr>
                  <th>Element</th>
                  <th>Record Type</th>
                  <th>Vendor</th>
                  <th>Vendor Circuit Id</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let element of item.elements || []">
                  <td data-label="Element">
                    <span>
                      {{ element.Name }}
                    </span>
                  </td>
                  <td data-label="Record Type">
                    <span>
                      {{ element.RecordType?.Name || '' }}
                    </span>
                  </td>
                  <td data-label="Vendor">
                    <span>
                      {{ element.Vendor_Name__c || '' }}
                    </span>
                  </td>
                  <td data-label="Vendor Circuit Id">
                    <span>
                      {{ element.Vendor_Circuit_Id__c || '' }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  `
})
export class LeafletPopupContentComponent {
  @Input() asTooltip = false;
  @Input({ alias: 'item' }) set _item(value: LeafletMarker) {
    this.color = categoryColor(value.status);
    this.item = value;
  }

  public item: LeafletMarker;
  public color: string;

}
