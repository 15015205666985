import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";

import {
  catchError,
  Observable,
  Subscription,
  take,
  throwError,
  timer,
} from "rxjs";
import { entraConfig } from "src/app/msal.config";
import { AuthService } from "src/app/services/auth.service";

@Component({
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, AfterViewInit {
  title = "MyNitel: Log In";

  passwordChanged: boolean = false;
  sessionExpired: boolean = false;
  loggedOut: boolean = false;
  loginFormGroup: UntypedFormGroup;
  usernameValid: boolean = false;
  showRegistrationMessage: boolean = false;

  isLoading: boolean = false;
  errorMessage: string;
  isLoading$: Observable<boolean>;
  errorMessage$: Observable<string>;
  subscription: Subscription;

  messages = {
    usernameInput: "",
    emailInput: "",
    passwordInput: "",
    passwordChanged: "Password changed!",
    sessionExpired: "Session expired. We've logged you out of your account.",
    loggedOut: "You have successfully logged out.",
    registration:
      "Your registration was successful! Please log in to continue.",
  };

  currentStep: "username" | "password" = "username";
  usernameFormGroup: FormGroup;
  userEmail: string;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private msalService: MsalService,
    private changeDetectorRef: ChangeDetectorRef,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment?.includes("id_token")) {
        this.showRegistrationMessage = true;
      }
    });
    this.passwordChanged =
      this.route.snapshot.queryParamMap.get("passwordChanged") === "true";
    this.sessionExpired =
      this.route.snapshot.queryParamMap.get("logout") === "expired";
    this.loggedOut =
      this.route.snapshot.queryParamMap.get("logout") === "manual";
    if (this.authService.isLoggedIn) {
      this.router.navigate(["/"]);
    }

    this.usernameFormGroup = this.fb.group({
      username: ["", [Validators.required, Validators.email]], // Allow both emails and usernames.
    });

    this.loginFormGroup = new UntypedFormGroup({
      username: new UntypedFormControl(
        this.usernameFormGroup.controls.username.value,
        [Validators.required]
      ),
      password: new UntypedFormControl("", [Validators.required]),
      toggle: new UntypedFormControl(true),
    });
  }

  ngAfterViewInit() {
    this.renderer.selectRootElement("#usernameId").focus();
    this.clearErrorMessages();
  }

  login(): void {
    if (this.loginFormGroup.valid) {
      const uid = this.loginFormGroup.controls.username.value;
      const password = this.loginFormGroup.controls.password.value;
      const rememberMe = this.loginFormGroup.controls.toggle.value;
      const regex = /(\s*$)|(^\s*)/g;
      const cleanUsername = uid.replace(regex, "");

      this.isLoading = true;

      this.authService
        .login(cleanUsername, password, rememberMe)
        .pipe(
          take(1),
          catchError((error: HttpErrorResponse) => {
            this.isLoading = false;
            const invalidUserOrPasswordMessage =
              "Incorrect username or password.";

            if (error.status === 401) {
              this.messages.passwordInput = invalidUserOrPasswordMessage;
            } else if (error.status === 404) {
              this.messages.usernameInput = invalidUserOrPasswordMessage;
            } else {
              this.errorMessage =
                "We can't log you in right now. Please try again later.";
            }
            this.clearErrorMessages();
            this.changeDetectorRef.detectChanges();
            return throwError(() => error); // Re-throw the error if needed
          })
        )
        .subscribe((data) => {
          this.isLoading = false;

          if (!data || !data.salesforce_auth_token) {
            this.messages.usernameInput = data.usernameError || "";
            this.messages.passwordInput = data.passwordError || "";
            this.errorMessage = data.error || "";
            this.changeDetectorRef.detectChanges();
            return;
          }

          if (
            data.user?.EntraUserId__c &&
            data.user?.Account?.Has_Entra_ID__c
          ) {
            this.authService.clearCache();
            this.userEmail = data.user.Email;
            this.loginEntraId();
          }

          this.changeDetectorRef.detectChanges();
        });
    } else {
      if (!this.loginFormGroup.controls.username.value) {
        this.messages.usernameInput = "Please enter a username.";
      } else {
        this.messages.usernameInput = "";
      }
      this.messages.passwordInput = this.loginFormGroup.controls.password.value
        ? ""
        : "Please enter a password.";
    }
    this.clearErrorMessages();
  }

  validateUsername() {
    const username = this.usernameFormGroup.controls.username.value;
    const regex = /(\s*$)|(^\s*)/g;
    const cleanUsername = username.replace(regex, "");
    this.isLoading = true;
    this.messages.usernameInput = "";

    this.authService
      .validateUsername(cleanUsername)
      .pipe(
        take(1),
        catchError((error: HttpErrorResponse) => {
          this.isLoading = false;
          this.messages.usernameInput =
            error.status === 404
              ? "Username or email not found. Please check for typos and try again."
              : "Unable to verify username or email. Please try again later.";

          this.changeDetectorRef.detectChanges();
          this.clearErrorMessages();
          return throwError(() => error);
        })
      )
      .subscribe((data) => {
        this.isLoading = false;
        this.userEmail = this.usernameFormGroup.controls.username.value;

        if (!data.isUsernameValid) {
          this.messages.usernameInput =
            data.usernameError || "Username is not valid";
        } else if (data.hasEntra) {
          this.userEmail = data.email;
          this.loginEntraId();
        } else {
          this.loginFormGroup.patchValue({
            username: this.usernameFormGroup.controls.username.value,
          });
          this.currentStep = "password";
          this.changeDetectorRef.detectChanges();
          this.clearErrorMessages();
          this.renderer.selectRootElement("#passwordId").focus();
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  private loginEntraId() {
    this.msalService.loginRedirect({
      extraQueryParameters: {
        login_hint: this.userEmail,
      },
      scopes: entraConfig.appScopes.nitelApi.scopes,
    });
  }

  private clearErrorMessages(): void {
    timer(5000)
      .pipe(take(1))
      .subscribe(() => {
        this.errorMessage = null;
        this.passwordChanged = false;
        this.sessionExpired = false;
        this.loggedOut = false;
        this.showRegistrationMessage = false;
      });

    this.changeDetectorRef.detectChanges();
  }
}
