import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-highlighted-network-measurements',
  templateUrl: './highlighted-network-measurements.component.html',
  styleUrls: ['./highlighted-network-measurements.component.scss']
})
export class HighlightedNetworkMeasurementsComponent {
  @Input() category: string = 'warning';  // Input property
  @Input() dataUtilization: string = '0';  // Input property
  @Input() latency: string = '0';  // Input property
  @Input() jitter: string = '0';  // Input property
  @Input() packetLost: string = '0';  // Input property
}
