<section class="login">
  <div class="loading" *ngIf="isLoading">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
  <div class="login-form-wrapper">
    <div class="logo"></div>

    <div *ngIf="displayForm" class="forgot-password-form">
      <h1>Forgot your Password?</h1>
      <div class="message">
        Enter the username registered with Nitel and a link will be sent to you to reset your password.
      </div>
      <div class="split-row login-form">
        <form
          [formGroup]="forgotPasswordFormGroup"
          novalidate
          (ngSubmit)="submitForgotPasswordForm()"
          autocomplete="off"
        >
          <div>
            <label class="input-wrapper">
              <span>Username</span>
              <input
                id="username"
                class="form-control"
                type="text"
                placeholder="Enter Username"
                required
                name="username"
                formControlName="username"
                [ngClass]="{ 'is-invalid': !!errorMessage }"
              />
            </label>
            <div class="alert alert-danger" [ngClass]="{ expanded: !!errorMessage }">
              <div class="custom-icon inline_error">inline error</div>
              {{ errorMessage }}
            </div>
            <div class="button-wrapper">
              <button class="button" type="submit"  [disabled]="isLoading">
                <span>SEND</span>
              </button>
            </div>
            <div class="help-wrapper">Need Help? Call us at <a href="tel:8884502100">888-450-2100</a></div>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="!displayForm" class="result-message">
      <div class="message">An email has been sent with a link to reset your password.</div>
      <div class="button-wrapper">
        <a routerLink="/login" title="Login to MyNitel">
          <button class="button" type="submit">
            <span>OK</span>
          </button>
        </a>
      </div>
    </div>
  </div>
</section>
