<section class="widget-element widget-summary" [class.loading]="loading">

  <ng-container *ngIf="loading">
    <div class="widget-element-loading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </ng-container>

  <div class="widget-element-header widget-summary-header">
    <h1 class="widget-element-title widget-summary-user">
      Welcome
      <ng-container *ngIf="userFirstName">
        {{ userFirstName }}
        {{ userLastName }}
      </ng-container>
    </h1>

    <ng-container>
      <ng-container *ngIf="userTitle">
        <div class="contact-title">
          {{ userTitle }}
        </div>
      </ng-container>
      <ng-container *ngIf="userPhone || userEmail">
        <div class="contact-info">
          <ng-container *ngIf="userPhone">
            {{ userPhone | phoneNumber: 'US' }} |
          </ng-container>
          {{ userEmail }}
        </div>
      </ng-container>
    </ng-container>
  </div>

  <ng-container *ngIf="!loading && userAccountName">
    <div class="widget-element-content widget-summary-content">
      <div class="billing-address">
        <div class="company-title">
          {{ userAccountName }}
        </div>
        <div class="address-label">
          Billing Address
        </div>
        <div class="address-content">
          <div class="custom-icon location_dark">
            location icon
          </div>
          <pre>{{ userBillingAddress }}</pre>
        </div>
      </div>
    </div>
  </ng-container>

</section>
