import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromStore from 'src/app/store/';
import { Node } from 'src/app/models/node.model';

@Component({
  selector: 'app-widget-monitoring',
  templateUrl: './widget-monitoring.component.html',
  styleUrls: ['../widgets.scss', './widget-monitoring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetMonitoringComponent implements OnInit {
  downNodes$: Observable<Node[]> = this.store.select(fromStore.getAllDownNodes);
  isLoading$: Observable<boolean> = this.store.select(fromStore.getDownNodesLoading);
  isLoaded$: Observable<boolean> = this.store.select(fromStore.getDownNodesLoaded);
  orionURL$: Observable<string> = this.store.select(fromStore.getOrionURL);
  errorMessage$: Observable<string> = this.store.select(fromStore.getDownNodesError);

  constructor(private store: Store<fromStore.State>) {}

  ngOnInit() {
    this.store.dispatch(new fromStore.LoadDownNodes());
  }
}
