<section class="widget-element widget-number justify-center" [class.clickable]="navigateOnClick" [class.loading]="loading">
  <ng-container *ngIf="loading">
    <div class="widget-element-loading">
      <mat-progress-bar mode="indeterminate" />
    </div>
  </ng-container>

  <div class="widget-element-header widget-number-header" *ngIf="title.length">
    <ng-content select="[titlePrefixIcon]"></ng-content>
    <h1 class="widget-element-title">{{ title }}</h1>
  </div>

  <div class="widget-element-content widget-number-content">
    <div class="record-count">
      <div class="record-number number">{{ number || 0 }}</div>
      <div class="record-description description">
        <div class="record-status upper">{{ status }}</div>
        <div class="record-label">{{ label }}</div>
      </div>
    </div>
    <ng-content></ng-content>
  </div>

  <div class="widget_cta">
    <a [routerLink]="hyperlink">{{ ctaText }}</a>
  </div>
</section>
