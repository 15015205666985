import { createSelector } from '@ngrx/store';
import * as fromReducer from '../reducers/users.reducer';

export const getCurrentUser = createSelector(fromReducer.getUserFeatureState, fromReducer.getCurrentUser);

export const getCurrentUserLoading = createSelector(fromReducer.getUserFeatureState, fromReducer.getUserLoading);

export const getCurrentUserLoaded = createSelector(fromReducer.getUserFeatureState, fromReducer.getUserLoaded);

export const getError = createSelector(fromReducer.getUserFeatureState, fromReducer.getUserError);

export const getOrionURL = createSelector(getCurrentUser, (user) => {
  let orionURL = '';
  if (user?.Orion_UserID__c && user?.Orion_Password__c) {
    orionURL = `http://vigil.nitelusa.com/Orion//Login.aspx?AccountID=${user.Orion_UserID__c}&Password=${user.Orion_Password__c}`;
  }
  return orionURL;
});
