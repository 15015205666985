import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  get(path: string, params: any = {}): Observable<any> {
    return this.http
      .get(`${environment.apiUrl}${path}`, params)
      .pipe(catchError((error) => this.handleLogout(error)));
  }

  post(path: string, body: any = {}): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}${path}`, body)
      .pipe(catchError((error) => this.handleLogout(error)));
  }

  postFormData(path: string, formData: any): Observable<any> {
    return this.http
      .post(`${environment.apiUrl}${path}`, formData)
      .pipe(catchError((error) => this.handleLogout(error)));
  }

  put(path: string, body: any = {}): Observable<any> {
    return this.http
      .put(`${environment.apiUrl}${path}`, body)
      .pipe(catchError((error) => this.handleLogout(error)));
  }

  handleLogout(error: HttpErrorResponse): Observable<never> {
    const { status, error: err, message } = error;
  
    // Handle logout if status is 401 (Unauthorized) or 0 (Network Error)
    if (status === 401 || status === 0) {
      this.authService.signout("expired");
    }
  
    return throwError(() => err || message || "Unknown error occurred");
  }  
}
