
import { DynamicAuthGuard } from './dynamic-auth.guard';
import { ContactsGuard } from './contacts.guard';
import { InvoiceAttachmentsGuard } from './invoice-attachments.guard';
import { InvoicesGuard } from './invoices.guard';
import { NodesDownGuard } from './nodes-down.guard';
import { OrdersGuard } from './orders.guard';
import { PaymentsGuard } from './payments.guard';
import { PermissionsGuard } from './permissions.guard';
import { PortalRoleGuard } from './portal-roles.guard';
import { ServicesGuard } from './services.guard';
import { SOFGuard } from './sof.guard';
import { TicketExistsGuards } from './ticket-exists.guard';
import { TicketsGuard } from './tickets.guard';
import { UsersGuard } from './users.guard';

export const guards: any[] = [
  DynamicAuthGuard,
  ContactsGuard,
  InvoiceAttachmentsGuard,
  InvoicesGuard,
  NodesDownGuard,
  OrdersGuard,
  PaymentsGuard,
  PermissionsGuard,
  PortalRoleGuard,
  ServicesGuard,
  SOFGuard,
  TicketsGuard,
  TicketExistsGuards,
  UsersGuard
];

export * from './dynamic-auth.guard';
export * from './contacts.guard';
export * from './invoice-attachments.guard';
export * from './invoices.guard';
export * from './nodes-down.guard';
export * from './orders.guard';
export * from './payments.guard';
export * from './permissions.guard';
export * from './portal-roles.guard';
export * from './services.guard';
export * from './sof.guard';
export * from './ticket-exists.guard';
export * from './tickets.guard';
export * from './users.guard';
