import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MatGridList } from "@angular/material/grid-list";
import { SynapseService } from "src/app/services/synapse.service";
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs/operators";
import { Subject, Observable } from "rxjs";
import * as fromStore from "src/app/store/";
import { AuthService } from "src/app/services/auth.service";
import { widgetTypes } from "src/app/dashboard/widget-types";

// Define interfaces for better type safety
interface LeafletMarker {
  device_id: string;
  latitude: string;
  longitude: string;
  name: string;
  record_type: string;
  source: string;
  status: string;
  elements?: ElementData[];
}

interface ElementData {
  Name: string;
  RecordType?: { Name?: string };
  Vendor_Name__c?: string;
  Vendor_Circuit_Id__c?: string;
}

interface DeviceData {
  name: string;
  latitude: string;
  longitude: string;
  record_type: string;
  source: string;
  status: string;
}

@Component({
  selector: "app-overall-network-status-page",
  templateUrl: "./overall-network-status-page.component.html",
  styleUrls: ["./overall-network-status-page.component.scss"],
})
export class OverallNetworkStatusPageComponent implements OnInit, OnDestroy {
  @ViewChild(MatGridList, { static: true }) matGridList!: MatGridList;

  widgetTypes = widgetTypes;
  browserSize$: Observable<string> = this.store.select(fromStore.getBrowserSizeName);
  browserSize: string = "";

  accountName: string = "";
  selectedTime: string = "";
  showMockData = false;

  aggregatedData: any = {};
  tableData: DeviceData[] = [];
  markers: LeafletMarker[] = [];
  categories: { label: string; count: number }[] = [];
  loading = false;

  private destroy$ = new Subject<void>();

  constructor(
    private synapseService: SynapseService,
    private cdr: ChangeDetectorRef,
    private store: Store<fromStore.State>,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    // Subscribe to browser size changes
    this.browserSize$.pipe(takeUntil(this.destroy$)).subscribe((name) => {
      this.browserSize = name ?? "";
    });

    this.getUserInfoAndLoadData();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleMockData(event: boolean): void {
    this.showMockData = event;
    this.getUserInfoAndLoadData();
  }

  private getUserInfoAndLoadData(): void {
    if (this.accountName) {
      this.loadNitelIQData();
      return;
    }

    this.store
      .select(fromStore.getCurrentUser)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          if (data?.Account?.Name) {
            this.accountName = data.Account.Name;
            this.loadNitelIQData();
          }
        },
        error: (err) => console.error("Error fetching user data:", err),
      });
  }

  private loadNitelIQData(): void {
    this.resetState();

    const params = {
      accountName: this.accountName,
      showMockData: this.showMockData,
      page: 1,
    };

    this.synapseService
      .getDeviceLocations(params)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (data) => {
          this.aggregatedData = data || {};
          if (this.aggregatedData?.sites) {
            this.tableData = this.aggregatedData.sites;
          }

          this.setMarkers(this.aggregatedData["elements"] || []);
          this.setCategories();

          this.loading = false;
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.error("Error fetching device locations:", err);
          this.loading = false;
          this.cdr.detectChanges();
        },
      });
  }

  private resetState(): void {
    this.markers = [];
    this.tableData = [];
    this.aggregatedData = {};
    this.loading = true;
  }

  private setMarkers(elements: any[]): void {
    const elementsMap = new Map(
      elements.map((el) => [el.Name, el.Elements__r?.records || []])
    );

    this.markers = this.tableData.map((item) => ({
      device_id: item.latitude,
      latitude: item.latitude,
      longitude: item.longitude,
      name: item.name,
      record_type: item.record_type,
      source: item.source,
      status: item.status,
      elements: elementsMap.get(item.name) || [],
    }));
  }

  private setCategories(): void {
    const statusList = ["UP", "IMPAIRED", "ON BACKUP", "DOWN"];
    const statusCountMap = statusList.reduce((acc, status) => ({ ...acc, [status]: 0 }), {});

    this.tableData.forEach((device) => {
      if (statusCountMap.hasOwnProperty(device.status)) {
        statusCountMap[device.status]++;
      }
    });

    this.categories = statusList.map((status) => ({
      label: status,
      count: statusCountMap[status],
    }));
  }
}
