import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { StoreModule } from '@ngrx/store';

import { SharedModule } from '../shared/shared.module';

import * as fromContainers from './containers';
import * as fromComponents from './components';
import * as fromUsersReducer from '../store/reducers/users.reducer';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgChartsModule } from 'ng2-charts';

import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


import { MatDatepickerModule } from "@angular/material/datepicker";
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';

import { NgApexchartsModule } from "ng-apexcharts";
import { MatInputModule } from '@angular/material/input';

const qoePageRoutes: Routes = [
  {
    path: '',
    component: fromContainers.QoePageComponent,
    canActivate: [NgxPermissionsGuard]
  }
];

@NgModule({
  imports: [
    SharedModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatFormFieldModule,
    NgChartsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    ScrollingModule,
    RouterModule.forChild(qoePageRoutes),
    StoreModule.forFeature('user', fromUsersReducer.reducer),
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    NgApexchartsModule,
    MatGridListModule,
    MatToolbarModule,
    NgxMatTimepickerModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatInputModule
  ],
  declarations: [...fromComponents.components, ...fromContainers.containers],
  exports: [...fromComponents.components, ...fromContainers.containers],
})
export class qualityOfExperienceModule {}
