<section class="widget-element widget-billing" [class.clickable]="navigateOnClick" [class.loading]="loadingInvoices && loadingPayments">

  <ng-container *ngIf="loadingInvoices && loadingPayments">
    <div class="widget-element-loading">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </ng-container>

  <div class="widget-element-header widget-billing-header">
    <div class="widget-element-icon custom-icon billing_dollar"></div>
    <h1 class="widget-element-title widget-billing-title">Billing</h1>
  </div>

  <div class="widget-billing-content">

    <ng-container *ngIf="invoiceDate; else noInvoices">
      <div class="invoice-container">
        <img class="invoice-image" alt="invoice icon" src="/assets/images/img_widget_invoice_std@2x.png" />
        <div class="invoice-detail">
          <h2>Invoice</h2>
          <div class="posted-date">
            posted {{ invoiceDate | date: 'MMM d, y.' }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noInvoices>
      <div class="invoice-container">
        <img class="invoice-image" alt="invoice icon" src="/assets/images/img_widget_invoice_empty@2x.png" />
        <div class="invoice-detail">
          <h2 class="empty">No Invoices.</h2>
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="paymentTransactionDate; else noPayments">
      <div class="payment-container">
        <h2>Payment</h2>
        <div class="posted-date">
          posted {{ paymentTransactionDate | date: 'MMM d, y.' }}
        </div>
        <div class="payment-value">
          <span>$</span>
          {{ paymentDollars }}
          <span>{{ paymentCents }}</span>
        </div>
      </div>
    </ng-container>
    <ng-template #noPayments>
      <div class="payment-container">
        <h2 class="empty">No Payment Yet.</h2>
        <div class="payment-value"><span>$</span>&nbsp;- - -</div>
      </div>
    </ng-template>
  </div>

  <div class="widget_cta">
    <a [routerLink]="hyperlink">View Billing</a>
  </div>

</section>
