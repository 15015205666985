import { State, LoadOrders, getActiveOrders, getOrdersLoading, getTotalActiveLocations } from 'src/app/store';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-widget-orders',
  templateUrl: './widget-orders.component.html',
  styleUrls: ['../widgets.scss', './widget-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetOrdersComponent implements OnInit {
  private readonly store = inject(Store<State>);

  public readonly totalOpenOrders$ = this.store.select(getActiveOrders);
  public readonly totalActiveLocations$ = this.store.select(getTotalActiveLocations);
  public readonly isLoading$ = this.store.select(getOrdersLoading);

  ngOnInit() {
    this.store.dispatch(new LoadOrders());
  }

}
