import { Injectable } from "@angular/core";
import { Observable, take } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { Store } from "@ngrx/store";

import { api } from "src/app/models/api-url.model";
import { Node } from "src/app/models/node.model";
import { ApiService } from "./api.service";
import { AuthService } from "./auth.service";
import * as fromStore from "../store/";

@Injectable({
  providedIn: "root",
})
export class NodeService {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private store: Store<fromStore.State>
  ) {}

  getDownNodes(): Observable<Node[]> {
    let params = {};
    this.store
      .select(fromStore.getCurrentUser)
      .pipe(take(1))
      .subscribe((currentUser) => {
        if (currentUser) {
          params = {
            Orion_UserID__c: currentUser?.Orion_UserID__c,
            Orion_Password__c: currentUser?.Orion_Password__c,
          };
        }
      });

    let httpParams = new HttpParams();
    Object.keys(params).forEach((key) => {
      httpParams = httpParams.set(key, params[key]);
    });
    return this.apiService.get(api.nodesDownURL, { params: httpParams });
  }
}
