import { createSelector } from "@ngrx/store";
import * as fromReducer from "../reducers/portal-roles.reducer";

// export const getPortalRoleEntities = createSelector(
//   fromReducer.getPortalRoleFeatureState,
//   fromReducer.getPortalRoleEntities
// );
export const getPortalRoleEntities = createSelector(
  fromReducer.getPortalRoleFeatureState,
  (state) => state ? state.entities : {} // Handle undefined state safely
);

export const getPortalRoles = createSelector(
  getPortalRoleEntities,
  (entities) => {
    return Object.keys(entities).map((id) => entities[id]);
  }
);

export const getPortalRoleLoaded = createSelector(
  fromReducer.getPortalRoleFeatureState,
  fromReducer.getPortalRoleLoaded
);

export const getPortalRoleLoading = createSelector(
  fromReducer.getPortalRoleFeatureState,
  fromReducer.getPortalRoleLoading
);