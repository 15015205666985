import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";

import * as fromGuards from "../store/guards";
import { effects } from "../store";
import { SharedModule } from "../shared/shared.module";
import { ManageUsersComponent } from "./manage-users.component";
import * as fromPortalRoleReducer from "../store/reducers/portal-roles.reducer";
import * as fromUsersReducer from "../store/reducers/users.reducer";
import { CreateEditUserDialog } from "./dialogs/create-edit-user-dialog.component";
import { ConfirmRemoveUserDialog } from "./dialogs/confirm-remove-user-dialog.component";
import { MatSortModule } from "@angular/material/sort";

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    ManageUsersComponent,
    CreateEditUserDialog,
    MatSortModule,
    ConfirmRemoveUserDialog,
    StoreModule.forFeature("user", fromUsersReducer.reducer),
    StoreModule.forFeature("portalRoles", fromPortalRoleReducer.reducer),
    EffectsModule.forFeature(effects),
  ],
  declarations: [],
  providers: [...fromGuards.guards],
})
export class ManageUsersModule {}
