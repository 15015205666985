<section class="parent-container">
  <div class="mx-5 mb-10">
    <div class="container-top">
      <div class="container-title">
        <mat-toolbar>
          <h1>Quality of Experience</h1>
          <div class="mock-data-toggle">
            <mat-slide-toggle
              [(ngModel)]="showMockData"
              (ngModelChange)="toggleMockData($event)"
              color="primary"
            >
              {{
                showMockData ? "Switch to Real Data" : "Switch to Sample Data"
              }}
            </mat-slide-toggle>
          </div>
          <span class="spacer"></span>
          <div class="title-right">
            <app-analytics-time-selector
              (selectedOptionChange)="onChildSelectionChange($event)"
            />
          </div>
        </mat-toolbar>
      </div>
    </div>

    <mat-grid-list
      cols="5"
      rowHeight="400px"
      gutterSize="20px"
      *ngIf="!isNoContent"
    >
      <mat-grid-tile colspan="2">
        <app-analytics-widget
          [isNoContent]="isNoContent"
          [loading]="loading"
          class="analytics-widget"
        >
        <qoe-by-region-chart
            *ngIf="!isNoContent && !loading"
            [data]="QOEScoresByRegionData"
            [categories]="QOEScoresByRegionCategories"
          />
      </app-analytics-widget>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <app-analytics-widget
          [isNoContent]="isNoContent"
          [loading]="loading"
          class="analytics-widget"
        >
          <qoe-by-time-chart
              *ngIf="!isNoContent && !loading"
              [data]="timeOfDayBreakdownData"
              [categories]="timeOfDayBreakdownLabels"
            />
        </app-analytics-widget>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <app-analytics-widget
          [isNoContent]="isNoContent"
          [loading]="loading"
          class="analytics-widget"
        >
        <app-analytics-apex-chart-pie
        *ngIf="!isNoContent && !loading"
        [series]="carrierData"
        [labels]="carrierLabels"
        [title]="timeOfDayBreakdownTitleProvider"
      />
        </app-analytics-widget>
        
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <!-- <app-qoe-data-table
    [dataSource]="qoeTableData"
    [loading]="loading"
    [isNoContent]="isNoContent"
  /> -->
</section>
