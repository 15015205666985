<div class="widget need-help bookends" *ngxPermissionsOnly="'Ticketing: Write'">
  <div class="customer-cta icon-text">
    <div class="custom-icon customer"></div>
    <div class="header-label" #toggleHelpBlimpButton (click)="toggleBlimp()">
      <span class="custom-icon help"></span>
      <div class="blimp-question">Have a question?</div>
    </div>
    <div #helpBlimpView class="help-blimp" [ngClass]="{ visible: (showBlimp$ | async) }">
      <div class="arrow-up"></div>
      <h3>Need Help?</h3>
      <p>Create a ticket with the subject “Billing” and we&apos;ll take it from there!</p>
    </div>
  </div>
  <ng-container *ngxPermissionsOnly="'Ticketing: Write'">
    <div class="button-wrapper" *ngIf="hasInvoice">
      <button class="button" (click)="onCreateTicket()">
        <span>Create Ticket</span>
      </button>
    </div>
  </ng-container>
</div>
