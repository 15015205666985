import { DownloadChartModalComponent } from "./download-chart-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { inject, Injectable } from "@angular/core";
import { ChartsService } from "../charts-service";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const REPORT_NAME = 'Operations-Summary-Report'

@Injectable()
export class DownloadChartsService {

  private readonly matDialog = inject(MatDialog);
  private openModal(charts: ChartsService) {
    return this.matDialog.open<DownloadChartModalComponent>(
      DownloadChartModalComponent,
      {
        width: "1200px",
        maxWidth: "1200px",
        disableClose: true,
        hasBackdrop: true,
        data: charts,
      }
    );
  }

  public downloadCharts(charts: ChartsService) {
    const modelRef = this.openModal(charts);
    setTimeout(() => {
      this.captureHtmlToPdf("simple-container");
      modelRef.close();
    }, 5000);
  }

  async captureHtmlToPdf(elementId: string) {
    const element = document.getElementById(elementId);

    if (!element) {
      return;
    }

    this.cleanElementDom(element);
    const canvasWidth = element.offsetWidth + 100;

    const canvas = await html2canvas(element, {
      backgroundColor: '#F8F8F8',
      useCORS: true,
    });

    const canvasHeight = canvas.height;
    const canvasDataURL = canvas.toDataURL("image/png");

    const pdf = new jsPDF({
      format: [canvasWidth, canvasHeight],
      orientation: "portrait",
      unit: "px",
    });

    pdf.addImage(canvasDataURL, "PNG", 0, 0, canvasWidth, canvasHeight);

    pdf.save(`${REPORT_NAME}.pdf`);
  }

  private cleanElementDom(element: HTMLElement) {
    let unstyleElements: HTMLElement[] = [
      ...(element.querySelectorAll(".stats") as any),
    ];
    for (const el of unstyleElements) {
      el.style.boxShadow = "none";
      el.querySelector("&>:nth-child(3)")?.remove();
    }

    unstyleElements = [...(element.querySelectorAll("div > div > h1") as any)];
    for (const el of unstyleElements) {
      el.parentElement.style.justifyContent = "center";
      el.parentElement.style.border = "none";

      el.parentElement.querySelectorAll("& > div").forEach((x: HTMLElement) => {
        x.remove();
      });
    }
  }

}
