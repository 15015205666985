import { State, LoadTotalOpenTickets, getTicketsLoading, getTotalOpenTickets } from 'src/app/store';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-widget-tickets',
  templateUrl: './widget-tickets.component.html',
  styleUrls: ['../widgets.scss', './widget-tickets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetTicketsComponent implements OnInit {
  private readonly store = inject(Store<State>);

  public readonly totalOpenTickets$ = this.store.select(getTotalOpenTickets);
  public readonly isLoading$ = this.store.select(getTicketsLoading);

  ngOnInit() {
    this.store.dispatch(new LoadTotalOpenTickets());
  }

}
