import { WidgetServicesDisplayComponent } from '../../../../shared/components/widget-displays/widget-services-display/widget-services-display.component'
import { State, getServicesLoading, getActiveServices, getTotalActiveServiceLocations, LoadServices } from '../../../../store';
import { SharedModule } from '../../../../shared/shared.module';
import { Service } from '../../../../models/service.model';

import { ChangeDetectionStrategy, Component, inject, Input, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';

@Component({
  standalone: true,
  selector: 'ons-widget-services',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    WidgetServicesDisplayComponent,
    SharedModule,
  ],
  template: `
    <app-widget-services-display
      class="dimensions-stretch"
      [hyperlink]="'/services'"
      [loading]="isLoading$()"
      [navigateOnClick]="navigateOnClick"
      [serviceCount]="totalOpenServices$()?.length"
      [locationCount]="totalActiveLocations$()"
    ></app-widget-services-display>
  `,
})
export class WidgetServicesComponent {
  @Input() navigateOnClick: boolean = false;

  private readonly store$ = inject(Store<State>);

  public readonly totalActiveLocations$ = toSignal<number>(this.store$.select(getTotalActiveServiceLocations));
  public readonly totalOpenServices$ = toSignal<Service[]>(this.store$.select(getActiveServices));
  public readonly isLoading$ = toSignal<boolean>(this.store$.select(getServicesLoading));

  ngOnInit() {
    this.store$.dispatch(new LoadServices());
  }

}
