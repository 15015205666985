import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-select-dimension-dropdown',
  templateUrl: './select-dimension-dropdown.component.html',
  styleUrls: ['./select-dimension-dropdown.component.scss']
})
export class SelectDimensionDropdown implements OnInit {
  @Input() disabled: boolean = false;

  public selectedOption = 'device'; // Default is "6 hours"
  public optionList = [
    { value: 'device', viewValue: 'Edges' },
    { value: 'application', viewValue: 'Application' },
    { value: 'connection', viewValue: 'Links'}
  ];

  @Output() selectedOptionChange = new EventEmitter<string>();

  ngOnInit(): void {
    this.emitSelectedOption(); // Emit default value on initialization
  }

  // Method to emit the selected option
  emitSelectedOption(): void {
    this.selectedOptionChange.emit(this.selectedOption);
  }

  // Called when the selection changes
  onSelectionChange(value: string): void {
    this.selectedOption = value;
    this.emitSelectedOption();
  }
}
