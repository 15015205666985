import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Observable, from, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(
    private permissionsService: NgxPermissionsService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.permissionsService.permissions$.pipe(
      filter(permissions => Object.keys(permissions).length > 0), // Wait until permissions are loaded
      take(1), // Complete the observable after the first valid emission
      switchMap(() => {
        const requiredPermissions = route.data['permissions']?.only;
        if (!requiredPermissions) return of(false); // No permissions defined, deny access
  
        return from(this.permissionsService.hasPermission(requiredPermissions)).pipe(
          map((hasPermission: boolean) => {
            if (!hasPermission) {
              this.router.navigate(['/unauthorized']);
              return false;
            }
            return true;
          })
        );
      })
    );
  }
  
  
}
