import { WidgetSummaryDisplayComponent } from '../../../../shared/components/widget-displays/widget-summary-display/widget-summary-display.component'
import { State, getCurrentUser, getCurrentUserLoading, getCurrentUserLoaded } from '../../../../store/';
import { SharedModule } from '../../../../shared/shared.module';
import { AuthService } from '../../../../services/auth.service';
import { User } from '../../../../models/user.model';

import { ChangeDetectionStrategy, Component, computed, inject, ViewEncapsulation } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';

@Component({
  standalone: true,
  selector: 'ons-widget-summary',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    WidgetSummaryDisplayComponent,
    SharedModule,
  ],
  template: `
    <app-widget-summary-display
      class="dimensions-stretch"
      [loading]="isLoading$()"
      [user.firstName]="user$()?.FirstName"
      [user.lastName]="user$()?.LastName"
      [user.title]="user$()?.Title"
      [user.phone]="user$()?.Phone"
      [user.email]="user$()?.Email"
      [user.accountName]="user$()?.Account?.Name"
      [user.billingAddress]="userBillingAddress$()"
    ></app-widget-summary-display>
  `,
})
export class WidgetSummaryComponent {
  private readonly store$ = inject(Store<State>);

  public readonly isLoading$ = toSignal<boolean>(this.store$.select(getCurrentUserLoading));
  public readonly isLoaded$ = toSignal<boolean>(this.store$.select(getCurrentUserLoaded));
  public readonly user$ = toSignal<User>(this.store$.select(getCurrentUser));

  public readonly userBillingAddress$ = computed<string>(() => {
    const user = this.user$();
    if (!user?.Account?.BillingAddress) return '';

    const { street, city, stateCode, postalCode } = user.Account.BillingAddress;
    return `${street}\r\n${city}, ${stateCode} ${postalCode}`;
  });
}
