<section class="login">
  <div class="loading" *ngIf="isLoading">
    <mat-spinner [diameter]="30"></mat-spinner>
  </div>
  <div class="login-form-wrapper">
    <div class="logo"></div>
    <div *ngIf="displayForm" class="split-row login-form">
      <h1>Reset Your Password</h1>
      <form [formGroup]="resetPasswordFormGroup" novalidate (ngSubmit)="verifyPasswords()" autocomplete="off">
        <div>
          <label class="input-wrapper">
            <span>Account</span>
            <div class="email-address">{{ username }}</div>
          </label>
          <label class="input-wrapper">
            <span>Password</span>
            <input
              id="password"
              class="form-control"
              type="password"
              placeholder="Enter Password"
              required
              name="password"
              (input)="validatePassword($event)"
              formControlName="pw1"
            />
          </label>
          <ul class="criteria" *ngIf="!criteriaMet">
            <li *ngFor="let c of criteria" [ngClass]="{ disabled: !values[c].valid }">
              <i *ngIf="values[c].valid" class="material-icons valid">done</i>
              <i *ngIf="!values[c].valid" class="material-icons invalid">fiber_manual_record</i>
              <span>{{ values[c].label }}</span>
            </li>
          </ul>
          <div *ngIf="criteriaMet" class="criteria-met">
            <i class="material-icons">check_circle_outline</i>Password meets all criteria.
          </div>
          <label class="input-wrapper">
            <span>Password reentry</span>
            <input
              id="reenterPw"
              class="form-control"
              type="password"
              placeholder="Reenter Password"
              required
              name="pw2"
              (input)="doPasswordsMatch()"
              formControlName="pw2"
              [ngClass]="{ 'is-invalid': !!pwMatchErrorMessage }"
            />
            <div [ngClass]="{ expanded: !!pwMatchErrorMessage }" class="alert alert-danger">
              <div class="material-icons">warning</div>
              {{ pwMatchErrorMessage }}
            </div>
            <div *ngIf="pwMatchSuccessMessage" class="criteria-met">
              <i class="material-icons">check_circle_outline</i>
              {{ pwMatchSuccessMessage }}
            </div>
          </label>

          <div *ngIf="errorMessage && !isLoading" class="alert alert-danger">
            <div class="material-icons">warning</div>
            {{ errorMessage }}
          </div>

          <div class="button-wrapper">
            <button class="button" type="submit" [disabled]="!criteriaMet || isLoading">
              <span>Reset Password</span>
            </button>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="!displayForm" class="result-message">
      <div class="message">
        This link is expired. If you wish to reset your password, click 'Forgot Password' at Login.
      </div>
      <div class="button-wrapper">
        <a class="button" routerLink="/login" title="Login to MyNitel">
          <span>Go to Login</span>
        </a>
      </div>
    </div>
  </div>
</section>
