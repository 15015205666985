import { SurveyService } from "../../../services/survey.service";
import { inject, Injectable } from "@angular/core";
import { ChartService } from "./chart.service";
import { GaugeValueKPI } from "../../types";
import { map, startWith } from "rxjs";
import moment from "moment";
import { ChartType } from "../../types/chart-type";

const DEFAULT_MAX_VALUE = 5;
const DEFAULT_MIN_VALUE = 0;

enum RTAStates {
  RED = 0,
  YELLOW = 3.5,
  GREEN = 4.5,
}

const THRESHOLDS: GaugeValueKPI["thresholds"] = Object.freeze({
  [RTAStates.GREEN]: { color: "#0F0A", bgOpacity: 0.2 },
  [RTAStates.YELLOW]: { color: "#FD0A", bgOpacity: 0.2 },
  [RTAStates.RED]: { color: "#F00A", bgOpacity: 0.2 },
});

@Injectable()
export class CSATSurveyResultsChartService extends ChartService<GaugeValueKPI> {
  public override readonly chart$: ChartService<GaugeValueKPI>["chart$"];

  constructor() {
    super();
    const npsService = inject(SurveyService);
    const since = moment();
    since.subtract(1, "year");

    const observable = npsService
      .getRatingSince(since)
      .pipe(startWith({ rating: null, records: undefined }));

    this.chart$ = observable.pipe(
      map(({ rating, records }) => {
        if (rating > 0) {
          rating = Number(rating.toFixed(2));
        }

        return {
          loading: rating === null,
          ngClass: [],
          data: {
            type: ChartType.GAUGE,
            id: "CSAT Survey Results",
            title: "CSAT Survey Results",
            numberLabel: "Rating",
            value: parseFloat((rating || 0).toFixed(2)),
            records: records,
            minValue: DEFAULT_MIN_VALUE,
            maxValue: DEFAULT_MAX_VALUE,
            markers: this.makeMarkers(rating || 0),
            thresholds: { ...THRESHOLDS },
          } satisfies GaugeValueKPI,
        };
      })
    );
  }

  private makeMarkers(value: number): GaugeValueKPI["markers"] {
    const defaults = Object.freeze({
      color: "#888",
      type: "line",
      size: 8,
    });

    return {
      [RTAStates.GREEN]: { ...defaults, label: `${RTAStates.GREEN}` },
      [RTAStates.YELLOW]: { ...defaults, label: `${RTAStates.YELLOW}` },
      [RTAStates.RED]: { ...defaults, label: `${RTAStates.RED}` },
      [DEFAULT_MAX_VALUE]: { ...defaults, label: `${DEFAULT_MAX_VALUE}` },
      [value]: {
        ...defaults,
        label: DEFAULT_MAX_VALUE < value ? `${value}` : "",
        type: "triangle",
        color: "#000",
      },
    };
  }
}
