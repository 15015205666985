import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogModule,
} from "@angular/material/dialog";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatOptionModule } from "@angular/material/core";
import { CommonModule } from "@angular/common";
import { NgxPermissionsModule } from "ngx-permissions";
import { MatSelectModule } from "@angular/material/select";
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  takeUntil,
} from "rxjs";
import { Store } from "@ngrx/store";

import * as fromStore from "src/app/store/";
import { MSGraphService } from "src/app/services/ms-graph.service";
import { UserDialogData } from "../manage-users.component";
import { PortalRole } from "src/app/models/portal-role.model";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

interface UserPayload {
  accountId?: string;
  organizationName?: string;
  email: string;
  entraId?: string;
  firstName: string;
  invitedUserDisplayName?: string;
  lastName: string;
  portalRoleId?: string;
}

@Component({
  selector: "create-edit-user-dialog",
  templateUrl: "create-edit-user-dialog.component.html",
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatOptionModule,
    CommonModule,
    NgxPermissionsModule,
    MatSelectModule,
    MatProgressSpinnerModule,
  ],
  // Use OnPush strategy to optimize performance by minimizing change detection cycles and reducing unnecessary checks
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["create-edit-user-dialog.component.scss"],
})
export class CreateEditUserDialog implements OnInit, OnDestroy {
  readonly dialogRef = inject(MatDialogRef<CreateEditUserDialog>);
  readonly dialogData = inject<UserDialogData | null>(MAT_DIALOG_DATA);

  userForm: FormGroup;
  isEditMode: boolean = false;
  graphSubscription: Subscription;

  isSubmittingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSubmitting$: Observable<boolean> = this.isSubmittingSubject.asObservable();
  errorMessage: string = null;
  hasSubmissionErrorSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  hasSubmissionError$: Observable<boolean> =
    this.hasSubmissionErrorSubject.asObservable();
  portalRoles$: Observable<PortalRole[]> = this.store.select(fromStore.getPortalRoles);
  private destroy$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private graphService: MSGraphService,
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<fromStore.State>
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.dialogData?.Id;

    this.userForm = this.fb.group({
      ...(this.dialogData?.Id && { id: new FormControl(this.dialogData?.Id) }),
      accountName: new FormControl(this.dialogData?.Account.Name),
      entraId: new FormControl(this.dialogData?.EntraUserId__c),
      firstName: new FormControl(
        this.dialogData?.FirstName || "",
        Validators.required
      ),
      lastName: new FormControl(
        this.dialogData?.LastName || "",
        Validators.required
      ),
      email: new FormControl(
        { value: this.dialogData?.Email || "", disabled: this.isEditMode },
        [Validators.required, Validators.email]
      ),
      portalRole: new FormControl(null, Validators.required),
    });

    this.isSubmitting$.subscribe((isSubmitting) => {
      if (isSubmitting) {
        this.userForm.get("firstName")?.disable();
        this.userForm.get("lastName")?.disable();
        this.userForm.get("email")?.disable();
        this.userForm.get("portalRole")?.disable();
      } else {
        this.userForm.get("firstName")?.enable();
        this.userForm.get("lastName")?.enable();
        if (!this.isEditMode) this.userForm.get("email")?.enable();
        this.userForm.get("portalRole")?.enable();
      }
    });
    this.portalRoles$.pipe(takeUntil(this.destroy$)).subscribe((roles) => {
      const matchingRole = roles.find(
        (role) => role.Id === this.dialogData?.Portal_Role__r?.Id
      );
      this.userForm.patchValue({ portalRole: matchingRole || null });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (!this.userForm.valid) {
      this.errorMessage = "Please fill in all required fields correctly.";
      this.hasSubmissionErrorSubject.next(true);
      this.isSubmittingSubject.next(false);
      return;
    }
    if (!this.isUserRoleValid()) return;

    // this.isSubmittingSubject.next(true);
    this.userForm.get("firstName")?.enable();
    this.userForm.get("lastName")?.enable();
    this.userForm.get("email")?.enable();
    this.userForm.get("portalRole")?.enable();

    if (this.isEditMode) {
      this.updateUser(this.userForm.value);
    } else {
      this.inviteEntraUser(this.userForm.value);
    }
  }

  private isUserRoleValid(): boolean {
    const selectedRole = this.userForm.get("portalRole").value;
    const superAdminLimitReached =
      selectedRole?.Name === "Super Admin" &&
      this.dialogData?.TotalSuperAdmins >= 1;
    const isRoleChanged =
      selectedRole?.Name !== this.dialogData?.Portal_Role__r?.Name;

    if (
      (!this.isEditMode && superAdminLimitReached) ||
      (this.isEditMode && superAdminLimitReached && isRoleChanged)
    ) {
      this.errorMessage =
        "Super Admin limit reached! Please remove an existing Super Admin to add another.";
      this.hasSubmissionErrorSubject.next(true);
      this.isSubmittingSubject.next(false);
      return false;
    }
    return true;
  }

  private inviteEntraUser(data: any): void {
    this.isSubmittingSubject.next(true);
    const payload: UserPayload = {
      email: data.email,
      invitedUserDisplayName: `${data.firstName} ${data.lastName}`,
      firstName: data.firstName,
      lastName: data.lastName,
      accountId: this.dialogData.AccountId,
      organizationName: this.dialogData.Account.Name,
      portalRoleId: data.portalRole.Id,
    };

    this.graphService
      .inviteEntraUser(payload)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.isSubmittingSubject.next(false);
          this.hasSubmissionErrorSubject.next(false);

          setTimeout(() => {
            this.reloadContacts();
          }, 1000);

          this.dialogRef.close(data);
          this.changeDetectorRef.detectChanges();
        },
        error: (error) => {
          this.getErrorMessage(error);
        },
      });
  }

  private updateUser(formData: any): void {
    this.isSubmittingSubject.next(true);
    this.graphService
      .updateEntraUser(formData?.entraId, formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.isSubmittingSubject.next(false);
          this.hasSubmissionErrorSubject.next(false);
          this.reloadContacts();

          this.dialogRef.close(formData);
          this.changeDetectorRef.detectChanges();
        },
        error: (error) => {
          this.getErrorMessage(error);
        },
      });
  }

  private getErrorMessage(error: any) {
    this.hasSubmissionErrorSubject.next(true);
    this.isSubmittingSubject.next(false);
    this.errorMessage = error.message || "An error occurred. Please try again.";
    this.changeDetectorRef.detectChanges();
  }

  private reloadContacts(): void {
    this.store.dispatch(new fromStore.ClearContacts());
    this.store.dispatch(new fromStore.LoadContacts());
  }
}
