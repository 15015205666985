import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import * as Orders from '../../../orders-table/models';
import { Router } from '@angular/router';
@Component({
  selector: 'app-active-order-chart',
  templateUrl: './active-order-chart.component.html',
  styleUrls: [ './active-order-chart.component.scss']
})
export class ActiveOrderChartComponent implements OnChanges {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  @Input() total: number = 0;
  @Input() data: { [key: string]: number } = {};
  @Input() order: any;
  @Input() loading: boolean = true;
  @Output() navigate: EventEmitter<string> = new EventEmitter();
  constructor(private router: Router) { }
  backgroundColor: string[];
  private ordersTableLink = '/orders-table';
  preparingData: String;
  preparingNumber: number;
  activationCompleteData: String;
  activationCompleteNumber: number;
  inProgressDetailsSum: number;

  public pieChartType: ChartType = 'pie' as ChartType;
  public pieChartLinks: Array<string> = [];
  private inProgressDetails: string;

  ngOnInit() {
    this.loading = true;
  }
  ngAfterViewChecked(){
    this.loading = false;
  }
  public pieChartOptions: ChartConfiguration['options'] = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        maxWidth: 195,
        display: true,
        position: 'right',
        labels: {
          color: 'white',
          boxHeight: 16,
          boxWidth: 16,
          font: {
            size: 16
          },
        },
      },
    },
    layout: {
      padding: {
        top: 5,
        bottom: 5,
        left: 5,
        right: 5
      }
    },
    onHover: (event, chartElement) => {
      const styles = event.native.target['style'];
      styles.cursor = (chartElement.length)
        ? 'pointer'
        : 'default';
    },
  };

  public pieChartData: ChartData<'pie'> = {
    labels: [],
    datasets: [
      {
        data: [],
        label: 'Active Orders',
        borderWidth: 1,
        hoverBorderWidth: 1
      }
    ]
  };


  ngOnChanges(): void {
    if (this.data && Object.keys(this.data).length > 0) {
      this.setData();
    }
  }
  private getLabelUrl(label: string): string {
    let statuses;
    if (label.includes("In Progress")) {
      statuses = [
        Orders.Status.IN_PROGRESS
      ];
    }
    if (label.includes("Pending") || label.includes("Ready")) {
      statuses = [
        Orders.Status.PENDING
      ];
    }
    return `${this.ordersTableLink}?status=${statuses.join(',')}`;
  }
  private label: any;

  public chartClicked(e): void {
    this.label = this.chart.data.labels[e.active[0].index];
    const url = this.getLabelUrl(this.label);
    this.router.navigateByUrl(url);
  }

  private setData(): void {
    let pendingCount = 0;
    this.pieChartData.labels = Object.keys(this.data).map((key) => {
      if (key === 'Ready' || key === 'Pending') {
        pendingCount += this.data[key];
        return null;
      } else {
        return key.charAt(0).toUpperCase() + key.slice(1);
      }
    }).filter((label) => label !== null);

    this.pieChartData.datasets[0].data = Object.entries(this.data).map(([key, value]) => {
      if (key === 'Ready' || key === 'Pending') {
        return null;
      } else {
        return value;
      }
    }).filter((value) => value !== null);

    if (pendingCount > 0) {
      this.pieChartData.labels.push('Pending');
      this.pieChartData.datasets[0].data.push(pendingCount);
    }
    this.backgroundColor = ['purple', '#2199e8', 'orange'];

    this.chart?.update();
  }
}
