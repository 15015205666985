import { ActiveServicesChartService } from "./active-services-chart.service";
import { DisconnectedOrdersChartService } from "./disconnected-orders-chart.service";
import { InflightOrdersChartService } from "./inflight-orders-chart.service";
import { OpenSuportCasesChartService } from "./open-support-cases-chart.service";
import { OldOpenSupportCasesChartService } from "./old-open-support-cases-chart.service";
import { OpenMaintenanceCasesChartService } from "./open-maintenance-cases-chart.service";
import { OpenCareCasesChartService } from "./open-care-cases-chart.service";
import { OldOpenCareCasesChartService } from "./old-open-care-cases-chart.service";
import { OpenFinanceCasesChartService } from "./open-finance-cases-chart.service";
import { OldOpenFinanceCasesChartService } from "./old-open-finance-cases-chart.service";
import { YtdNocCasesClosedCodeChartService } from "./ytd-noc-cases-closed-code-chart.service";
import { CaseHistoryByDateChartService } from "./case-history-by-date-chart.service";
import { ClosedCasesChartService } from "./old-closed-cases-chart.service";
import { NewClosedCasesChartService } from "./new-closed-cases-chart.service";
import { EmergencyCasesChartService } from "./open-emergency-cases-chart.service";
import { OpenNOCCasesChartService } from "./open-NOC-cases-chart.service";
import { OldOpenNOCCasesChartService } from "./old-open-NOC-cases-chart.service";
import { MTTRCircuitDownChartService } from "./mttr-circuit-down-chart.service";
import { CSATSurveyResultsChartService } from "./csta-survey-results-chart.service";
import { MTTRAllNOCCasesLifeChartService } from "./mttr-all-noc-cases-life-chart.service";
import { YearNitelNpsChartService } from "./year-nitel-nps-chart.service";
import { toSignal } from "@angular/core/rxjs-interop";
import { computed, inject, Injectable } from "@angular/core";
import { ChartService } from "./chart.service";
import { BaseKPI } from "../../types";
import { combineLatest } from "rxjs";

@Injectable()
export class ChartsService {
  public static readonly providers = Object.freeze([
    ActiveServicesChartService,
    InflightOrdersChartService,
    DisconnectedOrdersChartService,
    OpenSuportCasesChartService,
    OldOpenSupportCasesChartService,
    OpenMaintenanceCasesChartService,
    OpenCareCasesChartService,
    OldOpenCareCasesChartService,
    OpenFinanceCasesChartService,
    OldOpenFinanceCasesChartService,
    YtdNocCasesClosedCodeChartService,
    CaseHistoryByDateChartService,
    ClosedCasesChartService,
    NewClosedCasesChartService,
    EmergencyCasesChartService,
    OpenNOCCasesChartService,
    OldOpenNOCCasesChartService,
    MTTRCircuitDownChartService,
    MTTRAllNOCCasesLifeChartService,
    YearNitelNpsChartService,
    CSATSurveyResultsChartService,
  ]);

  public readonly activeServices = this.inject(ActiveServicesChartService);
  public readonly inflightOrders = this.inject(InflightOrdersChartService);
  public readonly disconnectedOrders = this.inject(DisconnectedOrdersChartService);
  public readonly openSuportCases = this.inject(OpenSuportCasesChartService);
  public readonly oldOpenSupportCases = this.inject(OldOpenSupportCasesChartService);
  public readonly openMaintenanceCases = this.inject(OpenMaintenanceCasesChartService);
  public readonly openCareCases = this.inject(OpenCareCasesChartService);
  public readonly oldOpenCareCases = this.inject(OldOpenCareCasesChartService);
  public readonly openFinanceCases = this.inject(OpenFinanceCasesChartService);
  public readonly oldOpenFinanceCases = this.inject(OldOpenFinanceCasesChartService);
  public readonly ytdNocCasesClosedCode = this.inject(YtdNocCasesClosedCodeChartService);
  public readonly caseHistoryByDate = this.inject(CaseHistoryByDateChartService);
  public readonly closedCases = this.inject(ClosedCasesChartService);
  public readonly newClosedCases = this.inject(NewClosedCasesChartService);
  public readonly emergencyCases = this.inject(EmergencyCasesChartService);
  public readonly openNOCCases = this.inject(OpenNOCCasesChartService);
  public readonly oldOpenNOCCases = this.inject(OldOpenNOCCasesChartService);
  public readonly mttrCircuitDown = this.inject(MTTRCircuitDownChartService);
  public readonly mttrAllNOCCasesLife = this.inject(MTTRAllNOCCasesLifeChartService);
  public readonly yearNitelNps = this.inject(YearNitelNpsChartService);
  public readonly csatSurveyResults = this.inject(CSATSurveyResultsChartService);

  public readonly _loadingCharts$ = computed(() => {
    const charts = [
      this.activeServices.signal(),
      this.inflightOrders.signal(),
      this.disconnectedOrders.signal(),
      this.openSuportCases.signal(),
      this.oldOpenSupportCases.signal(),
      this.openMaintenanceCases.signal(),
      this.openCareCases.signal(),
      this.oldOpenCareCases.signal(),
      this.openFinanceCases.signal(),
      this.oldOpenFinanceCases.signal(),
      this.ytdNocCasesClosedCode.signal(),
      this.caseHistoryByDate.signal(),
      this.closedCases.signal(),
      this.newClosedCases.signal(),
      this.emergencyCases.signal(),
      this.openNOCCases.signal(),
      this.oldOpenNOCCases.signal(),
      this.mttrCircuitDown.signal(),
      this.mttrAllNOCCasesLife.signal(),
      this.yearNitelNps.signal(),
      this.csatSurveyResults.signal(),
    ];

    return charts.some((x) => x.loading);
  });

  private inject<T extends BaseKPI<any, any>>(
    chartService: typeof ChartService<T>
  ) {
    const service = inject(chartService);
    return Object.freeze({
      signal: toSignal(service.chart$),
      observable: service.chart$,
      // service: service,
    });
  }
}
