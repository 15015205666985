import { entraConfig, msalConfig } from "./msal.config";
import {
  MsalGuardAuthRequest,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from "@azure/msal-angular";
import {
  PublicClientApplication,
  InteractionType,
  IPublicClientApplication,
} from "@azure/msal-browser";

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(
    "https://graph.microsoft.com/v1.0/me",
    entraConfig.appScopes.graphApi.scopes
  );
  protectedResourceMap.set("https://mynitel.onmicrosoft.com/mynitelapi", entraConfig.appScopes.nitelApi.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  // return {
  //   interactionType: InteractionType.Redirect,
  //   authRequest: azureConfig.appScopes.nitelApi,
  // };
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: entraConfig.appScopes.nitelApi.scopes,
    } as MsalGuardAuthRequest,
    loginFailedRoute: '/'
  };
}
