import { Component } from '@angular/core';

@Component({
  selector: 'page-not-found',
  template: `
    <div class="page-not-found">
      <h1>Page Not Found</h1>
      <h3>This is not the page you were looking for!</h3>
    </div>
  `,
  styleUrls: ["page-not-found.component.scss"]
})
export class PageNotFoundComponent {}
