import {
    Component,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    ViewEncapsulation,
} from "@angular/core";
import {
    ChartComponent,
    ApexAxisChartSeries,
    ApexChart,
    ApexXAxis,
    ApexTitleSubtitle,
    ApexPlotOptions,
    ApexDataLabels,
    ApexYAxis,
} from "ng-apexcharts";

export {
    ChartComponent,
    ApexAxisChartSeries,
    ApexChart,
    ApexXAxis,
    ApexTitleSubtitle,
    ApexPlotOptions,
    ApexDataLabels,
};

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    dataLabels: ApexDataLabels;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis;
    plotOptions: ApexPlotOptions;
    title: ApexTitleSubtitle;
};

@Component({
    selector: "app-analytics-apex-chart-bar",
    templateUrl: "./analytics-apex-chart-bar.component.html",
    styleUrls: ["../analytics.scss"],
    encapsulation: ViewEncapsulation.None, // Disables encapsulation
})
export class AnalyticsApexChartBarComponent {
    @ViewChild("chart") chart: ChartComponent;

    @Input() data: any[] = []; // Define series as Input
    @Input() categories: string[] = []; // Define labels as Input
    @Input() chartName: string = "";
    @Input() yTitle: string = "";
    @Input() xTitle: string = "";

    @Output() changeDevice = new EventEmitter<string>();

    public chartOptions: ChartOptions;

    constructor() {
        this.setOptions();
    }

    ngOnChanges() {
        this.setOptions();
    }

    setOptions() {
        this.chartOptions = {
            series: this.data,
            chart: {
                height: 350,
                type: "bar",
                events: {
                    dataPointSelection: (event: any, chartContext: any, config: any) => {
                        this.onChangeDevice(config);
                    },
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: true,
                formatter: (value: number) => {
                    if (this.yTitle.includes('%')) {
                        return value.toLocaleString('en-US') + '%';
                    } else {
                        return value.toLocaleString('en-US');
                    }
                }
            },
            xaxis: {
                categories: this.categories,
                labels: {
                    // Configure label behavior
                    hideOverlappingLabels: false,  // Allow overlapping labels (prevent label hiding)
                    showDuplicates: true,  // Allow showing duplicate labels
                    trim: true  // Trim the labels if they are too long to fit
                },
                title: {
                    text: this.xTitle,  // Set the title for the X-axis (dynamic from 'this.xTitle')
                }
            },
            yaxis: {
                labels: {
                    formatter: (value: number) => {
                        if (this.yTitle.includes('%')) {
                            return value.toLocaleString('en-US') + '%';
                        } else {
                            return value.toLocaleString('en-US');
                        }
                    }
                },
                title: {
                    text: this.yTitle  // Set the title of the Y-axis dynamically (from 'this.yTitle')
                }
            },
            title: {
                text: this.chartName,
                align: "center",
                margin: 10,
                offsetX: 0,
                offsetY: 0,
                floating: false,
            }
        };
    }

    onChangeDevice(config) {
        const selectedPointIndex = config.dataPointIndex;
        this.changeDevice.emit(
            this.chartOptions.xaxis.categories[selectedPointIndex]
        );
    }
}