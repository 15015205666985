<div class="single-value-item stats" [ngClass]="{ 'single-value-item--loading': loading }">
  <div class="single-value-item__header" >
    <h1 class="single-value-item__title">
      {{ data.title }}
    </h1>
    <!-- <div>
      <button
        mat-icon-button
        class="single-value-item__expand"
        (click)="openModal()"
      >
        <mat-icon>fullscreen</mat-icon>
      </button>
    </div> -->
  </div>
  <div class="single-value-item__body" [appTooltip]="tooltipTemplate" #modalContent>
    <span class="single-value-item__chart">
      {{ formatValue(data.value) }}
    </span>
    <mat-spinner class="single-value-item__spinner"
      *ngIf="loading"
      [diameter]="75"
    ></mat-spinner>
  </div>

  <!-- <div class="single-value-item__footer" *ngIf="data.linkEnabled">
    <a [routerLink]="data.hyperlink || null" [queryParams]="data.linkParams" [ngClass]="{ 'single-value-item__footer--link-disabled': !data.hyperlink }">
      {{ data.hyperlinkText || "View " + data.title }}
    </a>
  </div> -->
</div>

<ng-template #tooltipTemplate>
  <div>
    <strong>{{ formatValue(data.value) }}</strong>
  </div>
</ng-template>
