import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Idle } from "@ng-idle/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable, of, BehaviorSubject, throwError } from "rxjs";
import { map, catchError, distinctUntilChanged } from "rxjs/operators";
import { NgxPermissionsService } from "ngx-permissions";
import { MatDialog } from "@angular/material/dialog";
import { MsalService } from "@azure/msal-angular";

import * as fromStore from "../store";
import { OrdersParserService } from "./orders-parser.service";
import { GainsightService } from "./gainsight.service";
import { environment } from "../../environments/environment";
import { User } from "../models/user.model";
import { Contact } from "../models/contact.model";
import { api } from "../models/api-url.model";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  loggedInUser: User = null;
  impersonator: User = null;
  isImpersonated = false;
  redirectUrl: string;

  sfAuthToken: string;
  SALESFORCE_AUTH_TOKEN = "salesforce_auth_token";

  loginUrl = `${environment.apiUrl}/${api.loginURL}`;
  impersonationUrl = `${environment.apiUrl}/${api.impersonateURL}`;
  forgotPasswordUrl = `${environment.apiUrl}/${api.forgotPasswordURL}`;
  resetPasswordUrl = `${environment.apiUrl}/${api.resetPasswordURL}`;
  validatePasswordUrl = `${environment.apiUrl}/${api.validateTokenURL}`;
  verifyUserUrl = `${environment.apiUrl}/verify-user`;

  rememberMe = false;

  private authTokenSubject = new BehaviorSubject<string>("");
  authToken$ = this.authTokenSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(
    private http: HttpClient,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private idle: Idle,
    private store: Store<fromStore.State>,
    private orders: OrdersParserService,
    private dialog: MatDialog,
    private msalService: MsalService,
    private gainsight: GainsightService
  ) {
    this.sfAuthToken = localStorage.getItem(this.SALESFORCE_AUTH_TOKEN);
    if (this.sfAuthToken) {
      const jwt = new JwtHelperService();
      const decodedToken = jwt.decodeToken(this.sfAuthToken);
      this.permissionsService.loadPermissions(decodedToken?.permissions || []);
    }
  }

  get isLoggedIn(): boolean {
    // Check if the custom auth token exists (custom local login)
    const isCustomLoggedIn = this.sfAuthToken !== null;
    // Check if the Entra ID (Azure AD B2C) token exists via MSAL
    const isEntraLoggedIn =
      this.msalService.instance.getActiveAccount() !== null;

    return isCustomLoggedIn || isEntraLoggedIn;
  }

  impersonate(impersonationToken: string): Observable<any> {
    const impersonateOptions = {
      headers: new HttpHeaders({
        "Salesforce-Authorization": `Bearer ${impersonationToken}`,
        "Content-Type": "application/json",
        "Cache-control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
      }),
    };

    return this.http
      .post<any>(this.impersonationUrl, {}, impersonateOptions)
      .pipe(
        map((data) => {
          if (data.salesforce_auth_token) {
            if (data?.user?.EntraUserId__c?.length > 0) {
              return null;
            }
            this.authTokenSubject.next(data.salesforce_auth_token);
            localStorage.setItem(
              this.SALESFORCE_AUTH_TOKEN,
              data.salesforce_auth_token
            );
            this.sfAuthToken = data.salesforce_auth_token;
            this.loggedInUser = data.user;
            this.isImpersonated = true;

            const permissions = data.user.Permissions;
            this.permissionsService.loadPermissions(permissions);

            this.trackUser(data.user, true);
            this.idle.watch();
            return data;
          }
          return null;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  login(uid: string, password: string, rememberMe: boolean): Observable<any> {
    return this.http
      .post<any>(this.loginUrl, { uid, password, rememberMe })
      .pipe(
        map((data) => {
          if (data.salesforce_auth_token && !data.user.EntraUserId__c) {
            this.authTokenSubject.next(data.salesforce_auth_token);
            localStorage.setItem(
              this.SALESFORCE_AUTH_TOKEN,
              data.salesforce_auth_token
            );
            this.sfAuthToken = data.salesforce_auth_token;
            this.loggedInUser = data.user;

            const permissions = data.user?.Permissions;
            this.permissionsService.loadPermissions(permissions);

            if (rememberMe) {
              // Set the expiration time to the expiration of the JWT.
              const nowSeconds = Math.floor(Date.now() / 1000);
              const jwt = new JwtHelperService();
              const sessionSeconds = Math.abs(
                jwt.decodeToken(data.salesforce_auth_token).exp - nowSeconds
              );

              this.idle.setIdle(sessionSeconds);
            }

            this.trackUser(data.user);
            this.idle.watch();

            if (this.redirectUrl) {
              this.router.navigateByUrl(this.redirectUrl);
            } else {
              this.router.navigate(["/"]);
            }
          }
          return data;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  // TODO: ADD Entra User here too
  trackUser(user: User, isImpersonation: boolean = false): void {
    if (this.isLoggedIn && user?.Id) {
      this.gainsight.initGainsight({
        user: {
          id: user?.Id,
          firstName: user?.FirstName,
          lastName: user?.LastName,
          email: user?.Email,
          role: user.Portal_Role__r?.Name,
          isImpersonation: isImpersonation,
        },
        account: { id: user?.AccountId, name: user?.Account?.Name },
      });
    }
  }

  signIn(): Observable<any> {
    return this.http.get<any>(`${this.verifyUserUrl}`).pipe(
      map((data: { user: Contact; salesforce_auth_token: string }) => {
        // Handle response data here
        this.authTokenSubject.next(data.salesforce_auth_token);
        // TODO: move to cookies instead of local storage
        localStorage.setItem(
          this.SALESFORCE_AUTH_TOKEN,
          data.salesforce_auth_token
        );

        this.sfAuthToken = data.salesforce_auth_token;
        this.loggedInUser = data.user;
        if (data.user?.Permissions) {
          const permissions = data.user?.Permissions;
          this.permissionsService.loadPermissions(permissions);
        }

        this.trackUser(data.user);
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  signout(reason = null): void {
    this.clearCache();

    if (reason) {
      this.msalService.logout({
        postLogoutRedirectUri: `/login?logout=${reason}`,
      });
    } else {
      this.msalService.logout({
        postLogoutRedirectUri: `/login`,
      });
    }
  }

  clearCache() {
    this.permissionsService.flushPermissions();
    this.store.dispatch(new fromStore.Logout()); // clears NgRx store state
    this.dialog.closeAll();
    this.authTokenSubject.next(null);
    this.sfAuthToken = null;
    this.loggedInUser = null;

    localStorage.removeItem(this.SALESFORCE_AUTH_TOKEN);
    localStorage.clear();
    this.orders.clear();

    // stop watching idle session
    this.idle.stop();
  }

  forgotPassword(uid): Observable<any> {
    this.idle.stop();
    return this.http.post<any>(this.forgotPasswordUrl, { uid });
  }

  resetPassword(
    token: string,
    password: string,
    passwordConfirmation: string
  ): Observable<boolean> {
    this.idle.stop();
    localStorage.setItem("mynitel_reset_token", token);
    return this.http
      .post<boolean>(this.resetPasswordUrl, {
        password,
        passwordConfirmation,
      })
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  checkValidToken(token = "") {
    let exp: number;
    const jwt = new JwtHelperService();
    if (token) {
      exp = jwt.decodeToken(token).exp; // expiration time
    } else {
      token = localStorage.getItem(this.SALESFORCE_AUTH_TOKEN);
      exp = jwt.decodeToken(token).exp; // expiration time
    }
    const CURRENT_TIME = Math.floor(Date.now() / 1000);
    const isValid = exp > CURRENT_TIME;

    localStorage.getItem(this.SALESFORCE_AUTH_TOKEN);

    return isValid;
  }

  validateToken(token: string): Observable<boolean> {
    const headers = new HttpHeaders({
      "Salesforce-Authorization": `Bearer ${token}`,
      "Content-Type": "application/json",
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "-1",
    });

    return this.http.get<void>(this.validatePasswordUrl, { headers }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  validateUsername(username: string): Observable<any> {
    return this.http
      .post<boolean>(`${environment.apiUrl}${api.validateUsernameURL}`, {
        username,
      })
      .pipe(catchError((error: HttpErrorResponse) => throwError(() => error)));
  }
}
