import { APP_INITIALIZER, CSP_NONCE, Provider } from "@angular/core";
import { NonceService } from "./services/nonce.service"; // adjust the import path as necessary
import { environment } from "src/environments/environment";
import { RouterStateSerializer } from "@ngrx/router-store";
import { CustomSerializer } from "./store/reducers/router.reducer";
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalInterceptor,
  MsalService,
} from "@azure/msal-angular";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  MSALInstanceFactory,
  MSALGuardConfigFactory,
  MSALInterceptorConfigFactory,
} from "./msal.factory";
import { TokenInterceptor } from "./core/token.interceptor";

export const googleMeasurementId = environment.production
  ? "G-S1MYCEH0GM"
  : "G-8V2BK3ZR53";

function nonceInitializerFactory(
  nonceService: NonceService
): () => Promise<void> {
  return () => nonceService.getNonce().then(() => {});
}

function msalInitializerFactory(msalService: MsalService): () => Promise<void> {
  return () => msalService.instance.initialize();
}

function cspNonceFactory(nonceService: NonceService): string {
  return nonceService.getCachedNonce();
}

export const initializeAppProviders: Provider[] = [
  { provide: RouterStateSerializer, useClass: CustomSerializer },
  {
    provide: APP_INITIALIZER,
    useFactory: msalInitializerFactory,
    deps: [MsalService],
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true,
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory,
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory,
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory,
  },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  { provide: "googleTagManagerId", useValue: googleMeasurementId },
  {
    provide: APP_INITIALIZER,
    useFactory: nonceInitializerFactory,
    deps: [NonceService],
    multi: true,
  },
  {
    provide: "googleTagManagerCSPNonce",
    useFactory: cspNonceFactory,
    deps: [NonceService],
  },
];
