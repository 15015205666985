import { Pipe, PipeTransform } from '@angular/core';
import { pluralize } from '../functions/pluralize';

@Pipe({
  name: 'pluralize'
})
export class PluralizePipe implements PipeTransform {

  transform(input: number | Array<any>, singular: string, plural?: string): string {
    if (Array.isArray(input)) {
      return pluralize(input.length, singular, plural);
    }

    return pluralize(input, singular, plural);
  }
}
