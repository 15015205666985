<section class="dashboard-page">
  <div class="mx-5 mb-10">
    <div class="container-top" id="charts-dashboard">
      <div class="container-title">
        <h1>Operations Summary <span class="badge">beta</span></h1>
        <button
          class="download-button"
          (click)="dowloadFilesAsPdf()"
          [disabled]="charts$._loadingCharts$()"
        >
          Download PDF
        </button>
      </div>

      <div class="kpi-flex">
        <div class="kpi-grid-2x">
          <ng-container *ngIf="charts$.mttrAllNOCCasesLife.signal() as chart">
            <app-gauge-value
              class="stat__item"
              [ngClass]="chart.ngClass"
              [data]="chart.data"
              [loading]="chart.loading"
            ></app-gauge-value>
          </ng-container>

          <div class="kpi-flex">
            <ng-container *ngIf="charts$.yearNitelNps.signal() as chart">
              <app-gauge-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-gauge-value>
            </ng-container>

            <ng-container *ngIf="charts$.csatSurveyResults.signal() as chart">
              <app-gauge-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-gauge-value>
            </ng-container>
          </div>
          <!--
            <ng-container *ngIf="charts$.disconnectedtOrders.signal() as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container> -->

          <div class="kpi-flex">
            <ng-container *ngIf="charts$.activeServices.signal() as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container>

            <ng-container *ngIf="charts$.inflightOrders.signal() as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container>
          </div>

          <div class="kpi-flex">
            <ng-container *ngIf="charts$.newClosedCases.signal() as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container>

            <ng-container *ngIf="charts$.closedCases.signal() as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container>
          </div>

          <div class="kpi-flex">
            <ng-container *ngIf="charts$.oldOpenCareCases.signal() as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container>

            <ng-container *ngIf="charts$.openCareCases.signal() as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container>
          </div>

          <div class="kpi-flex">
            <ng-container *ngIf="charts$.oldOpenNOCCases.signal() as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container>

            <ng-container *ngIf="charts$.openNOCCases.signal() as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container>
          </div>

          <div class="kpi-flex">
            <ng-container *ngIf="charts$.oldOpenSupportCases.signal() as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container>

            <ng-container *ngIf="charts$.openSuportCases.signal() as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container>
          </div>

          <div class="kpi-flex">
            <ng-container *ngIf="charts$.oldOpenFinanceCases.signal() as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container>

            <ng-container *ngIf="charts$.openFinanceCases.signal() as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container>
          </div>

          <div class="kpi-flex">
            <ng-container *ngIf="charts$.emergencyCases.signal() as chart">
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container>

            <ng-container
              *ngIf="charts$.openMaintenanceCases.signal() as chart"
            >
              <app-single-value
                class="stat__item"
                [ngClass]="chart.ngClass"
                [data]="chart.data"
                [loading]="chart.loading"
              ></app-single-value>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="charts">
        <ng-container *ngIf="charts$.caseHistoryByDate.signal() as chart">
          <app-line-values
            [data]="chart.data"
            [loading]="chart.loading"
            (navigate)="handleNavigation($event)"
          ></app-line-values>
        </ng-container>
      </div>

      <div class="charts horizontal-bars">
        <ng-container *ngIf="charts$.ytdNocCasesClosedCode.signal() as chart">
          <app-horizontal-bars
            [data]="chart.data"
            [loading]="chart.loading"
            (navigate)="handleNavigation($event)"
          ></app-horizontal-bars>
        </ng-container>
      </div>
      <div class="charts horizontal-bars">
        <ng-container *ngIf="charts$.mttrCircuitDown.signal() as chart">
          <app-vertical-bars
            [data]="chart.data"
            [loading]="chart.loading"
            (navigate)="handleNavigation($event)"
          ></app-vertical-bars>
        </ng-container>
      </div>
    </div>
  </div>
</section>
