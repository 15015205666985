import { Component, Input, OnDestroy, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CreateTicketComponent } from 'src/app/tickets/containers';
import * as fromStore from 'src/app/store/';

@Component({
  selector: 'app-help-cta',
  templateUrl: './help-cta.component.html',
  styleUrls: ['./help-cta.component.scss']
})
export class HelpCtaComponent implements OnDestroy {
  @Input() originPage: string;
  @Input() hasInvoice: boolean;

  private readonly destroy$ = new Subject<void>();
  browserSize$: Observable<string>;
  browserSize: string;

  private showBlimpSubject = new BehaviorSubject<boolean>(false);
  showBlimp$ = this.showBlimpSubject.asObservable();
  @ViewChild('toggleHelpBlimpButton', { static: false }) toggleHelpBlimpButton: ElementRef;

  constructor(
    private dialog: MatDialog,
    private store: Store<fromStore.State>,
    private renderer: Renderer2
  ) {
    this.browserSize$ = this.store.select(fromStore.getBrowserSizeName);

    this.browserSize$.pipe(takeUntil(this.destroy$)).subscribe((name) => {
      if (name) {
        this.browserSize = name;
      }
    });

    // Close help blimp if clicked outside (desktop only)
    this.renderer.listen('window', 'click', (e: Event) => {
      // Check if the click happened outside of the header-label or help blimp view
      if (!this.toggleHelpBlimpButton.nativeElement.contains(e.target)) {
        this.closeBlimp();
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCreateTicket() {
    // Open the create ticket dialog
    const dialogConfig = {
      autoFocus: true,
      minHeight: this.browserSize === 'small' ? '315px' : '380px',
      width: this.browserSize === 'small' ? 'calc(100vw - 20px)' : '650px',
      maxWidth: '650px',
      data: {
        browserSize: this.browserSize,
        originPage: this.originPage
      },
      disableClose: true
    };

    const createTicketDialog = this.dialog.open(CreateTicketComponent, dialogConfig);
    createTicketDialog.afterClosed().subscribe();
  }

  toggleBlimp() {
    this.showBlimpSubject.next(!this.showBlimpSubject.value);
  }

  closeBlimp() {
    this.showBlimpSubject.next(false);
  }
}
